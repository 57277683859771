import { Raw } from '@/modules/common/helpers/api';
import { OrderType, RoutingStatus, TimeInForceType } from '@/modules/marketplace/types/marketplace';
import Decimal from 'decimal.js';

export type RawOmsOrderRequestDetails = Raw<OmsOrderRequestDetails>;

export class OmsOrderRequestDetails {
  public quantity: number;
  public minQuantity: number;
  public rate: Decimal | null;
  public agreementIds: string[];
  public orderType: OrderType;
  public timeInForceType: TimeInForceType;
  public goodTillTime: Date | null;
  public isAnonymous: boolean;
  public routingStatus: RoutingStatus;

  protected constructor(data: OmsOrderRequestDetails) {
    this.quantity = data.quantity;
    this.minQuantity = data.minQuantity;
    this.rate = data.rate;
    this.agreementIds = data.agreementIds;
    this.orderType = data.orderType;
    this.timeInForceType = data.timeInForceType;
    this.goodTillTime = data.goodTillTime;
    this.isAnonymous = data.isAnonymous;
    this.routingStatus = data.routingStatus;
  }

  public static toData(model: OmsOrderRequestDetails): RawOmsOrderRequestDetails {
    return {
      quantity: model.quantity,
      minQuantity: model.minQuantity,
      rate: model.rate?.toString() ?? null,
      agreementIds: model.agreementIds,
      orderType: model.orderType,
      timeInForceType: model.timeInForceType,
      goodTillTime: model.goodTillTime,
      isAnonymous: model.isAnonymous,
      routingStatus: model.routingStatus,
    };
  }
}

import { ColDef, ProcessCellForExportParams, ValueFormatterParams } from 'ag-grid-enterprise';

export function processCellCallback(params: ProcessCellForExportParams): string {
  const colDef = params.column.getColDef();

  // force using the valueFormatter if useValueFormatterForExport is true,
  // instead of the bad default, which is to ignore this for numeric values (which includes our enums)
  if (
    colDef.valueFormatter &&
    typeof colDef.valueFormatter !== 'string' &&
    colDef.useValueFormatterForExport
  ) {
    const valueFormatterParams: ValueFormatterParams = {
      ...params,
      data: params.node?.data,
      node: params.node!,
      colDef,
    };

    return prepareExcelCellValue(colDef.valueFormatter(valueFormatterParams));
  }

  return prepareExcelCellValue(params.value);
}

export function prepareExcelCellValue(value: unknown): string {
  if (typeof value === 'string') {
    if (isIntegerString(value) && (value.startsWith('0') || value.length > 8)) {
      return `'${value}`;
    }
  }

  return value as string;
}

const intRegex = /^\d+$/;

export function isIntegerString(value: string): boolean {
  return intRegex.test(value);
}

export function columnKeysExcl(columns: ColDef[], ...excludeColIds: string[]): string[] {
  return columns
    .map((colDef) => {
      if (colDef.headerCheckboxSelection) {
        return '';
      }
      if (!colDef.colId) {
        return '';
      }
      if (excludeColIds && excludeColIds.indexOf(colDef.colId) !== -1) {
        return '';
      }

      return colDef.colId;
    })
    .filter((colKey) => colKey !== '');
}

<template>
  <v-dialog
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    max-width="400"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
    @click:outside="!showSummary && closeDialog()"
    @keydown.esc="showSummary ? (showSummary = false) : closeDialog()"
    @shortkey.native="showSummary ? (showSummary = false) : closeDialog()"
  >
    <marketplace-order-form
      :new-order-security="newOrderSecurity"
      :new-order-side="newOrderSide"
      :order-ref="orderRef"
      :show-summary.sync="showSummary"
      @action="$emit('action', $event)"
      @cancel="closeDialog"
      @success="closeDialog"
    />
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';

import { PropType } from 'vue';
import Component from 'vue-class-component';
import MarketplaceOrderForm from '@/modules/marketplace/components/MarketplaceOrderForm.vue';
import { OrderSide } from '@/modules/marketplace/types/marketplace';
import { Security } from '@/modules/common/models';

@Component({
  props: {
    orderRef: String,
    newOrderSide: String as PropType<OrderSide>,
    newOrderSecurity: Object as PropType<Security>,
  },
  components: {
    MarketplaceOrderForm,
  },
})
export default class MarketplaceOrderFormDialog extends Vue {
  // props
  protected readonly orderRef?: string;
  protected readonly newOrderSide?: 'BORROWER' | 'LENDER';
  protected readonly newOrderSecurity?: Security;

  protected showSummary = false;

  protected closeDialog(): void {
    this.$emit('close-modal');
  }
}
</script>

import { format as dateFormatter, parse } from 'date-fns';
import { PbDate } from '@/connect';

/**
 * Formats a date object to a string
 */
export function formatDate(
  date: unknown,
  format: string,
  options?: Parameters<typeof dateFormatter>[2]
): string {
  try {
    if (typeof date === 'number' || date instanceof Date) {
      return dateFormatter(date, format, options);
    } else if (typeof date === 'string') {
      // attempt to turn into a date object
      const parsedDate = new Date(date);
      if (isNaN(parsedDate.valueOf())) throw new Error('invalid date');

      return dateFormatter(parsedDate, format, options);
    } else if (date instanceof PbDate) {
      // attempt to turn PbDate into a Date object
      // NOTE: In Date, the monthIndex is a number between 0 and 11 (January to December)
      //       In PbDate, the month is a number between 1 and 12
      const parsedDate = new Date(date.year, date.month - 1, date.day);
      if (isNaN(parsedDate.valueOf())) throw new Error('invalid date');

      return dateFormatter(parsedDate, format, options);
    } else {
      return '';
    }
  } catch (err) {
    return `${date}`;
  }
}

export function parseDate(date: string, format: string = 'yyyy-MM-dd', timezone?: string): Date {
  timezone ??= Intl.DateTimeFormat().resolvedOptions().timeZone;
  const now = new Date(new Date().toLocaleString('en', { timeZone: timezone }));
  return parse(date, format, now);
}

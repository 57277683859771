<!-- eslint-disable @typescript-eslint/unified-signatures -->

<script setup lang="ts">
import { computed, shallowRef, watchEffect } from 'vue';
import { AgreementInfo } from '@/modules/agreements/models';
import { formatAgreementInfo } from '@/modules/agreements/utils';
import { AgreementStatus, Side, serviceAgreements } from '@/connect';
import { useSnackbarManager } from '@/modules/snackbar/services/snackbar-manager';
import i18n from '@/localisation/i18n';
import { Security } from '@/modules/common/models';

const props = withDefaults(
  defineProps<{
    value?: AgreementInfo | null;
    statuses?: AgreementStatus[];
    label: string;
    autofocus?: boolean;
    disabled?: boolean;
    clearable?: boolean;
    dense?: boolean;
    errorMessages?: string[];
    placeholder?: string;
    prependItem?: AgreementInfo;
    side?: Side;
    security?: Security;
    excludeAgreementIds: string[];
  }>(),
  {
    value: null,
    statuses: () => [AgreementStatus.ACTIVE],
    errorMessages: undefined,
    placeholder: undefined,
    prependItem: undefined,
    side: undefined,
    security: undefined,
  }
);

const emit = defineEmits<{
  (event: 'input', item: AgreementInfo): void;
  (event: 'change', item: AgreementInfo): void;
  (event: 'blur'): void;
}>();

const snackbarManager = useSnackbarManager();

const isLoading = shallowRef(false);
const queriedItems = shallowRef<AgreementInfo[]>([]);
const searchTerm = shallowRef('');
const filteredItems = computed(() =>
  !searchTerm.value
    ? queriedItems.value
    : queriedItems.value.filter(
        (info) =>
          info.displayId.includes(searchTerm.value) || info.shortName.includes(searchTerm.value)
      )
);
const autocompleteItems = computed(() =>
  (props.prependItem ? [props.prependItem, ...filteredItems.value] : filteredItems.value).filter(
    ({ id }) => !props.excludeAgreementIds.includes(id)
  )
);

const queryConfig = computed(() => ({
  statuses: props.statuses ?? [AgreementStatus.ACTIVE],
  side: props.side,
  instrument: props.security?.cusip,
}));

watchEffect(search);

function onItemSelected(item: AgreementInfo): void {
  emit('input', item);
}

function onChange(item: AgreementInfo): void {
  emit('change', item);
}

async function search(): Promise<void> {
  isLoading.value = true;

  const result = await serviceAgreements.queryAgreements(queryConfig.value);
  if (result.success) {
    queriedItems.value = result.data.agreements.map(AgreementInfo.fromData);
  } else {
    queriedItems.value = [];
    snackbarManager.error(i18n.t(result.error, result.details) as string);
  }

  isLoading.value = false;
}
</script>

<template>
  <VAutocomplete
    auto-select-first
    :autofocus="autofocus"
    :clearable="clearable"
    :dense="dense"
    :disabled="disabled"
    :error-messages="errorMessages"
    hide-no-data
    item-value="id"
    :items="autocompleteItems"
    :label="label"
    :loading="isLoading"
    no-filter
    :placeholder="placeholder || 'search...'"
    return-object
    :value="value"
    @blur="emit('blur')"
    @change="onChange"
    @input="onItemSelected"
    @update:search-input="(value) => (searchTerm = value)"
  >
    <template #selection="data">
      {{ formatAgreementInfo(data.item) }}
    </template>
    <template #item="data">
      <VListItemContent>
        <VListItemTitle>
          {{ formatAgreementInfo(data.item) }}
        </VListItemTitle>
      </VListItemContent>
    </template>
  </VAutocomplete>
</template>

<style lang="scss" scoped>
::v-deep .v-select__selections {
  white-space: nowrap;
  flex-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>

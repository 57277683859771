import { ColDef } from 'ag-grid-enterprise';
import { alignLeft, alignRight, component, mergeClasses } from './utils';
import { formatPrettyNumber } from '@/modules/common/components/pretty-number';
import * as cols from './common';
import { h } from 'vue';
import { VBtn, VChip, VIcon, VTooltip } from 'vuetify/lib';
import i18n from '@/localisation/i18n';
import { getEventTypeColor, timeInForceAbbr } from '@/modules/marketplace/helpers/marketplace';
import { formatDate } from '@/utils/helpers/dates';
import { rateAdapter } from './common';
import FormatEventInitiator from '@/modules/common/components/FormatEventInitiator.vue';
import {
  EventInitiator,
  OmsHistoryExecutedItem,
  OmsHistoryItem,
  OmsOrderSummary,
} from '@/modules/marketplace/models';
import { AgreementInfo } from '@/modules/agreements/models';
import { formatAgreementInfo } from '@/modules/agreements/utils';
export {} from './common';

interface Config {
  cellHighlight: (name: keyof OmsOrderSummary, index: number) => string;
}

export function eventTimestamp(): ColDef<OmsHistoryItem> {
  return {
    field: 'eventTimestamp',
    colId: 'eventTimestamp',
    headerName: 'When',
    sortable: true,
    valueFormatter: (params) => formatDate(params.value, 'MMM d h:mm a'),
    ...mergeClasses([alignLeft(), { cellClass: 'highlight' }]),
  };
}

const eventTypeAdaptor = component<{
  item: OmsHistoryExecutedItem;
  openLoanDetails: (loanId: string | null) => void;
}>(
  (props) => () =>
    h('div', { style: { display: 'contents' } }, [
      h(
        VChip,
        {
          class: 'text-uppercase mr-2',
          props: { color: getEventTypeColor(props.item.eventType), xSmall: true },
        },
        [h('span', undefined, i18n.tc(`orderHistory.eventType.${props.item.eventType}`))]
      ),
      props.item.eventType === 'EXECUTED' && props.item.loanId
        ? h(
            VBtn,
            {
              class: 'icon-action',
              props: { icon: true, size: 24 },
              attrs: { 'data-test': 'open-loans-details' },
              on: { click: () => props.openLoanDetails(props.item.loanId) },
            },
            [h(VIcon, { props: { size: 24 } }, 'mdi-eye')]
          )
        : props.item.eventType === 'EXECUTED' && props.item.loanId === null
          ? h(
              VTooltip,
              {
                scopedSlots: {
                  activator: ({ on, attrs }) =>
                    h(VIcon, {
                      class: 'icon-action tooltip-icon',
                      attrs: {
                        'data-test': 'open-loans-details',
                        ...attrs,
                      },
                      props: { icon: true, size: 24 },
                      on,
                    }),
                },
                props: { bottom: true },
              },
              [h('span', undefined, 'Processing execution, details not available yet')]
            )
          : undefined,
    ])
);

export function eventType({
  openLoanDetails,
}: {
  openLoanDetails: (loanId: string | null) => void;
}): ColDef<OmsHistoryItem> {
  return {
    field: 'eventType',
    colId: 'eventType',
    sortable: false,
    headerName: 'Event',
    cellRendererSelector: (params) =>
      eventTypeAdaptor({
        item: params.data as OmsHistoryExecutedItem,
        openLoanDetails,
      }),
    ...alignLeft(),
  };
}

export function routingStatus(config: Config): ColDef<OmsHistoryItem> {
  return {
    field: 'summary.routingStatus',
    colId: 'routingStatus',
    sortable: false,
    headerName: 'Active',
    valueFormatter: (params) => {
      return params.value === 'ROUTED' ? 'Active' : params.value === 'PENDING' ? 'Pending' : '–';
    },
    ...mergeClasses([
      { cellClass: (params) => config.cellHighlight('routingStatus', params.rowIndex) },
    ]),
  } as ColDef<OmsHistoryItem>;
}

export function quantity(config: Config): ColDef<OmsHistoryItem> {
  const colQuantity = cols.quantity({
    field: 'summary.quantity',
    headerName: 'Order Qty.',
    sortable: false,
  });
  return {
    ...colQuantity,
    colId: 'quantity',
    ...mergeClasses([
      colQuantity,
      { cellClass: (params) => config.cellHighlight('quantity', params.rowIndex) },
    ]),
  } as ColDef<OmsHistoryItem>;
}

export function openQuantity(config: Config): ColDef<OmsHistoryItem> {
  return {
    field: 'summary',
    colId: 'openQuantity',
    sortable: false,
    headerName: 'Open Qty.',
    valueFormatter: (params) => formatPrettyNumber(params.value.quantity - params.value.filled),
    ...mergeClasses([
      alignLeft(),
      { cellClass: (params) => config.cellHighlight('openQuantity', params.rowIndex) },
    ]),
  };
}

export function filled(config: Config): ColDef<OmsHistoryItem> {
  return {
    field: 'summary.filled',
    colId: 'filled',
    sortable: false,
    headerName: 'Exec Qty.',
    valueFormatter: (params) => formatPrettyNumber(params.value),
    ...mergeClasses([
      alignLeft(),
      { cellClass: (params) => config.cellHighlight('filled', params.rowIndex) },
    ]),
  };
}

export function rate(config: Config): ColDef<OmsHistoryItem> {
  return {
    field: 'summary.rate',
    colId: 'rate',
    sortable: false,
    headerName: 'Rate Limit',
    valueFormatter: (params) => params.value,
    cellRendererSelector: (params) =>
      rateAdapter({
        rate: params.value,
        rateModifier: undefined,
        precision: undefined,
      }),
    ...mergeClasses([
      alignRight(),
      { cellClass: (params) => config.cellHighlight('rate', params.rowIndex) },
    ]),
  };
}

export function avgExecutionRate(config: Config): ColDef<OmsHistoryItem> {
  return {
    field: 'summary.avgExecutionRate',
    colId: 'avgExecutionRate',
    sortable: false,
    headerName: 'Avg Exec Rate',
    valueFormatter: (params) => params.value,
    cellRendererSelector: (params) =>
      rateAdapter({
        rate: params.value,
        rateModifier: undefined,
        precision: undefined,
      }),
    ...mergeClasses([
      alignRight(),
      { cellClass: (params) => config.cellHighlight('avgExecutionRate', params.rowIndex) },
    ]),
  };
}

const agreementAdapter = component<{
  agreements?: AgreementInfo[];
}>(
  (props) => () =>
    props.agreements
      ? h(
          'div',
          { style: { display: 'contents' } },
          props.agreements.map((agrmnt, agrmntIndex) =>
            h('span', undefined, [
              agrmntIndex !== 0 ? h('span', undefined, ', ') : undefined,
              h('span', { attrs: { title: formatAgreementInfo(agrmnt) } }, [agrmnt.displayId]),
            ])
          )
        )
      : h('span', undefined, 'Any')
);

export function agreement(config: Config): ColDef<OmsHistoryItem> {
  return {
    field: 'summary.agreements',
    colId: 'agreement',
    headerName: 'Agreement',
    valueFormatter: (params) => params.value,
    cellRendererSelector: (params) => agreementAdapter({ agreements: params.value }),
    ...mergeClasses([
      alignLeft(),
      { cellClass: (params) => config.cellHighlight('agreements', params.rowIndex) },
    ]),
  };
}

export function minQuantity(config: Config): ColDef<OmsHistoryItem> {
  return {
    field: 'summary.minQuantity',
    colId: 'minQuantity',
    sortable: false,
    headerName: 'Min Qty.',
    valueFormatter: (params) => formatPrettyNumber(params.value),
    ...mergeClasses([
      alignLeft(),
      { cellClass: (params) => config.cellHighlight('minQuantity', params.rowIndex) },
    ]),
  };
}

const orderTypeAdapter = component<{
  orderType: string;
}>((props) => () => h('span', { class: 'text-capitalize' }, props.orderType.toLowerCase()));

export function orderType(config: Config): ColDef<OmsHistoryItem> {
  return {
    field: 'summary.orderType',
    colId: 'orderType',
    sortable: false,
    headerName: 'Type',
    cellRendererSelector: (params) => orderTypeAdapter({ orderType: params.value }),
    ...mergeClasses([
      alignLeft(),
      { cellClass: (params) => config.cellHighlight('orderType', params.rowIndex) },
    ]),
  };
}

export function timeInForceType(config: Config): ColDef<OmsHistoryItem> {
  return {
    field: 'summary.timeInForceType',
    colId: 'timeInForceType',
    sortable: false,
    headerName: 'Time In Force',
    valueFormatter: (params) => timeInForceAbbr(params.value),
    ...mergeClasses([
      alignLeft(),
      { cellClass: (params) => config.cellHighlight('timeInForceType', params.rowIndex) },
    ]),
  };
}

const formatEventInitiatorAdapter = component<{
  initiator: EventInitiator;
}>((props) => () => h(FormatEventInitiator, { props }));

export function initiator(): ColDef<OmsHistoryItem> {
  return {
    field: 'initiator',
    colId: 'initiator',
    sortable: false,
    headerName: 'Initiator',
    valueFormatter: (params) => params.value,
    cellRendererSelector: (params) => formatEventInitiatorAdapter({ initiator: params.value }),
    ...alignLeft(),
  };
}

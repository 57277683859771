<template>
  <v-card class="flex d-flex flex-column col-xl-8">
    <v-card-title>
      <span class="headline">Manage IP Range Allow List</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col class="pa-0" cols="12">
            <ip-allow-list-manager></ip-allow-list-manager>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import IpAllowListManager from '@/modules/common/components/IpAllowListManager.vue';

@Component({
  components: { IpAllowListManager },
})
export default class TraderIpAllowListManager extends Vue {}
</script>

<script setup lang="ts">
import { useMarketplaceApiService } from '@/modules/marketplace/services/marketplace-api.service';
import { TopOfBookWatchlist } from '@/modules/marketplace/models';
import { computed, onMounted, shallowRef } from 'vue';

const props = defineProps<{
  modelValue?: string | null;
}>();

const emit = defineEmits(['input']);

const watchlists = shallowRef<TopOfBookWatchlist[]>([]);

onMounted(fetchWatchlists);

async function fetchWatchlists() {
  watchlists.value = await useMarketplaceApiService().fetchWatchlists();
}

const selected = computed({
  get: () => props.modelValue,
  set: (value) => emit('input', value),
});
</script>

<template>
  <VSelect
    v-model="selected"
    clearable
    item-text="name"
    item-value="displayId"
    :items="watchlists"
    label="Watchlist"
    return-object
  />
</template>

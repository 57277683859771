<script setup lang="ts">
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import AgreementsTable from '@/modules/agreements/components/AgreementsTable.vue';
import AgreementView from '@/modules/agreements/components/AgreementView.vue';
import { shallowRef } from 'vue';
import { Agreement, Side } from '@/connect';

const agreementView = shallowRef<null | Agreement>(null);

const sideItems = [
  { text: 'Lender', value: Side.LENDER },
  { text: 'Borrower', value: Side.BORROWER },
];

const selectedSide = shallowRef(Side.LENDER);
</script>

<template>
  <div class="wrapper d-flex flex-column fill-height">
    <DashboardPanel no-collapse title="Agreements">
      <v-card class="flex d-flex flex-column">
        <!-- Table header (filters, tabs) -->
        <v-container class="py-0" fluid>
          <v-row no-gutters>
            <v-col class="d-flex align-center" cols="4">
              <v-select
                v-model="selectedSide"
                class="side"
                clearable
                :items="sideItems"
                label="Side"
              />
            </v-col>
          </v-row>
        </v-container>
        <AgreementView :agreement="agreementView" @cancel="agreementView = null" />
        <AgreementsTable
          data-test="agreements-table"
          :side="selectedSide"
          @view="(value) => (agreementView = value)"
        />
      </v-card>
    </DashboardPanel>
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  height: 100%;
}
</style>

<template>
  <div>
    <dashboard-panel no-collapse title="Executions">
      <v-card class="flex d-flex flex-column">
        <!-- Table header (filters, tabs) -->
        <v-container class="py-0" fluid>
          <v-row no-gutters>
            <v-col class="d-flex align-center" cols="4">
              <!-- Filters -->
              <counterparty-search
                v-model="selectedCompany"
                class="companies"
                clearable
                placeholder="All companies"
              />
            </v-col>
          </v-row>
        </v-container>
        <marketplace-executions-table as-broker :company-id-filter="selectedCompany?.companyId" />
      </v-card>
    </dashboard-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import MarketplaceExecutionsTable from '@/modules/marketplace/components/MarketplaceExecutionsTable.vue';
import CounterpartySearch from '@/modules/user-accounts/components/CounterpartySearch.vue';
import { CompanyInfo } from '@/modules/common/models';

@Component({
  provide() {
    return {
      gridStateKey: 'BrokerMarketplaceExecutionsView',
    };
  },
  components: {
    CounterpartySearch,
    MarketplaceExecutionsTable,
    DashboardPanel,
  },
})
export default class BrokerMarketplaceExecutionsView extends Vue {
  protected selectedCompany: CompanyInfo | null = null;
}
</script>

<style lang="scss"></style>

import Decimal from 'decimal.js';
import { RoundingRule } from '@/modules/sec-lending/helpers/contract-details';
import { CompanyInfo } from '@/modules/common/models';
import { AgreementInfo } from '@/modules/agreements/models';
import { SettlementType } from '@/connect';

export interface NsccAccount {
  id: number;
  mainID: string;
  subID: string;
  isActive: boolean;

  // form validation helpers
  errorMainID: string;
  errorSubID: string;
}

export type DeliverOrderSender = 'provable' | 'loanet' | 'self';

export interface CompanyAccount {
  id: string;
  name: string;
  kind: string;
  tradingPermissions: number; // bit-set with allowed trade actions
  sponsor: CompanyInfo | null;

  defaultOrderSoftLimit: Decimal | null;
  defaultOrderHardLimit: Decimal | null;

  profileID: string;
  profileLabel: string;

  displayBoxID: string | null;
  nsccAccounts: NsccAccount[];
  preferredIndependentAmountRate: Decimal;
  preferredRoundingRule: RoundingRule | null;
  hasLoanetIntegration: boolean;
  deliverOrderSender: DeliverOrderSender;
  benchmarkType?: string;
  benchmarkPrice?: Decimal;
  defaultOmsAgreements: AgreementInfo[];
  bilateralCounterparties: CompanyInfo[];
  enabledSettlementTypes: SettlementType[];
  cutOffTime: string | null;
  complianceEmailAddresses: string[];
}

export type RawCompanyAccount = Raw<
  CompanyAccount,
  {
    enabledSettlementTypes: string[];
    complianceEmailAddresses: string[] | null;
  }
>;

export interface CompanyAccountRequest
  extends Omit<CompanyAccount, 'sponsor' | 'bilateralCounterparties' | 'enabledSettlementTypes'> {
  sponsorCompanyId: string | null;
  bilateralCounterpartyIds: string[];
  enabledSettlementTypes: string[];
}

export interface RawBrokerCompanies {
  companies: RawCompanyAccount[];
}

export function normalizeCompanyAccount(c: RawCompanyAccount): void {
  // input from server will have rate a string, we convert it to Decimal
  const cWithStrRate: { preferredIndependentAmountRate: string | Decimal } = c;
  if (typeof cWithStrRate.preferredIndependentAmountRate === 'string') {
    c.preferredIndependentAmountRate = new Decimal(cWithStrRate.preferredIndependentAmountRate);
  }

  // input from server will have soft-order-limit as a string, we convert it to Decimal
  const cWithStrOrderSoftLimit: { defaultOrderSoftLimit: string | Decimal | null } = c;
  if (typeof cWithStrOrderSoftLimit.defaultOrderSoftLimit === 'string') {
    c.defaultOrderSoftLimit = new Decimal(cWithStrOrderSoftLimit.defaultOrderSoftLimit);
  }

  // input from server will have hard-order-limit as a string, we convert it to Decimal
  const cWithStrOrderHardLimit: { defaultOrderHardLimit: string | Decimal | null } = c;
  if (typeof cWithStrOrderHardLimit.defaultOrderHardLimit === 'string') {
    c.defaultOrderHardLimit = new Decimal(cWithStrOrderHardLimit.defaultOrderHardLimit);
  }

  // input from server will have benchmark price as a string, we convert it to Decimal
  // const cWithStrBenchmarkPrice: { benchmarkPrice: Decimal | undefined } = c;
  if (c.benchmarkPrice) {
    c.benchmarkPrice = new Decimal(c.benchmarkPrice);
  }

  if (c.enabledSettlementTypes) {
    c.enabledSettlementTypes = c.enabledSettlementTypes.map((st) => {
      return typeof st === 'string' ? SettlementType[st] : st;
    });
  } else {
    c.enabledSettlementTypes = [];
  }

  c.complianceEmailAddresses = c.complianceEmailAddresses || [];
}

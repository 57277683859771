import { useStoreSocket } from '@/store/store-socket';
import { useStoreGridApiExplorer } from '@/modules/marketplace/store/store-grid-api-explorer';
import { IRowNode } from 'ag-grid-enterprise';
import {
  BookOrder,
  QueryMarketplaceInstrument,
  QueryMarketplaceItem,
} from '@/connect/gen/modules/apiengine/services/venue/venue_pb';
import { JsonValue } from '@bufbuild/protobuf';
import { OmsOrderType } from '@/connect';
import { throttle } from 'lodash';
import { onUnmounted } from 'vue';
import { equals } from '@/modules/common/components/ag-table/columns/utils';

type OrderKey = 'borrowOrder' | 'lendOrder';

interface Target {
  node: IRowNode;
  key: OrderKey;
  order: BookOrder;
}

export function useSocketExplorer(): void {
  const { getGridApi, expandAfterModelUpdated } = useStoreGridApiExplorer();

  const storeSocket = useStoreSocket();
  storeSocket.on('refreshVenueOrders', refreshVenueOrders);
  onUnmounted(() => storeSocket.off('refreshVenueOrders', refreshVenueOrders));

  function refreshVenueOrders(payload: JsonValue) {
    const order = BookOrder.fromJson(payload);
    // we are not interested in "MARKET", avoid unecessary re-renders and fetching
    if (order.orderType === OmsOrderType.MARKET) return;

    const target = findTarget(order.rootClientOrderRef);

    if (target && order.active) {
      handleUpdate(order, target);
    } else {
      handleAddOrRemove(order);
    }
  }

  function handleUpdate(order: BookOrder, target: Target) {
    if (order.rate === target.order.rate) {
      // this is the only case we don't need to refresh
      // because we know for sure order is in the same sorting position
      getGridApi().applyServerSideTransaction({
        route: [order.cusip],
        update: [{ ...target.node.data, [target.key]: order }],
      });
    } else {
      // when rate is different, sorting position can change after refresh
      // we need to manually flash the cells
      flashCellsAfterModelUpdated(target, order);
      refreshCusipOrders(order.cusip);
    }
  }

  function handleAddOrRemove(order: BookOrder) {
    // when "add" or "remove" we need to refresh because
    // FE doesn't know how to make order pairs or sort
    refreshCusipOrders(order.cusip);
    // could be the first or last order of a group, so refresh is needed
    refreshGroups();
  }

  const refreshGroups = throttle(
    () => {
      expandAfterModelUpdated();
      getGridApi().refreshServerSide({ purge: false });
    },
    1000,
    {
      leading: true,
      trailing: true, // because we want the most recent data
    }
  );

  function refreshCusipOrders(cusip: string) {
    getGridApi().refreshServerSide({ route: [cusip], purge: false });
  }

  function flashCellsAfterModelUpdated(target: Target, incomingOrder: BookOrder) {
    getGridApi().addEventListener('modelUpdated', flashCells);
    function flashCells() {
      // we need a new target because new sorting position after model updated
      const newTarget = findTarget(incomingOrder.rootClientOrderRef);
      if (!newTarget) return;
      getGridApi().flashCells({
        rowNodes: [newTarget.node],
        columns: getChangedColumns(target, incomingOrder),
      });
      getGridApi().removeEventListener('modelUpdated', flashCells);
    }
  }

  function getChangedColumns(target: Target, incomingOrder: BookOrder) {
    const columns: string[] = [];
    if (incomingOrder.rate !== target.order.rate) {
      columns.push(getColumnName(target.key, 'Rate'));
    }
    if (incomingOrder.quantity !== target.order.quantity) {
      columns.push(getColumnName(target.key, 'Quantity'));
    }
    if (incomingOrder.contractAmount !== target.order.contractAmount) {
      columns.push(getColumnName(target.key, 'Value'));
    }
    if (!equals.array(incomingOrder.agreementIds, target.order.agreementIds)) {
      columns.push(getColumnName(target.key, 'Agreements'));
    }
    return columns;
  }

  function getColumnName(orderKey: OrderKey, field: string) {
    return `${orderKey === 'lendOrder' ? 'lend' : 'borrow'}${field}`;
  }

  function findTarget(orderRef: string) {
    for (const node of getAllMarketplaceItemNodes()) {
      let key: OrderKey | null = null;
      if (node.data?.lendOrder?.rootClientOrderRef === orderRef) key = 'lendOrder';
      if (node.data?.borrowOrder?.rootClientOrderRef === orderRef) key = 'borrowOrder';
      if (key) return { node, key, order: node.data?.[key] as BookOrder };
    }
    return null;
  }

  // only return QueryMarketplaceItem, ignore QueryMarketplaceInstrument (because they are node.data groups)
  function getAllMarketplaceItemNodes() {
    const items: Array<IRowNode<QueryMarketplaceItem>> = [];

    getGridApi().forEachNode(
      (node: IRowNode<QueryMarketplaceItem | QueryMarketplaceInstrument>) => {
        if (node.data instanceof QueryMarketplaceItem) {
          items.push(node as IRowNode<QueryMarketplaceItem>);
        }
      }
    );
    return items;
  }
}

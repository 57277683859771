<template>
  <v-card class="flex d-flex flex-column">
    <v-container class="py-0" fluid>
      <v-row no-gutters>
        <v-col class="d-flex align-center pt-sm-4" cols="12" lg="6" md="12" sm="12" xl="6">
          <!-- @TODO: check if borrow/lend buttons will be restricted to certain users -->
          <div class="gap-1 d-flex flex-wrap">
            <aurora-btn-dropdown
              v-if="canBorrow"
              color="secondary"
              data-test="borrow-button"
              :disabled="!hasTraderUserRole"
              main-text="Borrow"
              split
              timeframe="createLoans"
              @click="$emit('create-order', 'BORROWER')"
            >
              <v-list dense>
                <v-list-item data-test="upload-demand-orders" @click="uploadDialog = Side.BORROWER">
                  <v-list-item-content>
                    <v-list-item-title>Upload Demand Orders</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon dense small> mdi-upload</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </aurora-btn-dropdown>

            <aurora-btn-dropdown
              v-if="canLend"
              color="secondary"
              data-test="lend-button"
              :disabled="!hasTraderUserRole"
              main-text="Lend"
              split
              timeframe="createLoans"
              @click="$emit('create-order', 'LENDER')"
            >
              <v-list dense>
                <v-list-item data-test="upload-supply-orders" @click="uploadDialog = Side.LENDER">
                  <v-list-item-content>
                    <v-list-item-title>Upload Supply Orders</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon dense small> mdi-upload</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </aurora-btn-dropdown>
          </div>

          <template v-if="selectedItems.length">
            <span class="ml-2 mr-2">
              {{
                selectedItems.length ? `${selectedItems.length}/${getTotalCount()} selected` : ''
              }}
            </span>
          </template>

          <aurora-btn
            v-if="selectedItems.length"
            color="red"
            data-test="batch-action-cancel"
            :disabled="!hasTraderUserRole"
            small
            timeframe="createLoans"
            @click="openDialog = 'cancelAll'"
          >
            Cancel
          </aurora-btn>

          <aurora-btn
            v-if="selectedItems.length"
            color="secondary ml-2"
            data-test="order-list-edit-btn"
            :disabled="!hasTraderUserRole"
            small
            timeframe="createLoans"
            @click="openDialog = 'modifyAll'"
          >
            Edit
          </aurora-btn>
        </v-col>

        <v-col class="gap-1 d-flex align-center pt-sm-4" cols="12" lg="6" md="12" sm="12" xl="6">
          <simple-security-search
            v-model="selectedSecurity"
            class="simple-security-search"
            clearable
            label="Security"
          />
          <v-select v-model="selectedSide" class="side" clearable :items="sideItems" label="Side" />
          <v-select
            v-model="selectedRouting"
            class="side"
            clearable
            :items="routingItems"
            label="Active/Inactive"
          />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-switch
                  v-model="showAllMarketplaceOrders"
                  class="mt-0"
                  data-test="show-all-orders"
                  :false-value="false"
                  hide-details
                  label="show archived"
                />
              </span>
            </template>
            <span class="tip-background">
              Enabling show archived will include in the results Orders that were closed before
              today
            </span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row class="mt-n2 mb-4" no-gutters>
        <v-tabs
          ref="tabs"
          background-color="transparent"
          :value="Object.values(tabs).indexOf(selectedTab)"
        >
          <v-tab
            v-for="tabName in Object.keys(tabs)"
            :key="tabName"
            :value="tabName"
            @change="
              selectedItems = [];
              resetSelection();
              selectedTab = tabs[tabName];
            "
          >
            {{ tabName }}
          </v-tab>
        </v-tabs>
      </v-row>
    </v-container>
    <!-- Dialog will not monitor changes to selectedItems list, it will only be used to initialize a local list on mount. -->
    <marketplace-batch-cancel-all-dialog
      v-if="openDialog === 'cancelAll'"
      :items="selectedItems"
      @back="openDialog = null"
      @close-modal="openDialog = null"
      @success="
        selectedItems = [];
        resetSelection();
      "
    />

    <!-- Dialog will not monitor changes to selectedItems list, it will only be used to initialize a local list on mount. -->
    <marketplace-batch-modify-all-dialog
      v-if="openDialog === 'modifyAll'"
      :items="selectedItems"
      v-on="$listeners"
      @back="openDialog = null"
      @close-modal="openDialog = null"
      @success="
        selectedItems = [];
        resetSelection();
      "
    />

    <marketplace-orders-table
      data-test="marketplace-orders-table"
      :omit-headers="['companyId', 'orderRef']"
      :query-data="queryData"
      :selected-items.sync="selectedItems"
      :sort="sort"
      @action="$emit('action', $event)"
      @edit-order="$emit('edit-order', $event)"
      @ready="onReady"
      @view-order="$emit('view-order', $event)"
    />

    <marketplace-orders-upload-dialog
      v-if="uploadDialog !== null"
      :side="uploadDialog"
      @close-modal="uploadDialog = null"
    />
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import Component from 'vue-class-component';
import { Security } from '@/modules/common/models';
import MarketplaceBatchCancelAllDialog from '@/modules/marketplace/components/MarketplaceBatchCancelAllDialog.vue';
import SimpleSecuritySearch from '@/modules/manual-loan/components/SimpleSecuritySearch.vue';
import MarketplaceBatchModifyAllDialog from '@/modules/marketplace/components/MarketplaceBatchModifyAllDialog.vue';
import MarketplaceOrdersTable from '@/modules/marketplace/components/MarketplaceOrdersTable.vue';
import MarketplaceOrderDetailsDialog from '@/modules/marketplace/components/MarketplaceOrderDetailsDialog.vue';
import { SocketEvents } from '@/store/store';
import MarketplaceOrdersUploadDialog from '@/modules/marketplace/components/MarketplaceOrdersUploadDialog.vue';
import UploadProgressPopup from '@/modules/common/components/UploadProgressPopup.vue';
import BtnDropdown from '@/modules/common/components/BtnDropdown.vue';
import { Watch } from 'vue-property-decorator';
import { Order } from '@/connect/gen/modules/apiengine/services/oms/oms_pb';
import { LoadSuccessParams, SortModelItem } from 'ag-grid-enterprise';
import { serviceMarketplace } from '@/connect/services/marketplace';
import { QueryOrdersFilter } from '@/connect/gen/modules/apiengine/services/oms/oms_pb';
import { Side } from '@/connect/gen/consts/commonconsts_pb';
import { RoutingStatus } from '@/connect/gen/consts/omsconsts_pb';
import { useStoreSecurities } from '@/store/store-securities';
import { useStoreCompanies } from '@/store/store-companies';
import { useStoreAgreements } from '@/store/store-agreements';
import { Tab, tabs } from '@/modules/marketplace/helpers/marketplace';

function stubFn() {
  return;
}

@Component({
  components: {
    SimpleSecuritySearch,
    MarketplaceOrdersTable,
    MarketplaceOrderDetailsDialog,
    MarketplaceOrdersUploadDialog,
    UploadProgressPopup,
    BtnDropdown,
    MarketplaceBatchCancelAllDialog,
    MarketplaceBatchModifyAllDialog,
  },
  computed: {
    ...mapState(['socketEvents']),
    ...mapGetters(['hasTraderUserRole', 'canBorrow', 'canLend']),
  },
})
export default class MarketplaceOrdersList extends Vue {
  protected storeSecurities = useStoreSecurities();
  protected storeCompanies = useStoreCompanies();
  protected storeAgreements = useStoreAgreements();
  protected socketEvents!: SocketEvents;
  protected hasTraderUserRole!: boolean;
  protected canBorrow!: boolean;
  protected canLend!: boolean;

  protected tabs = tabs;
  protected selectedTab: Tab = tabs.all;
  protected showAllMarketplaceOrders = false;
  protected selectedSecurity: Security | null = null;
  protected sideItems = [
    { text: 'Lender', value: Side.LENDER },
    { text: 'Borrower', value: Side.BORROWER },
  ];
  protected selectedSide: Side | null = null;
  protected routingItems = [
    { text: 'Active', value: RoutingStatus.ROUTED },
    { text: 'Inactive', value: RoutingStatus.UNROUTED },
  ];
  protected selectedRouting: RoutingStatus | null = null;
  protected selectedItems: Order[] = [];
  protected openDialog: 'cancelAll' | 'modifyAll' | null = null;
  protected sort: SortModelItem = { colId: 'updatedAt', sort: 'desc' };

  protected uploadDialog: Side | null = null;
  protected orderRefToCancel: string | null = null;

  protected tableRefresh: (config: { purge: boolean }) => void = stubFn;
  protected resetSelection!: () => void;
  protected getTotalCount!: () => number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected Side = Side;

  @Watch('socketEvents.marketplace.orders')
  protected onSocketEvents(): void {
    // table is expected to remain more or less the same,
    // no need to purge the cache, just refresh and update the rows
    this.tableRefresh({ purge: false });
  }

  @Watch('selectedSecurity')
  @Watch('selectedSide')
  @Watch('selectedRouting')
  @Watch('showAllMarketplaceOrders')
  @Watch('selectedTab')
  protected onChangeFilters(): void {
    this.resetSelection();
    // purge the cache to avoid slow UI animations
    this.tableRefresh({ purge: true });
  }

  protected onReady(config: {
    refresh: (config: { purge: boolean }) => void;
    resetSelection: () => void;
    getTotalCount: () => number;
  }): void {
    this.tableRefresh = config.refresh;
    this.resetSelection = config.resetSelection;
    this.getTotalCount = config.getTotalCount;
  }

  protected async queryData(config: {
    page: number;
    pageSize: number;
    sort: SortModelItem;
    signal: AbortSignal;
  }): Promise<LoadSuccessParams | undefined> {
    const result = await serviceMarketplace.queryOrders({
      ...config,
      filter: this.getQueryFilter(),
    });

    if (!result.success) {
      this.$snackbar.show({ message: this.$i18n.t(result.error, result.details) as string });
      return;
    }

    this.storeSecurities.addSecurities(result.data.instruments);
    this.storeCompanies.addCompanies(result.data.companies);
    this.storeAgreements.addAgreements(result.data.agreements);

    return { rowData: result.data.orders, rowCount: result.data.totalCount };
  }

  protected getQueryFilter(): QueryOrdersFilter {
    return new QueryOrdersFilter({
      instruments: this.selectedSecurity ? [this.selectedSecurity.cusip] : [],
      side: this.selectedSide ?? undefined,
      routingStatus: this.selectedRouting ?? undefined,
      orderStatus: this.selectedTab?.orderStatus ?? undefined,
      hasExecuted: this.selectedTab?.hasExecuted ?? undefined,
      showAll: this.showAllMarketplaceOrders,
    });
  }
}
</script>

<style lang="scss" scoped>
.gap-1 {
  gap: 1rem;
}

.simple-security-search {
  width: 2.5rem;
}

.side {
  width: 2.5rem;
}

.filter-list {
  width: 2.5rem;
}
</style>

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <confirm-dialog-wrapper
          :options="{
            message: `You are about to ${isEnabled ? 'disable' : 'enable'} the IP range allow list.`,
            title: `${isEnabled ? 'Disable' : 'Enable'} IP range allow list`,
            rejectText: `Cancel`,
            acceptText: `${isEnabled ? 'Disable' : 'Enable'}`,
            color: `primary`,
            shouldManuallyConfirm: true,
          }"
          v-on="$listeners"
        >
          <template #default="{ confirm }">
            <v-switch
              v-model="isEnabled"
              data-test="ip-allow-list-switch"
              :disabled="actionInProgress === 'toggle' || !canEnable"
              :label="isEnabled ? 'Enabled' : 'Disabled'"
              readonly
              @click="canEnable ? confirm(() => toggleEnabled()) : null"
            />
          </template>
        </confirm-dialog-wrapper>
        <v-alert dense outlined text>
          {{
            isEnabled
              ? `Only users from the allowed IP ranges can access the platform.`
              : canEnable
                ? `IP range allow list is disabled.`
                : `IP range allow list is disabled. Please add IP ranges before enabling the allow list. (Your IP is ${clientConfig.clientIpAddress})`
          }}
        </v-alert>
      </v-col>
      <v-col v-if="allowedRanges.length" cols="12">
        <div class="table-container mt-4">
          <p class="ml-4 mb-0">Allowed Ranges</p>
          <div>
            <v-data-table
              disable-filtering
              disable-pagination
              disable-sort
              fixed-header
              hide-default-footer
              item-key="id"
              :items="allowedRanges"
              width="100%"
            >
              <template #item="{ item }">
                <tr>
                  <td>
                    {{ item.cidr }}
                  </td>
                  <td class="text-right">
                    <confirm-dialog-wrapper
                      :options="{
                        message: 'You are about to remove the IP range from the allow list.',
                        title: 'Remove IP range',
                        rejectText: 'Cancel',
                        acceptText: 'Remove range',
                      }"
                      v-on="$listeners"
                    >
                      <template #default="{ confirm }">
                        <v-btn
                          class="icon-action"
                          :disabled="actionInProgress === 'remove'"
                          icon
                          @click="confirm(() => removeRange(item))"
                        >
                          <v-icon size="24">mdi-close-circle</v-icon>
                        </v-btn>
                      </template>
                    </confirm-dialog-wrapper>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col cols="10">
        <v-text-field
          v-model="newRange"
          dense
          :disabled="actionInProgress === 'add'"
          :error-messages="newRangeError"
          label="Add new range"
          :loading="actionInProgress === 'add'"
          placeholder="Example: 192.168.0.1/24"
          @keyup.enter="addRange"
        />
      </v-col>
      <v-col class="text-right" cols="2">
        <v-btn
          class="ml-n2"
          color="primary"
          :disabled="actionInProgress === 'add'"
          :loading="actionInProgress === 'add'"
          @click="addRange"
          >Add</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import axios from 'axios';
import { errorString, i18nServerMessage } from '@/utils/helpers/rest-response';
import { mapGetters, mapState } from 'vuex';
import { ClientConfig } from '@/utils/helpers/rest';

const cidrRegex =
  /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/([0-9]|[12][0-9]|3[0-2]))$/;

const ipRegex =
  /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

interface Range {
  id: string;
  cidr: string;
}
interface RangesResponse {
  status: string;
  enabled: boolean;
  allowedRanges: Range[];
}

@Component({
  components: { IpAllowListManager },
  props: {
    companyId: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapState(['clientConfig']),
    ...mapGetters(['hasBrokerAdminRole']),
  },
})
export default class IpAllowListManager extends Vue {
  // broker-admin or trader-admin, both are allowed to edit the list
  protected hasBrokerAdminRole!: boolean;
  protected clientConfig!: ClientConfig;

  // props
  protected companyId!: string;

  protected allowedRanges: Range[] = [];
  protected isEnabled = false;
  protected newRange = '';
  protected newRangeError = '';
  protected actionInProgress: 'cancel' | 'remove' | 'add' | 'toggle' | null = null;

  // the trader-admin can only activate the allow list after adding at least one IP range (hopefully allowing themselves)
  protected get canEnable(): boolean {
    return this.isEnabled || this.hasBrokerAdminRole || this.allowedRanges.length > 0;
  }

  protected get adminBaseURL(): string {
    return this.hasBrokerAdminRole
      ? `/api/1/broker-admin/ip-restriction/${this.companyId}`
      : `/api/1/trader-admin/ip-restriction`;
  }

  protected async mounted(): Promise<void> {
    await this.fetchAllowedRanges();
  }

  protected async fetchAllowedRanges(): Promise<void> {
    const response = await axios.get<RangesResponse>(this.adminBaseURL);
    this.allowedRanges = response.data.allowedRanges;
    this.isEnabled = response.data.enabled;
  }

  protected isValidCidr(cidr: string): boolean {
    return cidrRegex.test(cidr);
  }

  protected isIP(cidr: string): boolean {
    return ipRegex.test(cidr);
  }

  protected async addRange(): Promise<void> {
    this.newRangeError = '';
    if (!this.isValidCidr(this.newRange) && !this.isIP(this.newRange)) {
      this.newRangeError = 'Invalid CIDR / IP format.';
      return;
    }

    if (this.allowedRanges.some((range) => range.cidr === this.newRange)) {
      this.newRangeError = 'Range already in the list.';
      return;
    }

    this.actionInProgress = 'add';
    try {
      await axios.post(this.adminBaseURL, {
        cidr: this.newRange,
      });
      this.$snackbar.confirm('IP range added to the allow list.');
      await this.fetchAllowedRanges();
    } catch (e) {
      this.$snackbar.error(i18nServerMessage(e as Error));
    } finally {
      this.actionInProgress = null;
    }

    this.newRange = '';
    await this.fetchAllowedRanges();
  }

  protected async removeRange(range: Range): Promise<void> {
    this.actionInProgress = 'remove';
    try {
      await axios.delete(`${this.adminBaseURL}/${range.id}`);
      this.$snackbar.confirm('IP range removed from the allow list.');
      await this.fetchAllowedRanges();
    } catch (e) {
      this.$snackbar.error(errorString(e as Error));
    } finally {
      this.actionInProgress = null;
    }
  }

  protected async toggleEnabled(): Promise<void> {
    this.actionInProgress = 'toggle';
    try {
      await axios.put(`${this.adminBaseURL}/${this.isEnabled ? 'disable' : 'enable'}`);
      this.$snackbar.confirm(
        `IP range allow list  successfully ${this.isEnabled ? 'disabled' : 'enabled'}.`
      );
    } catch (e) {
      this.$snackbar.error(errorString(e as Error));
    } finally {
      this.actionInProgress = null;
    }

    await this.fetchAllowedRanges();
  }
}
</script>

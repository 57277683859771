<script setup lang="ts">
import { computed } from 'vue';
import { SettlementType } from '@/connect';

const props = defineProps<{
  value: SettlementType;
}>();

const emit = defineEmits(['input']);

const model = computed({
  get: () => props.value,
  set: (value) => emit('input', value),
});
</script>

<template>
  <VSelect
    v-model="model"
    :items="[
      { value: SettlementType.UNSPECIFIED, text: 'All' },
      { value: SettlementType.NSCC, text: 'NSCC' },
      { value: SettlementType.BILATERAL, text: 'Bilateral' },
      { value: SettlementType.OCC, text: 'OCC' },
    ]"
    label="Settlement Type"
  />
</template>

export function joinSentence(list: string[], delim1: string, delim2: string): string {
  // concatenate all strings with the first delimiter
  let s = list.join(delim1);

  // replace the last delimiter by delimiter 2
  const index = s.lastIndexOf(delim1);
  if (index >= 0) {
    s = s.substring(0, index) + delim2 + s.substring(index + delim1.length);
  }

  return s;
}

export function isValidEmailAddress(s: string): boolean {
  return /^(?:(.+@.+\..+)(?:,\s*(.+@.+\..+))*)?$/.test(s);
}

import { ApiService } from '@/modules/common/services/api.service';
import { Api } from '@/modules/common/types/api';
import { Security } from '@/modules/common/models';
import { ManualLoanStatus } from '@/modules/manual-loan/constants/manual-loans.const';
import { SettlementType } from '@/modules/marketplace/types/marketplace';
import { Side } from '@/modules/user-accounts/types/user-accounts';
import { parseISO } from 'date-fns';
import { Decimal } from 'decimal.js';
import Vue from 'vue';
import { Raw } from '@/modules/common/helpers/api';
import {
  LoanRequestUploadResponse,
  RawLoanRequestUploadResponse,
} from '@/modules/manual-loan/models';

export class ManualLoansApiService extends ApiService {
  public static readonly instance = new this();
  private featureUrl = '/manual-loan';

  /**
   * Install this service as a Vue Plugin
   */
  public static install(v: typeof Vue): void {
    v.prototype.$api ??= {};
    v.prototype.$api.manualLoans = this.instance;
  }

  /**
   * Get a paginated list of all manual loans where the current user is the lender or borrower
   */
  public async getManualLoansList(
    isPreEstablished: boolean,
    status: ManualLoanStatus[] = [],
    offset = 0,
    limit = 100,
    dir: 'asc' | 'desc' = 'asc',
    sort?: string,
    filter?: string
  ): Promise<Api.ManualLoans.ManualLoanListResponse> {
    const url = this.baseUrl + this.featureUrl + `/requests`;
    const params = {
      isPreEstablished,
      filter,
      status: status.join(','),
      sort,
      dir,
      limit,
      offset,
    };
    const { data } = await this.axios.get<Api.ManualLoans.Raw.ListResponse>(url, {
      params,
    });

    // convert various values from raw API response to appropriate types
    return {
      ...data,
      data: data.data.map((item) => ({
        ...item,
        security: Security.fromData(item.equity),
        price: new Decimal(item.price),
        contractAmount: new Decimal(item.contractAmount),
        independentAmountRate: new Decimal(item.independentAmountRate),
        settlementAmount: new Decimal(item.settlementAmount),
        isPreEstablished: item.isPreEstablished,
        rate: new Decimal(item.rate),
        createdAt: parseISO(item.createdAt),
        updatedAt: parseISO(item.updatedAt),
        expiresAt: parseISO(item.expiresAt),
        initiatorSide: item.initiatorSide,
      })),
    };
  }

  /**
   * Find Securities given a search term
   */
  public async searchSecurities(
    query: string,
    abortSignal?: AbortSignal,
    limit = 100
  ): Promise<Api.ManualLoans.SecuritySearchResponse> {
    const url = this.baseUrl + this.featureUrl + '/equity/search';

    const params = { query, limit };
    const { data } = await this.axios.get<Raw<Api.ManualLoans.SecuritySearchResponse>>(url, {
      params,
      signal: abortSignal,
    });

    return {
      data: data.data.map<Security>(Security.fromData),
      total: data.total,
      limit,
    };
  }

  public async fetchSecurityByCusip(value: string): Promise<Security | null> {
    const { data } = await this.searchSecurities(value);
    if (data.length === 1 && data[0].cusip === value) {
      return data[0];
    }
    return null;
  }

  /**
   * Create a new basket of manual loans
   */
  public async createManualLoanBasket(
    basket: Api.ManualLoans.NewManualLoanItem[],
    side: Side = 'LENDER',
    isPreEstablished = false,
    settlementType: SettlementType = 'NSCC'
  ): Promise<void> {
    const url = this.baseUrl + this.featureUrl + `/${side}/requests`;
    const postData: Api.ManualLoans.CreateManualLoanBasketRequest = {
      requestItems: basket,
      isPreEstablished: isPreEstablished,
      settlementType: settlementType,
    };
    return this.axios.post(url, postData).then(() => undefined);
  }

  /**
   * Upload a file containing data to be parsed for a new Manual Loan basket
   */
  public async uploadManualLoanFile(file: File): Promise<LoanRequestUploadResponse> {
    const url = this.baseUrl + this.featureUrl + '/requests/file-upload';
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = { 'Content-Type': 'multipart/form-data' };
    const formData = new FormData();
    formData.append('file', file);

    const { data } = await this.axios.post<RawLoanRequestUploadResponse>(url, formData, {
      headers,
    });

    return LoanRequestUploadResponse.fromData(data);
  }

  /**
   * Batch update to the status of one or more manual loans
   */
  public async batchUpdateManualLoanStatus(
    manualLoans: Api.ManualLoans.StatusChangeRequest[],
    side: Side
  ): Promise<void> {
    const url = this.baseUrl + this.featureUrl + `/${side}/request/status`;
    return this.axios.post(url, manualLoans).then(() => undefined);
  }
}

export function useManualLoansApiService(): ManualLoansApiService {
  return Vue.prototype.$api.manualLoans;
}

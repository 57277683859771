<!-- eslint-disable @typescript-eslint/unified-signatures -->

<script setup lang="ts">
import { computed, nextTick, shallowRef } from 'vue';
import AgreementSearch from './AgreementSearch.vue';
import { AgreementInfo } from '@/modules/agreements/models';
import { AgreementStatus, Side } from '@/connect';
import { Security } from '@/modules/common/models';

const props = withDefaults(
  defineProps<{
    agreements: AgreementInfo[];
    statuses?: AgreementStatus[];
    side?: Side;
    security?: Security;

    // passthrough props to AgreementSearch
    label?: string;
    autofocus?: boolean;
    disabled?: boolean;
    clearable?: boolean;
    dense?: boolean;
    errorMessages?: string[];
    placeholder?: string;
    prependItem?: AgreementInfo;
  }>(),
  {
    side: undefined,
    security: undefined,
    label: undefined,
    errorMessages: undefined,
    placeholder: undefined,
    prependItem: undefined,
    agreements: () => [],
    statuses: () => [AgreementStatus.ACTIVE],
  }
);

const emit = defineEmits<{
  (event: 'blur'): void;
  (event: 'add', item: AgreementInfo): void;
  (event: 'remove', item: AgreementInfo): void;
  (event: 'update:agreements', item: AgreementInfo[]): void;
}>();

const selectedSearchItem = shallowRef<AgreementInfo | null>(null);

const excludeAgreementIds = computed(() => props.agreements.map(({ id }) => id));

function onItemSelected(item: AgreementInfo): void {
  addSelectedItem(item);
  clearSearch();
  emit('add', item);
}

function onChange(item: AgreementInfo): void {
  addSelectedItem(item);
  clearSearch();
}

function onChipClick(i: AgreementInfo): void {
  removeSelectedItem(i);
}

function addSelectedItem(item: AgreementInfo): void {
  if (!props.agreements.some((ag) => ag.id == item.id)) {
    emit('update:agreements', props.agreements.concat(item));
  }
}

function removeSelectedItem(item: AgreementInfo): void {
  emit(
    'update:agreements',
    props.agreements.filter((ag) => ag.id !== item.id)
  );
  emit('remove', item);
}

function clearSearch(): void {
  nextTick(() => (selectedSearchItem.value = null));
}
</script>

<template>
  <div>
    <AgreementSearch
      v-model="selectedSearchItem"
      :autofocus="autofocus"
      :clearable="clearable"
      :dense="dense"
      :disabled="disabled"
      :error-messages="errorMessages"
      :exclude-agreement-ids="excludeAgreementIds"
      :label="label"
      :placeholder="placeholder"
      :prepend-item="prependItem"
      :security="security"
      :side="side"
      :statuses="statuses"
      @blur="emit('blur')"
      @change="onChange"
      @input="onItemSelected"
    />
    <div class="chips">
      <VChip
        v-for="item in agreements ?? []"
        :key="item.id"
        class="mr-1 mt-1"
        close
        :disabled="disabled"
        small
        @click="onChipClick(item)"
        @click:close="onChipClick(item)"
      >
        {{ item.shortName }}
      </VChip>
    </div>
  </div>
</template>

<style lang="scss" scoped>
// animating space, occupied by the chips
.chips {
  interpolate-size: allow-keywords;
  transition:
    height ease-in-out 300ms,
    margin ease-in-out 300ms;
  overflow: clip;
  margin-top: -4px;

  &:empty {
    height: 0;
    margin-top: 0;
  }
}
</style>

<template>
  <v-dialog
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    max-width="600"
    overlay-color="secondary"
    overlay-opacity="0.25"
    transition="scale-transition"
    :value="true"
    @click:outside="closeDialog()"
    @keydown.esc="closeDialog()"
    @shortkey.native="closeDialog()"
  >
    <v-card class="d-flex flex-column">
      <v-card-title>
        <span class="headline">Manage IP Range Allow List</span>
        <v-btn class="close-icon" icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col class="pa-0" cols="12">
              <ip-allow-list-manager :company-id="companyId"></ip-allow-list-manager>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import IpAllowListManager from '@/modules/common/components/IpAllowListManager.vue';

@Component({
  components: { IpAllowListManager },
  props: {
    companyId: {
      type: String,
      required: true,
    },
  },
})
export default class BrokerIpAllowListManager extends Vue {
  // props
  protected companyId!: string;

  protected closeDialog(): void {
    this.$emit('update:company-id', null);
  }
}
</script>

<style lang="scss" scoped>
.close-icon {
  top: 0.4rem;
  right: 0.4rem;
  position: absolute;
}
</style>

import { defineStore } from 'pinia';
import { shallowRef } from 'vue';
import { PbAgreementInfo, PlainMessage } from '@/connect';

export const useStoreAgreements = defineStore('agreements', () => {
  // Once we migrate to Vue3 we should use shallowReactive
  const agreements = shallowRef(new Map<string, PlainMessage<PbAgreementInfo>>());

  function addAgreements(items: Record<string, PlainMessage<PbAgreementInfo>>) {
    agreements.value = new Map([...agreements.value, ...Object.entries(items)]);
  }

  function getAgreement(id: string) {
    return agreements.value.get(id);
  }

  return {
    addAgreements,
    getAgreement,
  };
});

export type StoreAgreements = ReturnType<typeof useStoreAgreements>;

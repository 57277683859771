import { defineStore } from 'pinia';
import { shallowRef } from 'vue';
import { GridApi, GridReadyEvent, ModelUpdatedEvent } from 'ag-grid-enterprise';

export const useStoreGridApiExplorer = defineStore('gridApiExplorer', () => {
  const gridApi = shallowRef<GridApi | null>(null);
  const areGroupsExpanded = shallowRef(true);

  function onGridReady(params: GridReadyEvent): void {
    gridApi.value = params.api;
    resizeColumns();

    params.api.addEventListener('firstDataRendered', () => {
      if (areGroupsExpanded.value) {
        params.api.expandAll();
      }
    });
    params.api.addEventListener('filterChanged', expandAfterModelUpdated);

    window.addEventListener('resize', resizeColumns);
  }

  function getGridApi(): GridApi {
    if (gridApi.value === null) throw new Error('Grid API not set yet');
    return gridApi.value;
  }

  function resizeColumns(): void {
    getGridApi().sizeColumnsToFit();
  }

  function expandGroups() {
    getGridApi().expandAll();
    areGroupsExpanded.value = true;
  }

  function collapseGroups() {
    getGridApi().collapseAll();
    areGroupsExpanded.value = false;
  }

  function expandAfterModelUpdated() {
    function expandIfNeeded(ev: ModelUpdatedEvent) {
      // there are cases modelUpdated is called multiple times in succession,
      // we can only expand after the last one (keepRenderedRows: true)
      if (areGroupsExpanded.value && ev.keepRenderedRows) {
        getGridApi().expandAll();
        resizeColumns();
        // remove listener to avoid running when socket events are received
        getGridApi().removeEventListener('modelUpdated', expandIfNeeded);
      }
    }
    getGridApi().addEventListener('modelUpdated', expandIfNeeded);
  }

  return {
    onGridReady,
    getGridApi,
    resizeColumns,
    expandGroups,
    collapseGroups,
    expandAfterModelUpdated,
  };
});

export type StoreGridApiExplorer = ReturnType<typeof useStoreGridApiExplorer>;

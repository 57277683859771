import { PlainMessage } from '@/connect';
import { defineStore } from 'pinia';
import { shallowRef } from 'vue';
import { PbInstrument } from '@/connect/gen/common/protos/types_pb';

export const useStoreSecurities = defineStore('securities', () => {
  // Once we migrate to Vue3 we should use shallowReactive
  const securities = shallowRef(new Map<string, PlainMessage<PbInstrument>>());

  function addSecurities(items: Record<string, PlainMessage<PbInstrument>>) {
    securities.value = new Map([...securities.value, ...Object.entries(items)]);
  }

  function getSecurity(cusip: string) {
    return securities.value.get(cusip);
  }

  return {
    addSecurities,
    getSecurity,
  };
});

export type StoreSecurities = ReturnType<typeof useStoreSecurities>;

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { Security } from '@/modules/common/models';
import { useManualLoansApiService } from '@/modules/manual-loan/services/manual-loans-api.service';

interface InputToken {
  text: string;
  found: boolean;
  security?: Security;
}

withDefaults(
  defineProps<{
    value: Security[];
    label?: string;
    disabled?: boolean;
    placeholder?: string;
  }>(),
  {
    label: 'Securities',
    disabled: false,
    placeholder: '',
  }
);

const emit = defineEmits<{
  (event: 'input', value: Security[]): void;
}>();

const searchInput = ref('');
const inputTokens = ref<InputToken[]>([]);

let abortController: AbortController | null = null;

const currentToken = computed(() => {
  const tokens = searchInput.value.trim().split(/\s+/);
  return tokens.length > 0 ? tokens[tokens.length - 1] : '';
});

watch(
  inputTokens,
  () => {
    emit(
      'input',
      inputTokens.value.filter((t) => t.found).map((t) => t.security as Security)
    );
  },
  { deep: true }
);
async function onSpace(ev: KeyboardEvent) {
  ev.preventDefault();
  await searchToken(currentToken.value);
}

async function onTab() {
  // don't call preventDefault() to keep default tab behavior
  await searchToken(currentToken.value);
}

async function searchToken(tokenText: string) {
  if (!tokenText || inputTokens.value.find((t) => t.text === tokenText)) return;

  cancelOngoingRequest();
  abortController = new AbortController();

  try {
    const { data } = await useManualLoansApiService().searchSecurities(
      tokenText,
      abortController.signal
    );

    const exactMatches = data.filter(
      (sec) =>
        sec.ticker.trim().toUpperCase() === tokenText.toUpperCase() ||
        sec.cusip.trim().toUpperCase() === tokenText.toUpperCase()
    );

    if (exactMatches.length > 0) {
      inputTokens.value.push({
        text: tokenText,
        found: true,
        security: exactMatches[0],
      });
    } else {
      inputTokens.value.push({ text: tokenText, found: false });
    }
  } catch (err) {
    inputTokens.value.push({ text: tokenText, found: false });
  }

  searchInput.value = '';
}

function onInput() {
  searchInput.value = searchInput.value.toUpperCase();
}

function cancelOngoingRequest() {
  if (abortController) {
    abortController.abort();
    abortController = null;
  }
}

function removeToken(index: number) {
  inputTokens.value.splice(index, 1);
}

async function onPaste(event: ClipboardEvent) {
  event.preventDefault();
  const clipboardData = event.clipboardData;
  if (!clipboardData) return;

  const pastedText = clipboardData.getData('text/plain');
  if (!pastedText) return;

  // split the pasted text on whitespace and commas
  const tokens = pastedText.split(/[\s,]+/).filter((token) => token.trim().length > 0);

  for (const token of tokens) {
    await searchToken(token.toUpperCase());
  }
}
</script>

<template>
  <div class="d-flex align-center" style="gap: 0.5rem">
    <VTextField
      v-model="searchInput"
      :disabled="disabled"
      :label="label"
      :placeholder="placeholder"
      style="width: 100px"
      @input="onInput"
      @keydown.space="onSpace"
      @keydown.tab="onTab"
      @paste="onPaste"
    />

    <div class="ml-2 d-flex flex-wrap" style="gap: 0.25rem">
      <VChip
        v-for="(token, idx) in inputTokens"
        :key="idx"
        close
        :color="token.found ? 'primary' : 'error'"
        small
        @click:close="removeToken(idx)"
      >
        {{ token.text }}
      </VChip>
    </div>
  </div>
</template>

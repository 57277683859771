<template>
  <div class="d-flex fill-height full-width flex-grow-1">
    <div ref="leftPanelRef" :class="['d-flex flex-column fill-height pa-0 col']">
      <dashboard-panel no-collapse title="executions">
        <marketplace-executions-table :filtered-by-cusip="filteredByCusip" />
      </dashboard-panel>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import MarketplaceExecutionsTable from '@/modules/marketplace/components/MarketplaceExecutionsTable.vue';

@Component({
  provide() {
    return {
      gridStateKey: 'MarketplaceOrders',
    };
  },
  components: {
    MarketplaceExecutionsTable,
    DashboardPanel,
  },
  props: {
    filteredByCusip: {
      type: String,
      default: '',
    },
  },
})
export default class MarketplaceExecutions extends Vue {
  // props
  protected readonly filteredByCusip!: string;
}
</script>

<style lang="scss"></style>

<template>
  <ag-table-client
    :column-defs="columnDefs"
    :get-row-id="getRowId"
    :page="1"
    :page-size="1000"
    :row-data="executions"
    :sort="{ colId: 'execTime', sort: 'desc' }"
    table-id="MarketplaceExecutionsTable"
  />
</template>

<script lang="ts">
import { ClientConfig } from '@/utils/helpers/rest';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ColDef } from 'ag-grid-enterprise';
import { AgTableClient } from '@/modules/common/components/ag-table';
import * as cols from '@/modules/common/components/ag-table/columns/marketplace';
import { ExecutionRecord } from '@/connect/gen/modules/apiengine/services/oms/oms_pb';
import { serviceMarketplace } from '@/connect/services/marketplace';
import { useStoreSecurities } from '@/store/store-securities';
import { useStoreCompanies } from '@/store/store-companies';
import { PlainMessage } from '@/connect';
import { AppState } from '@/store/store';

const POLLING_INTERVAL = 1;

@Component({
  components: {
    AgTableClient,
  },
  props: {
    filteredByCusip: {
      type: String,
      default: '',
    },
    asBroker: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(['clientConfig', 'loginState']),
  },
})
export default class MarketplaceExecutionsTable extends Vue {
  // props
  protected readonly filteredByCusip!: string;
  protected readonly asBroker!: boolean;
  protected readonly companyIdFilter!: string;

  // store states
  protected readonly clientConfig!: ClientConfig;
  protected readonly loginState!: AppState['loginState'];

  protected storeSecurities = useStoreSecurities();
  protected storeCompanies = useStoreCompanies();

  protected executions: Array<PlainMessage<ExecutionRecord>> = [];
  protected pollInterval: ReturnType<typeof setInterval> | null = null;

  protected get columnDefs(): ColDef[] {
    return (this.asBroker ? [cols.company()] : []).concat([
      cols.execTime(),
      cols.side(),
      cols.counterparty(),
      cols.settlementType(),
      cols.ticker(),
      cols.cusip(),
      cols.executedQuantity(),
      cols.rateFromRateDetails(),
      cols.rateType(),
      cols.priceCurrency(),
      cols.unitPrice(),
      cols.settlementAmount(),
      cols.independentAmountRate(),
      cols.roundingRule(),
      cols.collateralType(),
      cols.tradeDate(),
      cols.orderDisplayId(),
      cols.auroraLoanID(),
    ]);
  }

  protected async mounted(): Promise<void> {
    await this.queryData();
    this.pollInterval = setInterval(() => this.queryData(), POLLING_INTERVAL * 1000);
  }

  protected getRowId(order: ExecutionRecord): string {
    return `${order.execId}-${order.side}`;
  }

  protected async queryData(): Promise<void> {
    const result = await serviceMarketplace.queryExecutions({
      companyId: this.asBroker ? this.companyIdFilter : this.loginState.user!.companyID,

      // server pagination does not make sense when the client filters and sorts the results
      pageNumber: -1,
      pageLimit: -1,
    });

    if (!result.success) {
      this.$snackbar.show({ message: this.$i18n.t(result.error, result.details) as string });
      return;
    }

    this.storeSecurities.addSecurities(result.data.instruments);
    this.storeCompanies.addCompanies(result.data.companies);

    let executions = result.data.execRecs;
    if (this.filteredByCusip) {
      executions = executions.filter((execution) => execution.cusip === this.filteredByCusip);
    }

    this.executions = executions;
  }

  protected destroyed(): void {
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
    }
  }
}
</script>

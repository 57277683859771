import { ColDef, ColDefField, ValueFormatterParams } from 'ag-grid-enterprise';
import { alignColumn, alignLeft, alignRight, comparator, component } from './utils';
import { formatPrettyNumber } from '@/modules/common/components/pretty-number';
import { PRICE_PRECISION, RATE_PRECISION } from '@/modules/common/constants/precision';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import Decimal from 'decimal.js';
import { formatDate } from '@/utils/helpers/dates';
import { Benchmark, benchmarkFromProtoEnum } from '@/utils/api/loans';
import { h } from 'vue';
import FormatSide from '@/modules/common/components/format-side/FormatSide.vue';
import RateOutput from '@/modules/common/components/format-rate/RateOutput.vue';
import { Security } from '@/modules/common/models';
import { PlainMessage, Timestamp } from '@bufbuild/protobuf';
import { Benchmark as PbBenchmark, PbRateDetails, SettlementType } from '@/connect';

export function prettyPrice(value: Decimal): string {
  return getPriceAsString(value, PRICE_PRECISION);
}

interface ColConfig<T> {
  field: ColDefField<T>;
  headerName: string;
  align?: 'left' | 'right' | 'center';
  sortable?: boolean;
  colId?: string;
}

export function checkbox(): ColDef<unknown> {
  return {
    colId: 'checkbox',
    headerCheckboxSelection: true,
    // it would be great to always set
    // headerCheckboxSelectionCurrentPageOnly: true
    // but it's not supported with "serverSide" row model, so AgTableClient sets it
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    resizable: false,
    pinned: 'left',
    lockVisible: true,
    sortable: true,
    maxWidth: 64,
    suppressHeaderMenuButton: true,
    suppressHeaderFilterButton: true,
    suppressColumnsToolPanel: true,
  };
}

// format number with thousands separator and 2 decimal places aligining right
export function quantity<T>({
  field,
  headerName,
  align = 'right',
  colId = undefined,
}: ColConfig<T>): ColDef<T> {
  return {
    field,
    colId: colId ?? field,
    headerName,
    valueFormatter: (params: ValueFormatterParams<T, bigint>) =>
      formatPrettyNumber(params.value ?? 0n),
    comparator: comparator.bigint,
    // for very specific cases we want to align left (like top/depth of book views that mirror sides)
    ...alignColumn({ align }),
  };
}

export function price<T>({ field, headerName, align = 'right' }: ColConfig<T>): ColDef<T, Decimal> {
  return {
    field,
    colId: field,
    headerName,
    valueFormatter: (params: ValueFormatterParams) => `$${prettyPrice(params.value)}`,
    comparator: comparator.decimal,
    ...alignColumn({ align }),
  };
}

export const timestampAdapter = component<{ date: string; time: string }>(
  (props) => () =>
    h('div', [h('span', props.date), h('span', { class: 'ml-1 font-weight-light' }, props.time)])
);

export function timestamp<T>({ field, headerName, align = 'left' }: ColConfig<T>): ColDef<T> {
  return {
    field,
    colId: field,
    headerName,
    useValueFormatterForExport: true,
    valueFormatter: (params: ValueFormatterParams<T, Timestamp | Date>) => {
      const normalizedValue =
        params.value instanceof Timestamp ? params.value.toDate() : params.value;
      return formatDate(normalizedValue, 'yyyy-MM-dd hh:mm');
    },
    cellRendererSelector: (params) => {
      const normalizedValue =
        params.value instanceof Timestamp ? params.value.toDate() : params.value;
      return timestampAdapter({
        date: formatDate(normalizedValue, 'MMM dd'),
        time: formatDate(normalizedValue, 'hh:mm a'),
      });
    },
    comparator: comparator.date,
    ...alignColumn({ align }),
  };
}

export function date<T>({ field, headerName, align = 'left' }: ColConfig<T>): ColDef<T> {
  return {
    field,
    colId: field,
    headerName,
    valueFormatter: (params: ValueFormatterParams) => formatDate(params.value, 'MM/dd/yy'),
    comparator: comparator.date,
    ...alignColumn({ align }),
  };
}

export function cusip<T extends { security: Security }>(): ColDef<T, string> {
  return {
    field: 'security.cusip' as ColDefField<T>,
    colId: 'security.cusip',
    headerName: 'CUSIP',
    ...alignLeft(),
  };
}

export const rateAdapter = component<{
  rate: null | number | string | Decimal;
  rateModifier: undefined | Benchmark;
  precision?: number;
}>((props) => () => (props.rate === null ? h('span', undefined, '–') : h(RateOutput, { props })));

export function rate<
  T extends { rate: Decimal; rateModifier: undefined | Benchmark } | { rate: string },
>(): ColDef<T> {
  return {
    field: 'rate' as ColDefField<T>,
    colId: 'rate',
    headerName: 'Rate',
    cellRendererSelector: (params) =>
      rateAdapter({
        rate: params.value,
        rateModifier:
          params.data && 'rateModifier' in params.data ? params.data.rateModifier : undefined,
        precision: undefined,
      }),
    comparator: comparator.decimal,
    cellDataType: 'text',
    ...alignRight({ hasPostfix: true }),
  };
}

export const formatSideAdapter = component<{ side: 'BORROWER' | 'LENDER' }>(
  (props) => () => h(FormatSide, { props })
);

export function side<T extends { side: 'BORROWER' | 'LENDER' }>(): ColDef<T> {
  return {
    field: 'side' as ColDefField<T>,
    colId: 'side',
    headerName: 'Side',
    cellRendererSelector: (params) =>
      formatSideAdapter({
        side: params.value,
      }),
    ...alignLeft(),
  };
}

export function ticker<T extends { security: Security }>(): ColDef<T, string> {
  return {
    field: 'security.ticker' as ColDefField<T>,
    colId: 'security.ticker',
    headerName: 'Ticker',
    ...alignLeft(),
  };
}

const settlementOptions: Array<{ value: string; text: string }> = [
  { value: 'NSCC', text: 'NSCC' },
  { value: 'BILATERAL', text: 'Bilateral' },
  { value: 'OCC', text: 'OCC' },
];

export function settlementTypeStringLabel(settlType: string): string {
  const item = settlementOptions.find((i) => i.value === settlType);
  return item ? item.text : '–';
}

export function settlementTypeString<T extends { settlementType: string }>(): ColDef<T> {
  return {
    field: 'settlementType' as ColDefField<T>,
    colId: 'settlementType',
    headerName: 'Sett Type',
    valueFormatter: (params) => settlementTypeStringLabel(params.value),
    ...alignLeft(),
  };
}

export function settlementTypeEnum<T extends { settlementType: SettlementType }>(): ColDef<T> {
  return {
    field: 'settlementType' as ColDefField<T>,
    colId: 'settlementType',
    headerName: 'Sett Type',
    valueFormatter: (params) => settlementTypeStringLabel(SettlementType[params.value]),
    ...alignLeft(),
  };
}

export function formatPbRateDetails(
  details: PlainMessage<PbRateDetails>,
  precision = RATE_PRECISION
): string {
  const r = new Decimal(details.rate);

  if (details.benchmark === PbBenchmark.UNSPECIFIED) {
    return `${r.toFixed(precision)}%`;
  }

  const sign = Math.sign(r.toNumber()) === -1 ? '-' : '+';

  return `${benchmarkFromProtoEnum(details.benchmark)} ${sign} ${r.abs().toFixed(precision)}%`;
}

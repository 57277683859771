<script setup lang="ts">
import { TopOfBookWatchlist } from '@/modules/marketplace/models';
import { useMarketplaceApiService } from '@/modules/marketplace/services/marketplace-api.service';
import { ApiError } from '@/utils/errors';
import { i18nServerMessage } from '@/utils/helpers/rest-response';
import { useSnackbarManager } from '@/modules/snackbar/services/snackbar-manager';

const props = defineProps<{
  watchlist: TopOfBookWatchlist;
}>();

const emit = defineEmits<{
  (event: 'remove'): void;
}>();

async function remove(): Promise<void> {
  try {
    await useMarketplaceApiService().deleteWatchlist(props.watchlist.displayId);
    emit('remove');
    useSnackbarManager().confirm('Watchlist has been successfully removed');
  } catch (err) {
    const errorMessage = new ApiError(i18nServerMessage(err as Error));
    useSnackbarManager().error(`Operation failed: ${errorMessage}`);
  }
}
</script>

<template>
  <ConfirmDialogWrapper
    :options="{
      message: `You are about to permanently delete watchlist ${watchlist.name}.`,
      title: 'Delete Watchlist',
      rejectText: 'Back',
      acceptText: 'Delete',
    }"
  >
    <template #default="{ confirm }">
      <VBtn class="icon-action" data-test-watchlist-manager="remove" icon @click="confirm(remove)">
        <VIcon size="24">mdi-close-circle</VIcon>
      </VBtn>
    </template>
  </ConfirmDialogWrapper>
</template>

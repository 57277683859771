<template>
  <div class="grid">
    <table class="summary">
      <tbody>
        <tr v-if="asBroker && order.company !== null">
          <td>Company</td>
          <td>{{ order.company.companyName }} ({{ order.company.displayBoxId }})</td>
        </tr>
        <tr v-if="!hideRouting">
          <td>Active</td>
          <td>
            {{
              order.routingStatus === 'ROUTED'
                ? 'Active'
                : order.routingStatus === 'PENDING'
                  ? 'Pending'
                  : 'Inactive'
            }}
          </td>
        </tr>
        <tr>
          <td>Security</td>
          <td>{{ order.security.ticker }} [{{ order.security.cusip }}]</td>
        </tr>
        <tr>
          <td>Side</td>
          <td>
            <format-side :side="order.side" />
          </td>
        </tr>
        <tr>
          <td>Limit</td>
          <td>
            <span v-if="order.rate === null">–</span>
            <rate-output v-else :rate="order.rate" />
          </td>
        </tr>

        <tr>
          <td>Quantity</td>
          <td>
            <pretty-number :value="order.quantity" />
            <span v-if="order.minQuantity > 1">
              &nbsp;(min. <pretty-number :value="order.minQuantity" />)
            </span>
          </td>
        </tr>
        <tr>
          <td>Open Qty.</td>
          <td>
            <pretty-number v-if="order.filled" :value="order.quantity - order.filled" />
            <pretty-number v-else :value="order.quantity" />
          </td>
        </tr>
        <tr v-if="order.orderRef">
          <td>Filled Qty.</td>
          <td>
            <pretty-number v-if="order.filled" :value="order.filled" />
            <span v-else>-</span>
          </td>
        </tr>
        <tr v-if="order.orderRef">
          <td>Avg. Execution Rate</td>
          <td>
            <rate-output v-if="order.avgExecutionRate" :rate="order.avgExecutionRate" />
            <span v-else>–</span>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="summary">
      <tbody>
        <tr v-if="order.createdAt">
          <td>Created Time</td>
          <td>
            {{ formatDate(order.createdAt) }}
          </td>
        </tr>
        <tr>
          <td>Agreements</td>
          <td v-if="order.agreements && order.agreements.length > 0">
            <div v-for="(agreement, index) in order.agreements" :key="agreement.id">
              {{ formatAgreementInfo(agreement)
              }}{{ index === order.agreements.length - 1 ? '' : ',' }}
            </div>
          </td>
          <td v-else>Any</td>
        </tr>
        <tr>
          <td>Time in Force</td>
          <td>
            {{ timeInForceLabel(order.timeInForceType) }}
          </td>
        </tr>
        <tr v-if="order?.timeInForceType === 'GOOD_TILL_TIME' && order.goodTillTime !== null">
          <td>Good Till Time</td>
          <td>
            {{ formatTime(order.goodTillTime) }}
          </td>
        </tr>
        <tr>
          <td>Anonymous Order</td>
          <td>
            {{ order.isAnonymous ? 'Yes' : 'No' }}
          </td>
        </tr>
        <tr>
          <td>Order Type</td>
          <td>
            {{ orderTypeLabel(order.orderType) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import Decimal from 'decimal.js';
import { formatDate } from '@/utils/helpers/dates';
import {
  orderTypeLabel,
  settlementTypeDisplayText,
  timeInForceLabel,
} from '@/modules/marketplace/helpers/marketplace';
import { formatAgreementInfo } from '@/modules/agreements/utils';
import formatPrettyNumber from '@/modules/common/components/pretty-number/formatPrettyNumber';
import { roundingRuleToShortString } from '@/modules/sec-lending/helpers/contract-details';
import { OmsOrder } from '@/modules/marketplace/models';
import { mapState } from 'vuex';
import { ClientConfig } from '@/utils/helpers/rest';

@Component({
  methods: { formatAgreementInfo, roundingRuleToShortString },
  props: {
    order: Object as PropType<OmsOrder>,
    asBroker: {
      type: Boolean,
      default: false,
    },
    hideRouting: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['clientConfig']),
  },
})
export default class MarketplaceOrderSummary extends Vue {
  // store
  protected readonly clientConfig!: ClientConfig;

  // props
  protected readonly order!: OmsOrder;
  protected readonly asBroker!: boolean;
  protected readonly hideRouting!: boolean;

  protected readonly settlementTypeDisplayText = settlementTypeDisplayText;
  protected readonly timeInForceLabel = timeInForceLabel;
  protected readonly orderTypeLabel = orderTypeLabel;

  protected prettyPrice(value: Decimal): string {
    return formatPrettyNumber(value.toNumber());
  }

  protected formatDate(value: Date): string {
    return formatDate(value, 'MMM dd h:mm a');
  }

  protected formatTime(value: Date): string {
    return formatDate(value, 'h:mm a');
  }
}
</script>
<style lang="scss" scoped>
.grid {
  row-gap: 0 !important;

  // only showing the border when the both tables fit the grid
  & > *:nth-child(odd) {
    @container (width >= calc(250px + 1rem + 250px)) {
      border-right: 1px solid rgba(255, 255, 255, 0.12);
    }
  }
}

.summary {
  line-height: 2;

  td {
    vertical-align: top !important;

    &:first-child {
      width: 40%;
      color: #888;
    }

    &:last-child {
      width: 60%;
      font-weight: bold;
      color: white;
    }
  }
}
</style>

import { RATE_PRECISION } from '@/modules/common/constants/precision';
import { DeepPartial, Raw } from '@/modules/common/helpers/api';
import { AuctionStatus } from '@/utils/helpers/buffers';
import { AuctionDialogBuffer, Direction } from '@/utils/helpers/rest';
import { parseISO } from 'date-fns';
import Decimal from 'decimal.js';

export type RawAuctionResponse = Raw<
  AuctionResponse,
  {
    // always specify existing raw entry types explititly
    auction: RawBespokeAuction;
  }
>;

export class AuctionResponse {
  public status: string;
  public auction: BespokeAuction;

  protected constructor(data: RawAuctionResponse) {
    this.status = data.status;
    this.auction = BespokeAuction.fromData(data.auction);
  }

  public static fromData(data: RawAuctionResponse): AuctionResponse {
    return new AuctionResponse(data);
  }
}

export type RawBespokeAuction = Raw<
  BespokeAuction,
  {
    // always specify existing raw entry types explititly
    equity: RawAuctionSecurity;
    companyOrderTickets: RawOrderTicket[];

    auctionID?: string;
    originalDirection?: string | null;
  },
  'security' | 'companyTotalVolume' | 'securityId' | 'securityName'
>;

export class BespokeAuction {
  public id: string;
  public isOwnCompany: boolean;
  public security: AuctionSecurity;
  public participants: string[];
  public participantLabels: string[];
  public leakedDirection: string | null;
  public isLeakedDirection: boolean;
  public leakedQuantity: number | null;
  public isLeakedQuantity: boolean;
  public leakedRate: Decimal | null;
  public isLeakedRate: boolean;
  public leakedIsStackedOrder: boolean | null;
  public isLeakedIsStackedOrder: boolean;
  public endsAt: Date;
  public executedAt: Date | null;
  public crossingRate: Decimal | null;
  public companyOrderTickets: OrderTicket[];
  public status: AuctionStatus;

  // client bloat
  public auctionID: string;
  public originalDirection?: string | null;
  public companyTotalVolume: number | null;

  protected constructor(data: RawBespokeAuction) {
    this.id = data.id;
    this.isOwnCompany = data.isOwnCompany;
    this.security = AuctionSecurity.fromData(data.equity);
    this.participants = data.participants;
    this.participantLabels = data.participantLabels;
    this.leakedDirection = data.leakedDirection;
    this.isLeakedDirection = data.isLeakedDirection;
    this.leakedQuantity = data.leakedQuantity;
    this.isLeakedQuantity = data.isLeakedQuantity;
    this.leakedRate = data.leakedRate === null ? null : new Decimal(data.leakedRate);
    this.isLeakedRate = data.isLeakedRate;
    this.leakedIsStackedOrder = data.leakedIsStackedOrder;
    this.isLeakedIsStackedOrder = data.isLeakedIsStackedOrder;
    this.endsAt = parseISO(data.endsAt);
    this.executedAt = data.executedAt === null ? null : parseISO(data.executedAt);
    this.crossingRate = data.crossingRate === null ? null : new Decimal(data.crossingRate);
    this.companyOrderTickets = data.companyOrderTickets.map(OrderTicket.fromData);
    this.status = data.status;

    this.auctionID = data.auctionID ?? '';
    this.originalDirection = data.originalDirection ?? null;
    this.companyTotalVolume = this.companyOrderTickets.reduce(
      (companyVolume, order) => companyVolume + order.totalQuantity,
      0
    );
  }

  public get securityId(): string {
    return this.security.id;
  }

  public get securityName(): string {
    return this.security.name;
  }

  public static create(from: {
    auction: NonNullableAll<AuctionDialogBuffer>;
    endsAt: Date;
    orderTicket: OrderTicket;
  }): BespokeAuction {
    return BespokeAuction.fromData(BespokeAuction.createData(from));
  }

  public static createData(from: {
    auction: NonNullableAll<AuctionDialogBuffer>;
    endsAt: Date;
    orderTicket: OrderTicket;
  }): RawBespokeAuction {
    return {
      id: '',
      isOwnCompany: true,
      equity: AuctionSecurity.toData(from.auction.security),
      participants: from.auction.participantList,
      participantLabels: [],
      leakedDirection: from.auction.shareableDirection,
      isLeakedDirection: true,
      leakedQuantity: from.auction.shareableQuantity,
      isLeakedQuantity: true,
      leakedRate: from.auction.shareableRate.toString(),
      isLeakedRate: true,
      leakedIsStackedOrder: from.auction.leakIsStackedOrder,
      isLeakedIsStackedOrder: true,
      endsAt: from.endsAt.toString(),
      executedAt: null,
      crossingRate: null,
      companyOrderTickets: [OrderTicket.toData(from.orderTicket)],
      status: AuctionStatus.Open,
    };
  }

  public static fromData(data: RawBespokeAuction): BespokeAuction {
    return new BespokeAuction(data);
  }

  public static toData(model: BespokeAuction): RawBespokeAuction {
    return {
      ...model,
      equity: AuctionSecurity.toData(model.security),
      companyOrderTickets: model.companyOrderTickets.map(OrderTicket.toData),
      leakedRate: model.leakedRate?.toString() ?? null,
      endsAt: model.endsAt.toString(),
      executedAt: model.executedAt?.toString() ?? null,
      crossingRate: model.crossingRate?.toString() ?? null,
    };
  }

  public static mock(data?: DeepPartial<RawBespokeAuction>): BespokeAuction {
    return BespokeAuction.fromData(BespokeAuction.mockData(data));
  }

  public static mockData(data?: DeepPartial<RawBespokeAuction>): RawBespokeAuction {
    const { equity, companyOrderTickets, ...rest } = data ?? {};
    return {
      id: '',
      isOwnCompany: true,
      equity: AuctionSecurity.mockData(equity),
      participants: [],
      participantLabels: [],
      leakedDirection: null,
      isLeakedDirection: false,
      leakedQuantity: null,
      isLeakedQuantity: false,
      leakedRate: null,
      isLeakedRate: false,
      leakedIsStackedOrder: null,
      isLeakedIsStackedOrder: false,
      endsAt: '2024-09-23',
      executedAt: '2024-09-23',
      crossingRate: '100',
      companyOrderTickets: companyOrderTickets?.map(OrderTicket.mockData) ?? [],
      status: AuctionStatus.Open,

      ...rest,
    };
  }
}

export type RawOrderTicket = Raw<
  OrderTicket,
  {
    // always specify existing raw entry types explititly
    orders: RawOrder[];
    auctionId?: string;
  },
  'totalQuantity' | 'avgRate' | 'renderedAt'
>;

export class OrderTicket {
  public id: string;
  public clientOrderID: string;
  public checkedOffAt: Date | null;
  public filledVolume: number | null;
  public direction: Direction;
  public notes: string;
  public orders: Order[];
  public totalQuantity: number;
  public avgRate: Decimal;

  // client bloat
  public renderedAt: Date;
  public auctionId: string;

  protected constructor(data: RawOrderTicket) {
    this.id = data.id;
    this.clientOrderID = data.clientOrderID;
    this.checkedOffAt = data.checkedOffAt === null ? null : parseISO(data.checkedOffAt);
    this.filledVolume = data.filledVolume;
    this.direction = data.direction;
    this.notes = data.notes;
    this.orders = data.orders.map((order, rowID) => Order.fromData({ ...order, rowID }));

    this.totalQuantity = this.orders.reduce((sum, order) => sum + order.quantity, 0);
    const totalValue = this.orders.reduce((sum, order) => {
      return sum.add(order.rate.times(order.quantity));
    }, new Decimal(0));
    this.avgRate = new Decimal(totalValue.dividedBy(this.totalQuantity).toFixed(RATE_PRECISION));

    // update 'renderedAt' (used as 'BespokeAuction:key') every time an auction is loaded
    // so that Vue is forced to repaint the order. Without it the order is not
    // repainted when the user edited the order
    this.renderedAt = new Date();
    this.auctionId = data.auctionId ?? '';
  }

  public static create(
    from: {
      auctionId?: string;
      orderId?: string;
      direction?: Direction;
      orders?: Order[];
      notes?: string;
    } = {}
  ): OrderTicket {
    return OrderTicket.fromData(OrderTicket.createData(from));
  }

  public static createData(
    from: {
      auctionId?: string;
      orderId?: string;
      direction?: Direction;
      orders?: Order[];
      notes?: string;
    } = {}
  ): RawOrderTicket {
    return {
      id: from.orderId ?? '',
      clientOrderID: '',
      checkedOffAt: null,
      filledVolume: 0,
      direction: from.direction ?? Direction.Borrow,
      notes: from.notes ?? '',
      orders: from.orders?.map(Order.toData) ?? [
        Order.createData({ volume: 0, rate: new Decimal(0) }),
      ],
      auctionId: from.auctionId ?? '',
    };
  }

  public static clone(model: OrderTicket): OrderTicket {
    return OrderTicket.fromData(OrderTicket.toData(model));
  }

  public static fromData(data: RawOrderTicket): OrderTicket {
    return new OrderTicket(data);
  }

  public static toData(model: OrderTicket): RawOrderTicket {
    return {
      ...model,
      checkedOffAt: model.checkedOffAt?.toString() ?? null,
      orders: model.orders.map(Order.toData),
    };
  }

  public static mockData(data?: DeepPartial<RawOrderTicket>): RawOrderTicket {
    const { orders, ...rest } = data ?? {};
    return {
      id: '87654321-dcba-4321-dcba-210987654321',
      clientOrderID: '',
      checkedOffAt: null,
      filledVolume: null,
      direction: Direction.Borrow,
      orders: orders?.map(Order.mockData) ?? [],
      notes: '',

      ...rest,
    };
  }
}

export type RawOrder = Raw<
  Order,
  {
    // always specify existing raw entry types explititly
    rowID?: number;
    quantityError?: string;
    rateError?: string;
  }
>;

export class Order {
  public quantity: number;
  public rate: Decimal;
  public filledVolume: number | null;

  // client bloat (html-table helpers)
  public rowID: number;
  public quantityError: string;
  public rateError: string;

  protected constructor(data: RawOrder) {
    this.quantity = data.quantity;
    this.rate = new Decimal(data.rate);
    this.filledVolume = data.filledVolume;

    this.rowID = data.rowID ?? 0;
    this.quantityError = data.quantityError ?? '';
    this.rateError = data.rateError ?? '';
  }

  public static create(from: { volume: number; rate: Decimal }): Order {
    return Order.fromData(Order.createData(from));
  }

  public static createData(from: { volume: number; rate: Decimal }): RawOrder {
    return {
      rowID: 0,
      quantity: from.volume,
      quantityError: '',
      rate: from.rate.toString(),
      rateError: '',
      filledVolume: null,
    };
  }

  public static clone(model: Order): Order {
    return Order.fromData(Order.toData(model));
  }

  public static fromData(data: RawOrder): Order {
    return new Order(data);
  }

  public static toData(model: Order): RawOrder {
    return {
      ...model,
      rate: model.rate.toString(),
    };
  }

  public static mockData(data: DeepPartial<RawOrder>): RawOrder {
    return {
      quantity: 1000,
      rate: '25.99',
      filledVolume: null,

      ...data,
    };
  }
}

export type RawAuctionSecurity = Raw<AuctionSecurity>;

export class AuctionSecurity {
  public id: string;
  public cusip: string;
  public ticker: string;
  public isAuroraActive: boolean;
  public isAuroraRestricted: boolean;
  public hasActivePriceDataSource: boolean;
  public isCusipActive: boolean;
  public nsccNewEligible: boolean;
  public nsccRollEligible: boolean;
  public marketplaceEligible: boolean;
  public tradeManagementEligible: boolean;
  public occEligible: boolean;
  public name: string;
  public type: string;
  public currencyCode: string;
  public isinCode: string | null;
  public cik: string | null;
  public compositeFigi: string | null;
  public closePrice: Decimal;

  protected constructor(data: RawAuctionSecurity) {
    this.id = data.id;
    this.cusip = data.cusip;
    this.ticker = data.ticker;
    this.isAuroraActive = data.isAuroraActive;
    this.isAuroraRestricted = data.isAuroraRestricted;
    this.hasActivePriceDataSource = data.hasActivePriceDataSource;
    this.isCusipActive = data.isCusipActive;
    this.nsccNewEligible = data.nsccNewEligible;
    this.nsccRollEligible = data.nsccRollEligible;
    this.name = data.name;
    this.type = data.type;
    this.currencyCode = data.currencyCode;
    this.isinCode = data.isinCode;
    this.cik = data.cik;
    this.compositeFigi = data.compositeFigi;
    this.closePrice = new Decimal(data.closePrice);
    this.marketplaceEligible = data.marketplaceEligible;
    this.occEligible = data.occEligible;
    this.tradeManagementEligible = data.tradeManagementEligible;
  }

  public static fromData(data: RawAuctionSecurity): AuctionSecurity;
  public static fromData(data: RawAuctionSecurity | null): AuctionSecurity | null;
  public static fromData(data: RawAuctionSecurity | undefined): AuctionSecurity | undefined;
  public static fromData(data: RawAuctionSecurity): null | undefined | AuctionSecurity {
    if (data === null) return null;
    if (data === undefined) return undefined;

    return new AuctionSecurity(data);
  }

  public static toData(model: AuctionSecurity): RawAuctionSecurity {
    return {
      ...model,
      closePrice: model.closePrice.toString(),
    };
  }

  public static mock(data?: DeepPartial<RawAuctionSecurity>): AuctionSecurity {
    return AuctionSecurity.fromData(AuctionSecurity.mockData(data));
  }

  public static mockData(data?: DeepPartial<RawAuctionSecurity>): RawAuctionSecurity {
    return {
      id: '',
      cusip: '123456789',
      ticker: 'IBM',
      isAuroraActive: true,
      isAuroraRestricted: true,
      hasActivePriceDataSource: true,
      isCusipActive: true,
      nsccNewEligible: true,
      nsccRollEligible: true,
      occEligible: true,
      marketplaceEligible: true,
      tradeManagementEligible: true,
      name: 'IBM',
      type: '',
      currencyCode: '',
      isinCode: null,
      cik: null,
      compositeFigi: null,
      closePrice: '100',

      ...data,
    };
  }
}

export type RawOrderTickerRequest = Raw<
  OrderTickerRequest,
  {
    // always specify existing raw entry types explititly
    orders: RawOrder[];
    auctionId?: string;
  },
  'totalQuantity' | 'avgRate' | 'renderedAt'
>;

export class OrderTickerRequest extends OrderTicket implements Leakage {
  public leakedDirection: string | null;
  public leakedQuantity: number | null;
  public leakedRate: Decimal | null;
  public leakedIsStackedOrder: boolean | null;

  protected constructor(data: RawOrderTickerRequest) {
    super(data);

    this.leakedDirection = data.leakedDirection;
    this.leakedQuantity = data.leakedQuantity;
    this.leakedRate = data.leakedRate === null ? null : new Decimal(data.leakedRate);
    this.leakedIsStackedOrder = data.leakedIsStackedOrder;
  }

  public static fromData(data: RawOrderTickerRequest): OrderTickerRequest {
    return new OrderTickerRequest(data);
  }

  public static toData(model: OrderTickerRequest): RawOrderTickerRequest {
    return {
      ...OrderTicket.toData(model),
      ...Leakage.toData(model),
    };
  }

  public static merge(what: { orderTicket: OrderTicket; leakage?: Leakage }): OrderTickerRequest {
    return OrderTickerRequest.fromData({
      ...OrderTicket.toData(what.orderTicket),
      ...(what.leakage
        ? Leakage.toData(what.leakage)
        : {
            leakedDirection: null,
            leakedQuantity: null,
            leakedRate: null,
            leakedIsStackedOrder: null,
          }),
    });
  }
}

export type RawLeakage = Raw<Leakage>;

export class Leakage {
  public leakedDirection: string | null;
  public leakedQuantity: number | null;
  public leakedRate: Decimal | null;
  public leakedIsStackedOrder: boolean | null;

  protected constructor(data: RawLeakage) {
    this.leakedDirection = data.leakedDirection;
    this.leakedQuantity = data.leakedQuantity;
    this.leakedRate = data.leakedRate === null ? null : new Decimal(data.leakedRate);
    this.leakedIsStackedOrder = data.leakedIsStackedOrder;
  }

  public static none(): Leakage {
    return Leakage.fromData({
      leakedDirection: null,
      leakedQuantity: null,
      leakedRate: null,
      leakedIsStackedOrder: null,
    });
  }

  public static fromData(data: RawLeakage): Leakage {
    return new Leakage(data);
  }

  public static toData(model: Leakage): RawLeakage {
    return {
      leakedDirection: model.leakedDirection,
      leakedQuantity: model.leakedQuantity,
      leakedRate: model.leakedRate?.toString() ?? null,
      leakedIsStackedOrder: model.leakedIsStackedOrder,
    };
  }
}

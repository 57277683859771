<template>
  <v-dialog
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    max-width="850"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistents
    :value="true"
    @click:outside="closeDialog()"
    @keydown.esc="closeDialog()"
    @shortkey.native="closeDialog()"
  >
    <uploader
      v-if="step === 'upload'"
      :execute="executeUpload"
      :with-dummy-header="false"
      @close-modal="closeDialog()"
      @upload="goToConfirm($event)"
      @upload-method="uploadMethod = $event"
      @upload-with-errors="goToConfirmWithErrors($event)"
    >
      <template #title>
        Upload
        <format-side :side="Side[side]">
          {{ side === Side.LENDER ? 'Supply' : 'Demand' }}
        </format-side>
        orders
      </template>
      <template #description>
        <div>Expected columns (* is optional):</div>
        <div>
          {{
            [
              'Ticker or CUSIP',
              'Quantity',
              'Rate',
              'Order Type*',
              'Active*',
              'Time in force*',
              'Agreement*',
              clientConfig.orderbookMinQuantityEditable ? 'Min. Quantity*' : null,
            ]
              .filter((f) => !!f)
              .join(' | ')
          }}
        </div>
      </template>
    </uploader>

    <upload-confirm-basket
      v-if="step === 'confirm'"
      :basket-response="basketResponse"
      :error-headers="[
        { text: 'Row', value: 'row' },
        { text: 'Field', value: 'field' },
        { text: 'Error', value: 'errMsg' },
      ]"
      :execute="(file) => $api.marketplace.createOrders(file)"
      :headers="
        [
          { text: 'Security', value: 'security' },
          { text: 'Quantity', value: 'quantity' },
          { text: 'Rate', value: 'rate', align: 'end' },
          { text: 'Order Type', value: 'orderType' },
          { text: 'Active', value: 'routingStatus' },
          { text: 'Time In Force', value: 'timeInForceType' },
          { text: 'Agreement', value: 'agreement' },
          { text: 'Min Qty.', value: 'minQuantity' },
          anyEntriesExceedSoftLimit ? {} : null, // if any entries exceed soft-limit we show warning icons
        ].filter((h) => !!h)
      "
      timeframe="createLoans"
      :upload-method="uploadMethod"
      @back="step = 'upload'"
      @close-modal="closeDialog()"
    >
      <template #title
        >New
        <format-side :side="basketResponse.side" />
        orders
      </template>
      <template v-if="loginState.user" #summary>
        You are about to create <strong>{{ basketResponse.entries.length }}</strong> orders
      </template>
      <template #item="{ item }">
        <tr>
          <td>{{ item.security.ticker }} [{{ item.security.cusip }}]</td>
          <td>
            <pretty-number :value="item.quantity" />
          </td>
          <td class="text-end">
            <rate-output :rate="item.rate" />
          </td>
          <td>{{ orderTypeLabel(item.type) }}</td>
          <td>{{ item.routingStatus === 'ROUTED' ? 'Yes' : 'No' }}</td>
          <td>
            {{ timeInForceAbbr(item.timeInForceType) }}
          </td>
          <td>
            {{
              item.agreements && item.agreements.length > 0
                ? item.agreements.map(formatAgreementName).join(', ')
                : 'All'
            }}
          </td>
          <td>
            <pretty-number :value="item.minQuantity" />
          </td>
          <td v-if="anyEntriesExceedSoftLimit">
            <v-tooltip color="form-tooltip" max-width="600px" right>
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-icon v-if="entryExceedsSoftLimit(item)" color="yellow darken-2">
                    mdi-alert-circle
                  </v-icon>
                </span>
              </template>
              <span>
                The notional value of your order exceeds your soft limit of ${{
                  riskLimitValidator.softLimitValue
                }}
              </span>
            </v-tooltip>
          </td>
        </tr>
      </template>
      <template #create-button-text>Create {{ basketResponse.entries.length }} orders</template>
      <template #success-message>Orders successfully created</template>
      <template #error-title
        >Failed
        <format-side :side="side" />
        orders
      </template>
      <template #error-item="{ item }">
        <tr>
          <td>{{ item.row }}</td>
          <td>{{ item.field }}</td>
          <td class="text-capitalize">{{ item.errMsg }}</td>
        </tr>
      </template>
    </upload-confirm-basket>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { orderTypeLabel, timeInForceAbbr } from '@/modules/marketplace/helpers/marketplace';
import { ClientConfig } from '@/utils/helpers/rest';
import { LoginState } from '@/store/store';
import { mapState } from 'vuex';
import Uploader from '@/modules/common/components/Uploader.vue';
import UploadParseErrors from '@/modules/common/components/UploadParseErrors.vue';
import UploadConfirmBasket from '@/modules/common/components/UploadConfirmBasket.vue';
import type { UploadMethod } from '@/modules/common/types/upload';
import { RiskLimitValidator } from '@/utils/helpers/risk-limit-validator';
import {
  RoundingRule,
  roundingRuleToShortString,
} from '@/modules/sec-lending/helpers/contract-details';
import { formatAgreementName } from '@/modules/user-accounts/helpers/user-accounts';
import Decimal from 'decimal.js';
import { ParseOmsBasketRequest, ParseOmsBasketResponse } from '@/modules/marketplace/models';
import { ErrorItem } from '@/modules/common/models';
import { OmsBasketItem, OmsCreateBasketRequest } from '@/modules/marketplace/models';
import { Side } from '@/connect/gen/consts/commonconsts_pb';

@Component({
  methods: { orderTypeLabel },
  props: {
    side: {
      type: Number as PropType<Side>,
      required: true,
    },
  },
  computed: {
    ...mapState(['clientConfig', 'loginState']),
  },
  components: {
    Uploader,
    UploadParseErrors,
    UploadConfirmBasket,
  },
})
export default class MarketplaceOrdersUploadDialog extends Vue {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly RoundingRule = RoundingRule;

  // store
  protected readonly clientConfig!: ClientConfig;
  protected readonly loginState!: LoginState;
  protected riskLimitValidator!: RiskLimitValidator;

  // props
  protected readonly side!: Side;

  protected uploadMethod: UploadMethod | null = null;
  protected timeInForceAbbr = timeInForceAbbr;
  protected roundingRuleToShortString = roundingRuleToShortString;
  protected formatAgreementName = formatAgreementName;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected Side = Side;

  protected step: 'upload' | 'confirm' = 'upload';
  protected basketResponse!: ParseOmsBasketResponse;
  protected errors: ErrorItem[] = [];

  protected get anyEntriesExceedSoftLimit(): boolean {
    return this.basketResponse.entries.some(this.entryExceedsSoftLimit);
  }

  protected mounted(): void {
    if (!this.loginState.user) {
      return;
    }
    this.riskLimitValidator = new RiskLimitValidator(this.$dialog, this.loginState.user);
  }

  protected async executeUpload(file: File): Promise<ParseOmsBasketResponse> {
    return await this.$api.marketplace.uploadOrders(
      ParseOmsBasketRequest.fromData({
        side: this.side === Side.LENDER ? 'LENDER' : 'BORROWER',
        file,
      })
    );
  }

  protected async executeConfirm(basketResponse: ParseOmsBasketResponse): Promise<void> {
    await this.$api.marketplace.createOrders(
      OmsCreateBasketRequest.fromParseOmsBasketResponse(basketResponse)
    );
  }

  protected goToConfirm(response: ParseOmsBasketResponse): void {
    this.basketResponse = response;
    this.step = 'confirm';
  }

  protected goToConfirmWithErrors(responseData: ParseOmsBasketResponse): void {
    this.basketResponse = responseData;
    this.errors = responseData.errors ?? [];
    this.step = 'confirm';
  }

  protected closeDialog(): void {
    this.$emit('close-modal');
  }

  protected entryExceedsSoftLimit(entry: OmsBasketItem): boolean {
    return this.riskLimitValidator.isGreaterThanOrderSoftLimit(
      entry.security.lastClosePrice.mul(new Decimal(entry.quantity))
    );
  }
}
</script>

// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file modules/apiengine/services/agreements/agreements.proto (package aurora.core.apiengine.agreementsv1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { SettlementType } from "../../../../consts/settlementconsts_pb.js";
import { RoundingRule } from "../../../../consts/loanconsts_pb.js";
import { Side } from "../../../../consts/commonconsts_pb.js";
import { AgreementStatus } from "../../../../consts/agreementconsts_pb.js";
import { PbCompanyInfo, PbUserInfo } from "../../../../common/protos/types_pb.js";

/**
 * Create-Agreement Request
 *
 * @generated from message aurora.core.apiengine.agreementsv1.CreateAgreementRequest
 */
export class CreateAgreementRequest extends Message<CreateAgreementRequest> {
  /**
   * @generated from field: string short_name = 1;
   */
  shortName = "";

  /**
   * @generated from field: string counterparty_id = 2;
   */
  counterpartyId = "";

  /**
   * @generated from field: aurora.core.consts.settlement.SettlementType settlement_type = 3;
   */
  settlementType = SettlementType.UNSPECIFIED;

  /**
   * @generated from field: aurora.core.consts.RoundingRule rounding_rule = 4;
   */
  roundingRule = RoundingRule.UNKNOWN;

  /**
   * @generated from field: string independent_amount_rate = 5;
   */
  independentAmountRate = "";

  /**
   * @generated from field: aurora.core.consts.common.Side side = 6;
   */
  side = Side.BORROWER;

  constructor(data?: PartialMessage<CreateAgreementRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.agreementsv1.CreateAgreementRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "short_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "counterparty_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "settlement_type", kind: "enum", T: proto3.getEnumType(SettlementType) },
    { no: 4, name: "rounding_rule", kind: "enum", T: proto3.getEnumType(RoundingRule) },
    { no: 5, name: "independent_amount_rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "side", kind: "enum", T: proto3.getEnumType(Side) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAgreementRequest {
    return new CreateAgreementRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAgreementRequest {
    return new CreateAgreementRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAgreementRequest {
    return new CreateAgreementRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAgreementRequest | PlainMessage<CreateAgreementRequest> | undefined, b: CreateAgreementRequest | PlainMessage<CreateAgreementRequest> | undefined): boolean {
    return proto3.util.equals(CreateAgreementRequest, a, b);
  }
}

/**
 * Create-Agreement Response
 *
 * @generated from message aurora.core.apiengine.agreementsv1.CreateAgreementResponse
 */
export class CreateAgreementResponse extends Message<CreateAgreementResponse> {
  /**
   * @generated from field: aurora.core.apiengine.agreementsv1.Agreement agreement = 1;
   */
  agreement?: Agreement;

  constructor(data?: PartialMessage<CreateAgreementResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.agreementsv1.CreateAgreementResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agreement", kind: "message", T: Agreement },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAgreementResponse {
    return new CreateAgreementResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAgreementResponse {
    return new CreateAgreementResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAgreementResponse {
    return new CreateAgreementResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAgreementResponse | PlainMessage<CreateAgreementResponse> | undefined, b: CreateAgreementResponse | PlainMessage<CreateAgreementResponse> | undefined): boolean {
    return proto3.util.equals(CreateAgreementResponse, a, b);
  }
}

/**
 * Approve-Agreement Request
 *
 * @generated from message aurora.core.apiengine.agreementsv1.ApproveAgreementRequest
 */
export class ApproveAgreementRequest extends Message<ApproveAgreementRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string short_name = 2;
   */
  shortName = "";

  constructor(data?: PartialMessage<ApproveAgreementRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.agreementsv1.ApproveAgreementRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "short_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ApproveAgreementRequest {
    return new ApproveAgreementRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ApproveAgreementRequest {
    return new ApproveAgreementRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ApproveAgreementRequest {
    return new ApproveAgreementRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ApproveAgreementRequest | PlainMessage<ApproveAgreementRequest> | undefined, b: ApproveAgreementRequest | PlainMessage<ApproveAgreementRequest> | undefined): boolean {
    return proto3.util.equals(ApproveAgreementRequest, a, b);
  }
}

/**
 * Approve-Agreement Response
 *
 * @generated from message aurora.core.apiengine.agreementsv1.ApproveAgreementResponse
 */
export class ApproveAgreementResponse extends Message<ApproveAgreementResponse> {
  constructor(data?: PartialMessage<ApproveAgreementResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.agreementsv1.ApproveAgreementResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ApproveAgreementResponse {
    return new ApproveAgreementResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ApproveAgreementResponse {
    return new ApproveAgreementResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ApproveAgreementResponse {
    return new ApproveAgreementResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ApproveAgreementResponse | PlainMessage<ApproveAgreementResponse> | undefined, b: ApproveAgreementResponse | PlainMessage<ApproveAgreementResponse> | undefined): boolean {
    return proto3.util.equals(ApproveAgreementResponse, a, b);
  }
}

/**
 * Reject-Agreement Request
 *
 * @generated from message aurora.core.apiengine.agreementsv1.RejectAgreementRequest
 */
export class RejectAgreementRequest extends Message<RejectAgreementRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<RejectAgreementRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.agreementsv1.RejectAgreementRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RejectAgreementRequest {
    return new RejectAgreementRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RejectAgreementRequest {
    return new RejectAgreementRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RejectAgreementRequest {
    return new RejectAgreementRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RejectAgreementRequest | PlainMessage<RejectAgreementRequest> | undefined, b: RejectAgreementRequest | PlainMessage<RejectAgreementRequest> | undefined): boolean {
    return proto3.util.equals(RejectAgreementRequest, a, b);
  }
}

/**
 * Reject-Agreement Response
 *
 * @generated from message aurora.core.apiengine.agreementsv1.RejectAgreementResponse
 */
export class RejectAgreementResponse extends Message<RejectAgreementResponse> {
  constructor(data?: PartialMessage<RejectAgreementResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.agreementsv1.RejectAgreementResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RejectAgreementResponse {
    return new RejectAgreementResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RejectAgreementResponse {
    return new RejectAgreementResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RejectAgreementResponse {
    return new RejectAgreementResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RejectAgreementResponse | PlainMessage<RejectAgreementResponse> | undefined, b: RejectAgreementResponse | PlainMessage<RejectAgreementResponse> | undefined): boolean {
    return proto3.util.equals(RejectAgreementResponse, a, b);
  }
}

/**
 * Modify-Agreement Request
 *
 * @generated from message aurora.core.apiengine.agreementsv1.ModifyAgreementRequest
 */
export class ModifyAgreementRequest extends Message<ModifyAgreementRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string short_name = 3;
   */
  shortName = "";

  /**
   * @generated from field: aurora.core.consts.settlement.SettlementType settlement_type = 5;
   */
  settlementType = SettlementType.UNSPECIFIED;

  /**
   * @generated from field: aurora.core.consts.RoundingRule rounding_rule = 6;
   */
  roundingRule = RoundingRule.UNKNOWN;

  /**
   * @generated from field: string independent_amount_rate = 7;
   */
  independentAmountRate = "";

  constructor(data?: PartialMessage<ModifyAgreementRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.agreementsv1.ModifyAgreementRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "short_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "settlement_type", kind: "enum", T: proto3.getEnumType(SettlementType) },
    { no: 6, name: "rounding_rule", kind: "enum", T: proto3.getEnumType(RoundingRule) },
    { no: 7, name: "independent_amount_rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModifyAgreementRequest {
    return new ModifyAgreementRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModifyAgreementRequest {
    return new ModifyAgreementRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModifyAgreementRequest {
    return new ModifyAgreementRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ModifyAgreementRequest | PlainMessage<ModifyAgreementRequest> | undefined, b: ModifyAgreementRequest | PlainMessage<ModifyAgreementRequest> | undefined): boolean {
    return proto3.util.equals(ModifyAgreementRequest, a, b);
  }
}

/**
 * Modify-Agreement Response
 *
 * @generated from message aurora.core.apiengine.agreementsv1.ModifyAgreementResponse
 */
export class ModifyAgreementResponse extends Message<ModifyAgreementResponse> {
  /**
   * @generated from field: aurora.core.apiengine.agreementsv1.Agreement agreement = 1;
   */
  agreement?: Agreement;

  constructor(data?: PartialMessage<ModifyAgreementResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.agreementsv1.ModifyAgreementResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agreement", kind: "message", T: Agreement },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModifyAgreementResponse {
    return new ModifyAgreementResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModifyAgreementResponse {
    return new ModifyAgreementResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModifyAgreementResponse {
    return new ModifyAgreementResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ModifyAgreementResponse | PlainMessage<ModifyAgreementResponse> | undefined, b: ModifyAgreementResponse | PlainMessage<ModifyAgreementResponse> | undefined): boolean {
    return proto3.util.equals(ModifyAgreementResponse, a, b);
  }
}

/**
 * Cancel-Agreement Request
 *
 * @generated from message aurora.core.apiengine.agreementsv1.CancelAgreementRequest
 */
export class CancelAgreementRequest extends Message<CancelAgreementRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<CancelAgreementRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.agreementsv1.CancelAgreementRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelAgreementRequest {
    return new CancelAgreementRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelAgreementRequest {
    return new CancelAgreementRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelAgreementRequest {
    return new CancelAgreementRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CancelAgreementRequest | PlainMessage<CancelAgreementRequest> | undefined, b: CancelAgreementRequest | PlainMessage<CancelAgreementRequest> | undefined): boolean {
    return proto3.util.equals(CancelAgreementRequest, a, b);
  }
}

/**
 * Cancel-Agreement Response
 *
 * @generated from message aurora.core.apiengine.agreementsv1.CancelAgreementResponse
 */
export class CancelAgreementResponse extends Message<CancelAgreementResponse> {
  constructor(data?: PartialMessage<CancelAgreementResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.agreementsv1.CancelAgreementResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelAgreementResponse {
    return new CancelAgreementResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelAgreementResponse {
    return new CancelAgreementResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelAgreementResponse {
    return new CancelAgreementResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CancelAgreementResponse | PlainMessage<CancelAgreementResponse> | undefined, b: CancelAgreementResponse | PlainMessage<CancelAgreementResponse> | undefined): boolean {
    return proto3.util.equals(CancelAgreementResponse, a, b);
  }
}

/**
 * Query-Agreement Request
 *
 * @generated from message aurora.core.apiengine.agreementsv1.QueryAgreementsRequest
 */
export class QueryAgreementsRequest extends Message<QueryAgreementsRequest> {
  /**
   * @generated from field: repeated aurora.core.consts.agreements.AgreementStatus statuses = 1;
   */
  statuses: AgreementStatus[] = [];

  /**
   * @generated from field: optional aurora.core.consts.common.Side side = 2;
   */
  side?: Side;

  /**
   * @generated from field: optional string instrument = 3;
   */
  instrument?: string;

  constructor(data?: PartialMessage<QueryAgreementsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.agreementsv1.QueryAgreementsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "statuses", kind: "enum", T: proto3.getEnumType(AgreementStatus), repeated: true },
    { no: 2, name: "side", kind: "enum", T: proto3.getEnumType(Side), opt: true },
    { no: 3, name: "instrument", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryAgreementsRequest {
    return new QueryAgreementsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryAgreementsRequest {
    return new QueryAgreementsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryAgreementsRequest {
    return new QueryAgreementsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: QueryAgreementsRequest | PlainMessage<QueryAgreementsRequest> | undefined, b: QueryAgreementsRequest | PlainMessage<QueryAgreementsRequest> | undefined): boolean {
    return proto3.util.equals(QueryAgreementsRequest, a, b);
  }
}

/**
 * Query-Agreement Response
 *
 * @generated from message aurora.core.apiengine.agreementsv1.QueryAgreementsResponse
 */
export class QueryAgreementsResponse extends Message<QueryAgreementsResponse> {
  /**
   * @generated from field: repeated aurora.core.apiengine.agreementsv1.Agreement agreements = 1;
   */
  agreements: Agreement[] = [];

  constructor(data?: PartialMessage<QueryAgreementsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.agreementsv1.QueryAgreementsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agreements", kind: "message", T: Agreement, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryAgreementsResponse {
    return new QueryAgreementsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryAgreementsResponse {
    return new QueryAgreementsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryAgreementsResponse {
    return new QueryAgreementsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: QueryAgreementsResponse | PlainMessage<QueryAgreementsResponse> | undefined, b: QueryAgreementsResponse | PlainMessage<QueryAgreementsResponse> | undefined): boolean {
    return proto3.util.equals(QueryAgreementsResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.agreementsv1.Agreement
 */
export class Agreement extends Message<Agreement> {
  /**
   * @generated from field: string id = 8;
   */
  id = "";

  /**
   * @generated from field: string display_id = 7;
   */
  displayId = "";

  /**
   * @generated from field: string short_name = 1;
   */
  shortName = "";

  /**
   * @generated from field: aurora.core.common.protos.PbCompanyInfo lender_company = 2;
   */
  lenderCompany?: PbCompanyInfo;

  /**
   * @generated from field: aurora.core.common.protos.PbCompanyInfo borrower_company = 3;
   */
  borrowerCompany?: PbCompanyInfo;

  /**
   * @generated from field: aurora.core.consts.settlement.SettlementType settlement_type = 4;
   */
  settlementType = SettlementType.UNSPECIFIED;

  /**
   * @generated from field: aurora.core.consts.RoundingRule rounding_rule = 5;
   */
  roundingRule = RoundingRule.UNKNOWN;

  /**
   * @generated from field: string independent_amount_rate = 6;
   */
  independentAmountRate = "";

  /**
   * @generated from field: aurora.core.consts.agreements.AgreementStatus status = 9;
   */
  status = AgreementStatus.UNSPECIFIED;

  /**
   * @generated from field: aurora.core.common.protos.PbUserInfo proposer_user = 10;
   */
  proposerUser?: PbUserInfo;

  /**
   * @generated from field: aurora.core.consts.common.Side proposed_by_side = 15;
   */
  proposedBySide = Side.BORROWER;

  /**
   * @generated from field: optional aurora.core.common.protos.PbUserInfo responder_user = 11;
   */
  responderUser?: PbUserInfo;

  /**
   * @generated from field: int64 version = 12;
   */
  version = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 13;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp approved_at = 14;
   */
  approvedAt?: Timestamp;

  constructor(data?: PartialMessage<Agreement>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.agreementsv1.Agreement";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 8, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "display_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 1, name: "short_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "lender_company", kind: "message", T: PbCompanyInfo },
    { no: 3, name: "borrower_company", kind: "message", T: PbCompanyInfo },
    { no: 4, name: "settlement_type", kind: "enum", T: proto3.getEnumType(SettlementType) },
    { no: 5, name: "rounding_rule", kind: "enum", T: proto3.getEnumType(RoundingRule) },
    { no: 6, name: "independent_amount_rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "status", kind: "enum", T: proto3.getEnumType(AgreementStatus) },
    { no: 10, name: "proposer_user", kind: "message", T: PbUserInfo },
    { no: 15, name: "proposed_by_side", kind: "enum", T: proto3.getEnumType(Side) },
    { no: 11, name: "responder_user", kind: "message", T: PbUserInfo, opt: true },
    { no: 12, name: "version", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "created_at", kind: "message", T: Timestamp },
    { no: 14, name: "approved_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Agreement {
    return new Agreement().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Agreement {
    return new Agreement().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Agreement {
    return new Agreement().fromJsonString(jsonString, options);
  }

  static equals(a: Agreement | PlainMessage<Agreement> | undefined, b: Agreement | PlainMessage<Agreement> | undefined): boolean {
    return proto3.util.equals(Agreement, a, b);
  }
}


<template>
  <div class="d-flex wrapper">
    <template v-if="isMyOrder">
      <v-btn
        class="icon-action"
        data-test="edit-order"
        icon
        x-small
        @click="storeOrderForm.editOrder(order)"
      >
        <v-icon size="24">mdi-pencil</v-icon>
      </v-btn>
      <v-btn class="icon-action" data-test="cancel-order" icon x-small @click="cancelOrder">
        <v-icon size="24">mdi-close-circle</v-icon>
      </v-btn>
    </template>
    <template v-else>
      <v-btn
        v-if="!isIoi"
        color="primary"
        data-test="match-order"
        x-small
        @click="storeOrderForm.matchOrder(order)"
      >
        Match
      </v-btn>
    </template>
  </div>
</template>

<script lang="ts" setup>
import Vue from 'vue';
import { computed } from 'vue';
import { useStoreOrderForm } from '@/modules/marketplace/store/store-order-form';
import { store as globalStore } from '@/store/store';
import { OmsOrderType } from '@/connect/gen/consts/omsconsts_pb';
import { BookOrder } from '@/connect/gen/modules/apiengine/services/venue/venue_pb';
import { ApiError } from '@/utils/errors';
import { i18nServerMessage } from '@/utils/helpers/rest-response';

const props = defineProps<{
  order: BookOrder;
}>();

const storeOrderForm = useStoreOrderForm();

const isMyOrder = computed(() => {
  return props.order.companyId === globalStore.state.loginState.user?.companyID;
});

const isIoi = computed(() => {
  return props.order.orderType === OmsOrderType.IOI;
});

function cancelOrder() {
  const orderRef = props.order.clientOrderRef;
  Vue.prototype.$dialog.ask({
    message: 'You are about to cancel the order.',
    title: 'Order cancellation',
    rejectText: 'Keep order',
    acceptText: 'Cancel order',
    onAccept: async () => {
      try {
        await Vue.prototype.$api.marketplace.cancelOrder(orderRef);
        Vue.prototype.$snackbar.confirm('Order has been successfully canceled');
      } catch (err) {
        const errorMessage = new ApiError(i18nServerMessage(err as Error));
        Vue.prototype.$snackbar.error(`Operation failed: ${errorMessage}`);
      }
    },
  });
}
</script>

<style scoped>
.wrapper {
  scale: 0.8;
  padding-top: 3px;
  gap: 0.5rem;
}
</style>

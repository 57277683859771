<script setup lang="ts">
import { Security } from '@/modules/common/models';
import { ManualLoansApiService } from '@/modules/manual-loan/services/manual-loans-api.service';
import axios from 'axios';
import { computed, nextTick, ref, shallowRef, watch, watchEffect } from 'vue';
import { VAutocomplete } from 'vuetify/lib';

const props = withDefaults(
  defineProps<{
    value?: Security | null;
    label?: string;
    autofocus?: boolean;
    disabled?: boolean;
    clearable?: boolean;
    clearAfterSelect?: boolean;
    dense?: boolean;
    errorMessages?: string | string[];
  }>(),
  { value: null, label: 'security', errorMessages: () => [] }
);

const emit = defineEmits<{
  (event: 'input' | 'change', value: Security | null): void;
  (event: 'blur', value: FocusEvent): void;
}>();

// https://axios-http.com/docs/cancellation
// no need to have it reactive
let abortController: AbortController | null = null;

const cmpVAutocomplete = ref<InstanceType<typeof VAutocomplete>>();
const elInputSearch = computed(
  () => cmpVAutocomplete.value?.$el?.querySelector<HTMLInputElement>('input[type="text"]') ?? null
);

const items = shallowRef<Security[]>([]);
const hasFocus = shallowRef(false);
const isLoading = shallowRef(false);
const securityQuery = shallowRef('');

// local v-model representation
const vModel = shallowRef(props.value);
watchEffect(() => (vModel.value = props.value));
// we don't want this immediate, so no watchEffect here
watch(vModel, (value) => emit('input', value));

const securityFormatted = computed(() => formatItemText(vModel.value));
const isDesiredSecuritySelected = computed(() => securityQuery.value === securityFormatted.value);
const securityExactMatch = computed(() =>
  items.value.find(
    (item) =>
      securityQuery.value === item.ticker.trim().toLocaleUpperCase() ||
      securityQuery.value === item.cusip.trim().toLocaleUpperCase()
  )
);
const securitySingleMatch = computed(
  () => (items.value.length === 1 ? items.value[0] : securityExactMatch.value) ?? null
);

const searchInput = computed({
  get() {
    return securityQuery.value;
  },
  set(value: string | null) {
    // VAutocomplete will try to set null on blur, ignore. We update it to empty string
    // when we actually pick null, via securityFormatted
    if (value === null) return;

    securityQuery.value = value.trim().toUpperCase();
  },
});

// sync input with formatted value whenever a security is selected (or removed)
watchEffect(() => (searchInput.value = securityFormatted.value));

// make dropdown include selected value
watchEffect(() => {
  // no need to update items before security selected
  if (!isDesiredSecuritySelected.value) return;

  if (vModel.value) {
    if (items.value.includes(vModel.value as Security)) {
      // items already include our security, no need to reset items
      // may be important when we matched V when there are VZ and NVDA
      // or GOOG, while there's GOOGL
      return;
    }
    items.value = [vModel.value];
  } else {
    items.value = [];
  }
});

watch(securityQuery, async (query) => {
  // no need to search for what's already selected
  if (!isDesiredSecuritySelected.value) {
    await getSecurities(query);
  }
});

function setValue(security: Security | null) {
  vModel.value = security;
  emit('change', vModel.value);
}

async function onBlur(event: FocusEvent) {
  if (!isLoading.value) {
    // if there's only one item - select it! If no items, it's null
    setValue(securitySingleMatch.value);

    // if single item is not found, and there's multiple items, take user back
    if (vModel.value === null && items.value.length > 1) {
      await openMenu();
      return;
    }
  }
  hasFocus.value = false;
  emit('blur', event);
}

function onEnterKey(event: KeyboardEvent) {
  if (items.value.length === 1) {
    // if we have a single item, pretend user has pressed Tab
    // so we select the item and move focus to the next element
    elInputSearch.value?.dispatchEvent(
      new KeyboardEvent('keydown', {
        key: 'Tab',
        code: 'Tab',
        keyCode: 9,
        which: 9,
        shiftKey: event.shiftKey,
      })
    );
  }
}

function formatItemText(item: Security | null): string {
  return item?.ticker ?? '';
}

function onChange(item: Security | null): void {
  emit('change', item);
  if (props.clearAfterSelect && item !== null) {
    emit('change', null);
  }
}

async function getSecurities(query: string): Promise<void> {
  // we want to initiate a new request, but the previous one is still pending
  // cancel to avoid stale responses arriving late (and potentially in the wrong order)
  abortController?.abort();

  abortController = new AbortController();
  isLoading.value = true;
  items.value = [];

  // we are searching, current security selection is obsolete
  // note that this doesn't trigger change event
  vModel.value = null;

  // current security selection reset also cleans search input
  // so here's a dirty trick to restore it
  nextTick(() => (searchInput.value = query));

  try {
    ({ data: items.value } = await ManualLoansApiService.instance.searchSecurities(
      query,
      abortController.signal
    ));

    isLoading.value = false;

    // if the user moved the focus to another element before the search completed, and
    // one of the returned securities is a single or an exact match of the query
    // then select that element automagically (there is no need to bother the user)
    if (!hasFocus.value && securitySingleMatch.value !== null) {
      setValue(securitySingleMatch.value);
      return;
    }

    // no match; grab the focus and let v-autocomplete do its magic
    if (!hasFocus.value) await openMenu();
  } catch (err) {
    if (axios.isCancel(err)) {
      // exception thrown by abortController.cancel()
      // just return and wait for the new response to arrive
      return;
    }
    items.value = [];
    isLoading.value = false;
  }
}

/**
 * opens menu and restores query text
 */
async function openMenu() {
  if (!elInputSearch.value) return;
  elInputSearch.value.click();
  await nextTick();
  elInputSearch.value.value = searchInput.value ?? '';
}
</script>

<template>
  <VAutocomplete
    ref="cmpVAutocomplete"
    v-model="vModel"
    :autofocus="autofocus"
    :clearable="clearable"
    :dense="dense"
    :disabled="disabled"
    :error-messages="errorMessages"
    hide-no-data
    :item-text="formatItemText"
    item-value="id"
    :items="items"
    :label="label"
    :loading="isLoading"
    no-filter
    return-object
    :search-input.sync="searchInput"
    @blur="onBlur"
    @change="onChange"
    @focus="hasFocus = true"
    @keydown.enter="onEnterKey"
  >
    <!-- hack: display 'query' as label when user blurs away before async search returned -->
    <template #label>{{ isLoading && !hasFocus ? securityQuery : label }}</template>
    <template #item="{ item }">
      <VListItemContent :class="{ 'opacity-50': item.cannotTradeMessage }">
        <VListItemTitle>{{ item.ticker }}</VListItemTitle>
        <VListItemSubtitle>CUSIP: {{ item.cusip }}</VListItemSubtitle>
      </VListItemContent>
    </template>
  </VAutocomplete>
</template>

<style scoped lang="scss">
.opacity-50 {
  opacity: 0.5;
}
</style>

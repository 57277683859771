<template>
  <v-card class="flex d-flex flex-column">
    <v-form class="col-xl-8 pa-0" novalidate @submit.prevent>
      <v-card-title class="mb-4">
        <span class="headline">{{ $t('adminCompanyPreferences.title') }}</span>
      </v-card-title>
      <v-card-text class="text--primary">
        <v-row>
          <v-col class="title">Loan Parameters</v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            Preferred Independent Amount Rate
            <small class="d-block text--secondary label-note">
              Your Preferred Independent Amount Rate is applied to all new loans for which you are
              the lender. For Borrow Orders, this acts as a limit, i.e. matches will not occur for a
              value more aggressive than your setting.
            </small>
          </v-col>
          <v-col cols="4">
            <numeric-input
              v-model="preferredIndependentAmountRate"
              :error-messages="errorMsgs['preferredIndependentAmountRate']"
              :max="maxIa"
              :min="0"
              :precision="iaPrecision"
              :step="1"
              suffix="%"
              type="decimal"
              @blur="$v.preferredIndependentAmountRate.$touch()"
              @input="$v.preferredIndependentAmountRate.$touch()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            Preferred Rounding Rule
            <small class="d-block text--secondary label-note">
              Your Preferred Rounding Rule is applied to all new loans for which you are the lender.
              For Borrow Orders, this acts as a limit, i.e. matches will not occur for a value more
              aggressive than your setting.
            </small>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="preferredRoundingRule"
              :items="roundingRuleOptions"
              @blur="$v.preferredRoundingRule.$touch()"
              @input="$v.preferredRoundingRule.$touch()"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="text--primary">
        <v-row>
          <v-col class="title">Default Risk Limits</v-col>
        </v-row>
        <v-row>
          <v-col cols="7">
            <v-alert class="text-sm-body-2" color="secondary" dense type="info">
              Default Risk Limits are applied when individual risk limits are not configured for a
              trader.
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="subtitle-1"> Per Order Soft Limit</v-col>
        </v-row>
        <v-row>
          <v-col>
            Fat-finger warning will trigger when a trader attempts to enter an order exceeding this
            notional value.
          </v-col>
        </v-row>
        <v-row align="center" dense>
          <v-col cols="4">Default Per Order Soft Limit:</v-col>
          <v-col cols="4">
            <numeric-input
              ref="softLimitInput"
              v-model="defaultOrderSoftLimit"
              :clearable="true"
              :error-messages="errorMsgs.defaultOrderSoftLimit"
              :min="1"
              :precision="0"
              prefix="$"
              type="decimal"
              @blur="$v.defaultOrderSoftLimit.$touch()"
              @input="$v.defaultOrderSoftLimit.$touch()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-6 pb-0">
            <div class="subtitle-1">Per Order Hard Limit</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Hard limit will be enforced when a trader attempts to enter an order exceeding this
            notional value, preventing order entry.
          </v-col>
        </v-row>
        <v-row align="center" dense>
          <v-col cols="4">Default Per Order Hard Limit:</v-col>
          <v-col cols="4">
            <numeric-input
              ref="hardLimitInput"
              v-model="defaultOrderHardLimit"
              :clearable="true"
              :error-messages="errorMsgs.defaultOrderHardLimit"
              :min="1"
              :precision="0"
              prefix="$"
              type="decimal"
              @blur="$v.defaultOrderHardLimit.$touch()"
              @input="$v.defaultOrderHardLimit.$touch()"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="text--primary">
        <v-row>
          <v-col class="title">Benchmark</v-col>
        </v-row>
        <v-row>
          <v-col> Benchmark rate to be used for calculations in company reports. </v-col>
        </v-row>
        <v-row align="center" dense>
          <v-col cols="4">Default Benchmark:</v-col>
          <v-col cols="4">
            <v-select
              v-model="benchmarkType"
              :items="benchmarkOptions"
              @blur="$v.benchmarkType.$touch()"
            />
            <numeric-input
              v-if="benchmarkType == 'Custom'"
              v-model="benchmarkPrice"
              :autofocus="true"
              :error-messages="errorMsgs.benchmarkPrice"
              :min="0"
              :precision="2"
              type="decimal"
              @focus="$v.benchmarkPrice.$reset()"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="text--primary">
        <v-row>
          <v-col class="title">Marketplace Defaults</v-col>
        </v-row>
        <v-row align="center" dense>
          <v-col cols="4">Default Agreements:</v-col>
          <v-col cols="4">
            <multiple-chip-agreement-selector
              :agreements.sync="defaultOmsAgreements"
              label="Agreements"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-if="canConfigCutOffTime" class="text--primary">
        <v-row>
          <v-col class="title">Cutoff time</v-col>
        </v-row>
        <v-row align="center" dense>
          <v-col cols="4">
            <p class="mt-4">
              Return Cutoff Time (EST). Any returns initiated after this time will require the
              lender to approve the return before they get officially recorded to the trade and/or
              settlement instructions sent.
            </p>
          </v-col>
          <v-col cols="4">
            <v-menu
              ref="timePickerMenu"
              v-model="isTimePickerOpen"
              :close-on-content-click="false"
              max-width="290px"
              min-width="290px"
              :nudge-right="40"
              offset-y
              transition="scale-transition"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  clearable
                  placeholder="Select a cutoff time"
                  readonly
                  :value="amPmTime"
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="cutOffTime = null"
                />
              </template>
              <v-time-picker v-if="isTimePickerOpen" v-model="cutOffTime" full-width />
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="pa-3">
        <v-row>
          <v-col>
            <v-btn
              class="px-6"
              color="primary"
              :disabled="isProcessing"
              type="submit"
              @click="submitForm()"
            >
              {{ $t('saveButton') }}
            </v-btn>
          </v-col>
          <v-col>
            <div v-if="showError || showSuccess">
              <div
                class="v-alert v-alert--dense text--primary text-body-2 text-center ma-0"
                :class="{ error: showError, success: showSuccess }"
              >
                <div v-if="showError">
                  {{ errorMsgs.apiErrors.join('\n') }}
                </div>
                <div v-if="showSuccess">Your company details have been updated.</div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';
import axios from 'axios';
import { Route } from 'vue-router';
import Decimal from 'decimal.js';
import { isEqual, isNull } from 'lodash';
import { IA_PRECISION, MAX_IA } from '@/modules/common/constants/precision';
import { ApiError } from '@/utils/errors';
import { i18nServerMessage } from '@/utils/helpers/rest-response';
import wait from '@/modules/common/services/wait';
import { validationMixin } from 'vuelidate';
import { decimal, maxValue, minValue, numeric, requiredIf } from 'vuelidate/lib/validators';
import { notGreaterThanPrecision } from '@/utils/helpers/custom-validators';
import { RoundingRule, roundingRuleToString } from '@/modules/sec-lending/helpers/contract-details';
import { CompanyAccount, normalizeCompanyAccount } from '@/utils/api/broker';
import NumericInput from '@/modules/common/components/NumericInput.vue';
import { ClientConfig } from '@/utils/helpers/rest';
import { minValueIf } from '@/utils/helpers/risk-limit-validator';
import { getAllBenchmarks } from '@/utils/api/loans';
import { LoginState } from '@/store/store';
import { formatAmPmTime } from '@/utils/helpers/time';
import { TradingPermission, hasOneOfTradingPermission } from '@/utils/helpers/trading-permissions';
import MultipleChipAgreementSelector from '@/modules/agreements/components/MultipleChipAgreementSelector.vue';
import { AgreementInfo } from '@/modules/agreements/models';

interface FormFields {
  preferredRoundingRule: RoundingRule;
  preferredIndependentAmountRate: Decimal | null;
  defaultOrderSoftLimit: Decimal | null;
  defaultOrderHardLimit: Decimal | null;
  benchmarkPrice: Decimal | null;
  defaultOmsAgreements: AgreementInfo[];
  benchmarkType: string | null;
  cutOffTime: string | null;
}
interface FormErrors {
  apiErrors: string[];
  preferredIndependentAmountRate: string[];
  defaultOrderSoftLimit: string[];
  defaultOrderHardLimit: string[];
  benchmarkPrice: string[];
}

// Register router hooks to use in the component
Component.registerHooks(['beforeRouteLeave']);

@Component({
  components: { MultipleChipAgreementSelector },
  mixins: [validationMixin],
  validations: function (this: TraderAdminCompanyPreferences) {
    return {
      benchmarkType: {},
      preferredRoundingRule: {},
      preferredIndependentAmountRate: {
        decimal,
        notGreaterThanPrecision: notGreaterThanPrecision(IA_PRECISION),
        minValue: minValue(0),
        maxValue: maxValue(MAX_IA),
      },
      defaultOrderSoftLimit: {
        numeric,
        minValue: minValue(1),
      },
      defaultOrderHardLimit: {
        numeric,
        minValue: minValue(1),
        minOrderSoftLimitValue: minValueIf(
          !isNull(this.defaultOrderSoftLimit),
          this.defaultOrderSoftLimit
        ),
      },
      benchmarkPrice: {
        requiredIfTypeCustom: requiredIf(() => {
          return this.benchmarkType == 'Custom';
        }),
        decimal,
      },
    };
  },
  methods: {
    ...mapActions([]),
  },
  computed: {
    ...mapState(['clientConfig', 'loginState']),
  },
})
export default class TraderAdminCompanyPreferences extends mixins(validationMixin) {
  public $refs!: {
    softLimitInput: NumericInput;
    hardLimitInput: NumericInput;
    timePickerMenu: Vue;
  };

  public formInitialValues: Partial<FormFields> = {};

  // store state refs
  protected clientConfig!: ClientConfig;
  protected loginState!: LoginState;

  protected preferredIndependentAmountRate: Decimal | null = null;
  protected preferredRoundingRule: RoundingRule = RoundingRule.NoRounding;
  protected hasPreferredRoundingRule = false;
  protected defaultOmsAgreements: AgreementInfo[] = [];
  protected benchmarkType: string | null = null;
  protected benchmarkPrice: Decimal | null = null;
  protected benchmarkOptions = ['Custom', ...getAllBenchmarks().filter((i) => i !== 'NoBenchmark')];
  protected cutOffTime: string | null = null;

  protected roundingRuleOptions: Array<{ text: string; value: RoundingRule }> = [
    { value: RoundingRule.NoRounding, text: roundingRuleToString(RoundingRule.NoRounding) },
    {
      value: RoundingRule.UpToNearest0Dot01,
      text: roundingRuleToString(RoundingRule.UpToNearest0Dot01),
    },
    {
      value: RoundingRule.UpToNearest0Dot05,
      text: roundingRuleToString(RoundingRule.UpToNearest0Dot05),
    },
    {
      value: RoundingRule.UpToNearest0Dot10,
      text: roundingRuleToString(RoundingRule.UpToNearest0Dot10),
    },
    {
      value: RoundingRule.UpToNearest0Dot25,
      text: roundingRuleToString(RoundingRule.UpToNearest0Dot25),
    },
    {
      value: RoundingRule.UpToNearest1Dot0,
      text: roundingRuleToString(RoundingRule.UpToNearest1Dot0),
    },
  ];
  protected isProcessing = false;
  protected showSuccess = false;
  protected isTimePickerOpen = false;
  protected apiErrors: string[] = [];
  private defaultOrderSoftLimit: Decimal | null = null;
  private defaultOrderHardLimit: Decimal | null = null;
  private maxIa = MAX_IA;
  private iaPrecision = IA_PRECISION;

  protected get errorMsgs(): FormErrors {
    const errors: FormErrors = {
      apiErrors: this.apiErrors,
      preferredIndependentAmountRate: [],
      defaultOrderSoftLimit: [],
      defaultOrderHardLimit: [],
      benchmarkPrice: [],
    };

    // rate errors
    if (this.$v.preferredIndependentAmountRate.$dirty) {
      if (!this.$v.preferredIndependentAmountRate.minValue)
        errors.preferredIndependentAmountRate.push("can't be negative.");
      if (!this.$v.preferredIndependentAmountRate.maxValue)
        errors.preferredIndependentAmountRate.push(`can't be larger than ${MAX_IA}.`);
      if (!this.$v.preferredIndependentAmountRate.notGreaterThanPrecision) {
        errors.preferredIndependentAmountRate.push(
          `Rate must not be more than ${IA_PRECISION} decimal places.`
        );
      }
    }

    // soft limit errors
    if (!isNull(this.defaultOrderSoftLimit) && this.$v.defaultOrderSoftLimit.$dirty) {
      if (!this.$v.defaultOrderSoftLimit.minValue) {
        errors.defaultOrderSoftLimit.push('please enter a soft limit');
      }
    }

    // hard limit errors
    if (!isNull(this.defaultOrderHardLimit) && this.$v.defaultOrderHardLimit.$dirty) {
      if (!this.$v.defaultOrderHardLimit.minValue) {
        errors.defaultOrderHardLimit.push('please enter a hard limit');
      }

      if (!this.$v.defaultOrderHardLimit.minOrderSoftLimitValue) {
        errors.defaultOrderHardLimit.push('hard limit should always exceed the soft limit');
      }
    }

    // benchmark price errors
    if (this.$v.benchmarkPrice.$dirty) {
      if (!this.$v.benchmarkPrice.requiredIfTypeCustom) {
        errors.benchmarkPrice.push('please enter a value');
      }
      if (!this.$v.benchmarkPrice.numeric) {
        errors.benchmarkPrice.push('please enter a valid number');
      }
    }

    return errors;
  }

  protected get showError(): boolean {
    return !!this.errorMsgs.apiErrors.length;
  }

  protected get amPmTime(): string {
    return this.cutOffTime ? formatAmPmTime(this.cutOffTime) : '';
  }

  protected get canConfigCutOffTime(): boolean {
    return hasOneOfTradingPermission(
      this.loginState.user?.companyTradingPermissions as TradingPermission,
      TradingPermission.LenderPermissions
    );
  }

  protected getFormModelValues(): FormFields {
    return {
      preferredIndependentAmountRate: this.preferredIndependentAmountRate || new Decimal(0),
      preferredRoundingRule: this.preferredRoundingRule,
      defaultOrderSoftLimit: this.defaultOrderSoftLimit,
      defaultOrderHardLimit: this.defaultOrderHardLimit,
      defaultOmsAgreements: this.defaultOmsAgreements,
      benchmarkType: this.benchmarkType,
      benchmarkPrice: this.benchmarkPrice,
      cutOffTime: this.cutOffTime,
    };
  }

  protected async mounted(): Promise<void> {
    try {
      await this.fetchTraderAdminCompanyPreferences();
      this.$v.$reset();
      this.formInitialValues = this.getFormModelValues();
    } catch (e) {
      this.$log.warn(e);
    }
  }

  protected beforeRouteLeave(
    to: Route,
    from: Route,
    next: (to?: string | false | void | undefined) => void
  ): void {
    // check if anything was changed to see if we should let the user confirm he's sure about leaving
    if (this.$v.$anyDirty && !isEqual(this.getFormModelValues(), this.formInitialValues)) {
      this.$dialog.confirm({
        title: 'Are you sure?',
        message:
          'You have unsaved changes in the company preferences form. Are you sure you want to leave without saving?',
        isRejectable: true,
        rejectText: 'Cancel',
        acceptText: 'Continue',
        onAccept: () => {
          next();
        },
        onReject: () => {
          next(false);
        },
      });
    } else {
      next();
    }
  }

  protected async getTraderAdminCompanyPreferences(): Promise<CompanyAccount> {
    const { data } = await axios.get<{ company: CompanyAccount }>(`/api/1/trader-admin/company`);

    normalizeCompanyAccount(data.company);

    return data.company;
  }

  protected async fetchTraderAdminCompanyPreferences(): Promise<void> {
    const companyPreferences = await this.getTraderAdminCompanyPreferences();

    this.preferredIndependentAmountRate = companyPreferences.preferredIndependentAmountRate;

    if (companyPreferences.preferredRoundingRule !== null) {
      this.preferredRoundingRule = companyPreferences.preferredRoundingRule;
      this.hasPreferredRoundingRule = true;
    } else {
      this.preferredRoundingRule = RoundingRule.NoRounding;
      this.hasPreferredRoundingRule = false;
    }

    this.defaultOrderSoftLimit = companyPreferences.defaultOrderSoftLimit;
    this.defaultOrderHardLimit = companyPreferences.defaultOrderHardLimit;

    this.defaultOmsAgreements = companyPreferences.defaultOmsAgreements || [];

    this.benchmarkPrice = null;

    if (companyPreferences.benchmarkType) {
      this.benchmarkType = companyPreferences.benchmarkType;
    } else if (companyPreferences.benchmarkPrice) {
      this.benchmarkPrice = companyPreferences.benchmarkPrice;
      this.benchmarkType = 'Custom';
    } else {
      this.benchmarkType = 'OBFR';
    }
    this.cutOffTime = companyPreferences.cutOffTime;
  }

  protected async submitForm(): Promise<void> {
    // run validation
    if (this.isProcessing || !this.validateForm()) {
      return;
    }
    this.isProcessing = true;

    try {
      await this.updateCompanyPreferences(
        this.preferredIndependentAmountRate as Decimal,
        this.preferredRoundingRule,
        this.defaultOrderSoftLimit,
        this.defaultOrderHardLimit,
        this.benchmarkType,
        this.benchmarkPrice,
        this.defaultOmsAgreements,
        this.cutOffTime
      );

      this.showSuccess = true;
      void wait(1500).then(() => (this.showSuccess = false));

      // reload details
      await this.fetchTraderAdminCompanyPreferences();
      this.formInitialValues = this.getFormModelValues();
      this.$v.$reset();
    } catch (err) {
      this.apiErrors = [`${err}`];
    } finally {
      this.isProcessing = false;
    }
  }

  protected validateForm(): boolean {
    this.$v.$reset();
    this.apiErrors = [];
    this.$v.$touch();
    return !this.$v.$anyError;
  }

  protected async updateCompanyPreferences(
    rate: Decimal | null,
    roundingRule: RoundingRule | null,
    defaultOrderSoftLimit: Decimal | null,
    defaultOrderHardLimit: Decimal | null,
    benchmarkType: string | null,
    benchmarkPrice: Decimal | null,
    defaultOmsAgreements: AgreementInfo[],
    cutOffTime: string | null
  ): Promise<void> {
    try {
      const params = {
        preferredIndependentAmountRate: rate?.toFixed(IA_PRECISION),
        // leave roundingRule NULL if it wasn't set originally and we didn't change it
        preferredRoundingRule:
          this.hasPreferredRoundingRule || roundingRule != RoundingRule.NoRounding
            ? roundingRule
            : null,
        defaultOrderSoftLimit,
        defaultOrderHardLimit,
        defaultOmsAgreementIds: defaultOmsAgreements.map((c) => c.id),
        cutOffTime,
      };

      const payload =
        this.benchmarkType === 'Custom'
          ? { benchmarkPrice, ...params }
          : { benchmarkType, ...params };

      await axios.put(`/api/1/trader-admin/company`, payload);
    } catch (err) {
      throw new ApiError(i18nServerMessage(err as Error));
    }
  }

  private setSoftLimitFocus(): void {
    this.$refs.softLimitInput?.setFocus();
  }

  private setHardLimitFocus(): void {
    this.$refs.hardLimitInput?.setFocus();
  }
}
</script>

<style lang="scss" scoped>
small {
  font-size: 0.8rem;
}

.v-card .title {
  border-bottom: 1px solid #4a4a4a;
}
</style>

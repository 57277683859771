import { defineStore } from 'pinia';
import { nextTick, shallowRef, watch } from 'vue';
import { BookOrder } from '@/connect/gen/modules/apiengine/services/venue/venue_pb';

export const useStoreOrderForm = defineStore('orderForm', () => {
  const orderToMatch = shallowRef<BookOrder | null>(null);

  const showForm = shallowRef(sessionStorage.getItem('marketplace-show-form') === 'true' || false);
  watch(showForm, (val) => {
    sessionStorage.setItem('marketplace-show-form', String(val));
  });

  const orderRef = shallowRef<string | null>(null);

  function editOrder(order: BookOrder): void {
    showForm.value = true;
    orderToMatch.value = null;
    orderRef.value = order.clientOrderRef;
  }

  async function matchOrder(order: BookOrder): Promise<void> {
    // in case edit was ongoing, reset orderRef
    orderRef.value = null;
    showForm.value = true;
    await nextTick();
    orderToMatch.value = order;
  }

  async function resetAndShowForm(): Promise<void> {
    // force a re-render
    showForm.value = false;
    await nextTick();

    orderToMatch.value = null;
    orderRef.value = null;
    showForm.value = true;
  }

  return {
    showForm,
    orderRef,
    orderToMatch,
    editOrder,
    matchOrder,
    resetAndShowForm,
  };
});

export type StoreOrderForm = ReturnType<typeof useStoreOrderForm>;

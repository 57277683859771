import { CompanyInfo } from '@/modules/common/models';
import { AgreementInfo } from '@/modules/agreements/models';

export function formatCompanyName(cp: CompanyInfo): string {
  return `${cp.companyName} ${cp.displayBoxId != null ? ' (' + cp.displayBoxId + ')' : ''}`;
}

export function formatCompanyBoxId(cp: CompanyInfo): string {
  return cp.displayBoxId != null ? cp.displayBoxId : cp.companyName;
}

export function formatAgreementName(a: AgreementInfo): string {
  return a.displayId + '-' + a.shortName;
}

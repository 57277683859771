import {
  QueryMarketplaceInstrument,
  QueryMarketplaceItem,
} from '@/connect/gen/modules/apiengine/services/venue/venue_pb';

export function getRowId(params: {
  data: QueryMarketplaceItem | QueryMarketplaceInstrument | null;
}): string {
  // AgGrid may still be rendering the row or fetching data
  if (!params.data) return '';

  if ('instrument' in params.data && params.data.instrument) {
    return params.data.instrument.cusip; // row group
  }

  const borrowRef =
    'borrowOrder' in params.data && params.data.borrowOrder
      ? params.data.borrowOrder.rootClientOrderRef
      : '';
  const lendRef =
    'lendOrder' in params.data && params.data.lendOrder
      ? params.data.lendOrder.rootClientOrderRef
      : '';

  return `${borrowRef}_${lendRef}`; // order row
}

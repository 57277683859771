<script setup lang="ts">
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import MarketplaceTopOfBookWatchlistTable from '@/modules/marketplace/components/MarketplaceTopOfBookWatchlistTable.vue';
import MarketplaceTopOfBookWatchlistForm from '@/modules/marketplace/components/MarketplaceTopOfBookWatchlistForm.vue';
import { useMarketplaceApiService } from '@/modules/marketplace/services/marketplace-api.service';
import { TopOfBookWatchlist } from '@/modules/marketplace/models';
import { onMounted, shallowRef } from 'vue';

const watchlists = shallowRef<TopOfBookWatchlist[]>([]);
const createFormStatus = shallowRef<'closed' | 'form' | 'upload'>('closed');
const editDisplayId = shallowRef<string | null>(null);

onMounted(refreshWatchlists);

async function refreshWatchlists(): Promise<void> {
  watchlists.value = await useMarketplaceApiService().fetchWatchlists();
}
</script>

<template>
  <div class="d-flex flex-column flex fill-height">
    <DashboardPanel no-collapse title="Watchlists">
      <div class="d-flex mt-4">
        <VBtn
          class="icon-create ml-4"
          color="primary"
          data-test-watchlist-manager="create"
          split
          @click="createFormStatus = 'form'"
        >
          Create New
        </VBtn>
        <VBtn
          class="icon-upload"
          color="primary"
          data-test-watchlist-manager="upload"
          @click="createFormStatus = 'upload'"
        >
          <VIcon size="24"> mdi-cloud-upload </VIcon>
        </VBtn>
      </div>

      <MarketplaceTopOfBookWatchlistTable
        show-headers
        :watchlists="watchlists"
        @edit="editDisplayId = $event"
        @remove="refreshWatchlists"
      />
    </DashboardPanel>
    <MarketplaceTopOfBookWatchlistForm
      v-if="createFormStatus !== 'closed' || editDisplayId"
      :display-id="editDisplayId"
      :initial-step="createFormStatus === 'upload' ? 'upload' : 'form'"
      @close-modal="
        editDisplayId = null;
        createFormStatus = 'closed';
      "
      @refresh-watchlists="refreshWatchlists"
    />
  </div>
</template>

<style lang="scss" scoped>
.icon-create.primary {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin: 0 1px 0 0;
}

.icon-upload.primary {
  width: 24px;
  min-width: 20px;
  padding-left: 24px;
  padding-right: 24px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>

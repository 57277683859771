import { Agreement, Side } from '@/connect';
import { Permission, useStoreAuth } from '@/store/store-auth';
import { ComputedRef, MaybeRefOrGetter, computed, unref } from 'vue';

function toValue<T>(maybeRefOrGetter: MaybeRefOrGetter<T>) {
  return maybeRefOrGetter === null
    ? maybeRefOrGetter
    : typeof maybeRefOrGetter === 'function'
      ? (maybeRefOrGetter as () => T)()
      : unref(maybeRefOrGetter);
}

export function useIsIncoming(agreement: MaybeRefOrGetter<Agreement | null>): ComputedRef<boolean> {
  const { user } = useStoreAuth();
  return computed(() => {
    const agreementValue = toValue(agreement);
    return agreementValue?.proposedBySide === Side.LENDER
      ? agreementValue?.lenderCompany?.companyId !== user?.companyID
      : agreementValue?.borrowerCompany?.companyId !== user?.companyID;
  });
}

export function useHasPermission(...permissions: Permission[]): ComputedRef<boolean> {
  const { userPermissions } = useStoreAuth();
  return computed(() => permissions.some((permission) => userPermissions.has(permission)));
}

<template>
  <div class="plain-time-picker">
    <div :class="['v-input', 'v-text-field', { 'error--text': errorMessages?.length }]">
      <div style="position: relative; width: 12em">
        <v-row class="mb-1" dense>
          <v-label absolute active :class="[{ 'error--text': errorMessages?.length }]" :value="true"
            >{{ label }}
          </v-label>
          <v-col cols="4">
            <v-select
              ref="hr-select"
              v-model="hr"
              data-test-id="hr-select"
              :error="!!errorMessages?.length"
              hide-details
              :items="hrOptions"
            />
          </v-col>
          <v-col cols="4">
            <v-select
              ref="min-select"
              v-model="min"
              data-test-id="min-select"
              :error="!!errorMessages?.length"
              hide-details
              :items="minOptions"
            />
          </v-col>
          <v-col cols="4">
            <v-btn-toggle v-model="amPm" class="mt-2">
              <v-btn class="px-0" compact data-test-id="am-button" :value="'am'" x-small>am</v-btn>
              <v-btn class="px-0" compact data-test-id="pm-button" :value="'pm'" x-small>pm</v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <div v-if="!!errorMessages?.length" class="v-messages error--text">
          <div
            v-for="errorMessage in errorMessages"
            :key="errorMessage"
            class="v-messages__message"
          >
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { PropType } from 'vue';
import { AmPm, TimeTuple } from '@/modules/common/models';

@Component({
  props: {
    value: {
      type: [] as PropType<TimeTuple>,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    errorMessages: {
      type: Array,
      required: false,
    },
  },
  inheritAttrs: false,
})
export default class PlainTimePicker extends Vue {
  protected readonly value!: TimeTuple;
  protected readonly label!: string;
  protected readonly errorMessages?: string[];

  protected amPm: AmPm = 'pm';
  protected hr = 12;
  protected min = 0;

  protected get hrOptions(): Array<{ text: string; value: number }> {
    return [
      { text: '12', value: 0 },
      { text: '1', value: 1 },
      { text: '2', value: 2 },
      { text: '3', value: 3 },
      { text: '4', value: 4 },
      { text: '5', value: 5 },
      { text: '6', value: 6 },
      { text: '7', value: 7 },
      { text: '8', value: 8 },
      { text: '9', value: 9 },
      { text: '10', value: 10 },
      { text: '11', value: 11 },
    ];
  }

  protected get minOptions(): Array<{ text: string; value: number }> {
    const opts = [
      { text: '00', value: 0 },
      { text: '15', value: 15 },
      { text: '30', value: 30 },
      { text: '45', value: 45 },
    ];

    // if the current time (coming from upstream) isn't one of our options then we add it as an option
    if (opts.map((opt) => opt.value).indexOf(this.min) === -1) {
      opts.push({ text: `${this.min}`, value: this.min });
      opts.sort((a, b) => a.value - b.value);
    }

    return opts;
  }

  // $attrs doesn't include class and style (https://v2.vuejs.org/v2/api/#vm-attrs)
  protected get attrs(): Record<string, unknown> {
    return {
      ...this.$attrs,
      class: this.$vnode.data?.staticClass,
      style: this.$vnode.data?.staticStyle,
    };
  }

  @Watch('value', { immediate: true })
  protected onValueChange(value: TimeTuple): void {
    let amPm: AmPm = 'am';
    let hr = value[0];
    const min = value[1];
    if (hr >= 12) {
      hr = hr - 12;
      amPm = 'pm';
    }

    this.amPm = amPm;
    this.hr = hr;
    this.min = min;
  }

  @Watch('hr')
  @Watch('min')
  @Watch('amPm')
  protected emitNewValue(): void {
    this.$emit('input', [this.amPm == 'am' ? this.hr : this.hr + 12, this.min] as TimeTuple);
  }
}
</script>

<style lang="scss" scoped>
.plain-time-picker {
  .v-label {
    transform-origin: top left;
    max-width: 133%;
    transform: translateY(-18px) scale(0.75);
    pointer-events: auto;
    top: 6px;
  }

  .v-select {
    padding-top: 0px;
  }
}
</style>

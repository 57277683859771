import { LogLevel } from '@/modules/common/constants/logging.const';
import { loggerPreference } from '@/modules/common/services/logger-preference';
import logger from '@/modules/common/services/logger.service';
import { MyUser, Security, UserNotification } from '@/modules/common/models';
import { MarketTimeframes } from '@/modules/market-closed/types/market-closed';
import { AppState, newSessionState } from '@/store/store';
import { Mutation } from '@/types/vuex.custom';
import { CompanyAccount } from '@/utils/api/broker';
import { CounterpartyCredit } from '@/utils/api/credits';
import { UptimeService } from '@/utils/api/uptime';
import PermissionValidator from '@/utils/helpers/permissions';
import {
  ClientConfig,
  DesktopFeature,
  InvestorProfile,
  TradingPermissionOption,
  UXConfig,
  User2FASettings,
} from '@/utils/helpers/rest';
import { AuctionStatusMutation, SocketMessage } from '@/utils/helpers/socket';
import { extend } from 'lodash';
import { BespokeAuction } from '@/modules/auction/models';
import { BorrowerLocate } from '@/modules/borrower/models';
import { User } from '@/modules/user-accounts/models';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.js';

/**
 * Export key names as a helper type
 */
export type MutationKeys = keyof typeof mutations;

export const mutations = {
  updateClientConfig: function (state, clientConfig) {
    state.clientConfig = clientConfig;

    // backend may enforce reload of the UI
    if (state.clientConfig.frontendHash !== '') {
      state.frontendHash = state.clientConfig.frontendHash;
    }

    if (!loggerPreference.hasPreference()) {
      if (clientConfig.systemProdLike) {
        // in production print only errors
        logger.setDebugLevel(LogLevel.Error);
      } else {
        // be verbose in development
        logger.setDebugLevel(LogLevel.Debug);
      }
    }
  } as Mutation<AppState, ClientConfig>,

  clearCurrentUser: function (state) {
    // @TODO solve issue of injecting initialConfig....if this is really necessary
    // could perhaps store initial state in the Store itself.
    // extend(state, newSessionState(initialConfig));
    extend(state, newSessionState());
  } as Mutation<AppState, void>,

  updateCurrentUser: function (state, user) {
    state.loginState.user = user;
    // cache permission validator as it is called often and user.roles will not change
    state.loginState.permissionValidator = new PermissionValidator(user.roles);
    // cache the effective trading permission of the user (might be restricted by the company's permissions)
    state.loginState.tradingPermissions =
      user.tradingPermissions === null
        ? user.companyTradingPermissions // inherit the company's permissions
        : user.tradingPermissions & user.companyTradingPermissions; // intersect company and user permissions;
  } as Mutation<AppState, MyUser>,

  updateUXConfig: function (state, uxConfig) {
    state.uxConfig = uxConfig;
    sessionStorage.uxConfig = JSON.stringify(uxConfig);
  } as Mutation<AppState, UXConfig>,

  update2FASettings: function (state, tfaSettings) {
    state.loginState.tfa = tfaSettings;
  } as Mutation<AppState, User2FASettings>,

  updateNotifications: function (state, notifications) {
    state.notifications = notifications;
  } as Mutation<AppState, UserNotification[]>,

  updateTotalNotificationCount: function (state, notificationCount) {
    state.totalNotificationCount = notificationCount;
  } as Mutation<AppState, number>,

  updateUnreadNotificationCount: function (state, notificationCount) {
    state.unreadNotificationCount = notificationCount;
  } as Mutation<AppState, number>,

  updateDesktopFeatures: function (state, features) {
    state.desktopFeatures = features;
  } as Mutation<AppState, DesktopFeature[]>,

  updateLastNotification: function (state, message) {
    state.lastNotification = message;
  } as Mutation<AppState, SocketMessage<unknown>>,

  updateAuctions: function (state, auctions) {
    state.auctions = auctions;
  } as Mutation<AppState, BespokeAuction[]>,

  updateAuctionsHistory: function (state, auctions) {
    state.auctionsHistory = auctions;
  } as Mutation<AppState, BespokeAuction[]>,

  updateLenderLoanSocketEvent: function (state, loanId) {
    state.socketEvents.openLoans.lenderLoan = { loanId };
  } as Mutation<AppState, string>,

  updateBorrowerLoanSocketEvent: function (state, loanId) {
    state.socketEvents.openLoans.borrowerLoan = { loanId };
  } as Mutation<AppState, string>,

  updateBrokerLoanSocketEvent: function (state, loanId) {
    state.socketEvents.openLoans.brokerLoan = { loanId };
  } as Mutation<AppState, string>,

  updateTermLoanSocketEvent: function (state, termContractDisplayId) {
    state.socketEvents.termLoans = { termContractDisplayId };
  } as Mutation<AppState, string>,

  updateBorrowerLocates: function (state, locates) {
    state.borrowerLocates = locates;
  } as Mutation<AppState, BorrowerLocate[]>,

  updateBrokerCompanies: function (state, companies) {
    state.companies = companies;
  } as Mutation<AppState, CompanyAccount[]>,

  updateBrokerUsers: function (state, users) {
    state.users = users;
  } as Mutation<AppState, User[]>,

  updateCompanyUsers: function (state, users) {
    state.companyUsers = users;
  } as Mutation<AppState, User[]>,

  updateCounterpartyCredits: function (state, credits) {
    state.counterpartyCredits = credits;
  } as Mutation<AppState, CounterpartyCredit[]>,

  updateUptimeServices: function (state, services) {
    state.uptimeServices = services;
  } as Mutation<AppState, UptimeService[]>,

  updateTradingPermissionOptions: function (state, options) {
    state.tradingPermissionOptions = options;
  } as Mutation<AppState, TradingPermissionOption[]>,

  updateInvestorProfiles: function (state, profiles) {
    state.investorProfiles = profiles;
  } as Mutation<AppState, InvestorProfile[]>,

  updateMarketplaceOrdersEvent: function (state, orderRef) {
    state.socketEvents.marketplace.orders = { orderRef };
  } as Mutation<AppState, string>,

  updateTermLoansEvent: function (state, orderRef) {
    state.socketEvents.termLoans = { termContractDisplayId: orderRef };
  } as Mutation<AppState, string>,

  updateLastVisitedSymbolOverview: function (state, security) {
    state.lastVisitedSymbolOverview = security;
  } as Mutation<AppState, Security>,

  updateMarketTimeframes: function (state, timeframes) {
    if (state.clientConfig) {
      state.clientConfig.marketTimeframes = timeframes;
    }
  } as Mutation<AppState, MarketTimeframes>,

  updateFrontendHash: function (state, hash: string) {
    state.frontendHash = hash;
    logger.debug('reconnect state.frontendHash=', state.frontendHash);
  } as Mutation<AppState, string>,

  updateCurrentTimeUTC: function (state, currentTimeUTC) {
    state.currentTimeUTC = currentTimeUTC;
    state.localTimezone = moment(currentTimeUTC).tz(moment.tz.guess()).zoneAbbr();
  } as Mutation<AppState, Date>,

  SENTRY_CONNECT: function (state, status) {
    state.sentryConnected = status;
  } as Mutation<AppState, boolean>,

  SOCKET_DISCONNECT: function (state) {
    state.socketConnected = false;
  } as Mutation<AppState, void>,

  SOCKET_ONOPEN: function (state) {
    logger.debug('ws: onopen', state);
    state.socketConnected = true;
    state.socketReconnecting = false;
  } as Mutation<AppState, void>,

  SOCKET_ONCLOSE: function (state) {
    state.socketConnected = false;
  } as Mutation<AppState, void>,

  SOCKET_ONERROR: function () {
    // ignore
  } as Mutation<AppState, void>,

  SOCKET_ONMESSAGE: function (_state, message) {
    // ignore
    logger.debug('ws: uncaught message...', message);
  } as Mutation<AppState, void>,

  SOCKET_RECONNECT: function (state, count) {
    logger.debug('ws: reconnecting...', count);
    state.socketReconnecting = true;
  } as Mutation<AppState, void>,

  SOCKET_RECONNECT_ERROR: function () {
    // ignore
  } as Mutation<AppState, void>,

  SOCKET_AUCTION_STATUS: function (state, mutation) {
    // find auctions and update status
    state.auctions.some((a) => {
      if (a.id === mutation.payload.auctionId) {
        a.status = mutation.payload.status;
        return true;
      }
      return false;
    });
  } as Mutation<AppState, SocketMessage<AuctionStatusMutation>>,
};

import { defineStore } from 'pinia';
import { shallowRef } from 'vue';
import { PlainMessage } from '@/connect';
import { PbCompanyInfo } from '@/connect/gen/common/protos/types_pb';

export const useStoreCompanies = defineStore('companies', () => {
  // Once we migrate to Vue3 we should use shallowReactive
  const companies = shallowRef(new Map<string, PlainMessage<PbCompanyInfo>>());

  function addCompanies(items: Record<string, PlainMessage<PbCompanyInfo>>) {
    companies.value = new Map([...companies.value, ...Object.entries(items)]);
  }

  function getCompany(id: string) {
    return companies.value.get(id);
  }

  return {
    addCompanies,
    getCompany,
  };
});

export type CompaniesStore = ReturnType<typeof useStoreCompanies>;

import { AgreementStatus, ConnectResult, Data, createPromiseClient } from '@/connect';
import { AgreementsService } from '@/connect/gen/modules/apiengine/services/agreements/agreements_connect';
import {
  ApproveAgreementRequest,
  ApproveAgreementResponse,
  CancelAgreementRequest,
  CancelAgreementResponse,
  CreateAgreementRequest,
  CreateAgreementResponse,
  ModifyAgreementRequest,
  ModifyAgreementResponse,
  QueryAgreementsRequest,
  QueryAgreementsResponse,
  RejectAgreementRequest,
  RejectAgreementResponse,
} from '@/connect/gen/modules/apiengine/services/agreements/agreements_pb';

export * from '@/connect/gen/modules/apiengine/services/agreements/agreements_pb';

export const serviceAgreements = new (class {
  public readonly promiseClient = createPromiseClient(AgreementsService);

  public async createAgreement(
    config: Data<CreateAgreementRequest>
  ): Promise<ConnectResult<CreateAgreementResponse>> {
    const result = await this.promiseClient.createAgreement(config);
    return result as ConnectResult<CreateAgreementResponse>;
  }

  public async approveAgreement(
    config: Data<ApproveAgreementRequest>
  ): Promise<ConnectResult<ApproveAgreementResponse>> {
    const result = await this.promiseClient.approveAgreement(config);
    return result as ConnectResult<ApproveAgreementResponse>;
  }

  public async rejectAgreement(
    config: Data<RejectAgreementRequest>
  ): Promise<ConnectResult<RejectAgreementResponse>> {
    const result = await this.promiseClient.rejectAgreement(config);
    return result as ConnectResult<RejectAgreementResponse>;
  }

  public async modifyAgreement(
    config: Data<ModifyAgreementRequest>
  ): Promise<ConnectResult<ModifyAgreementResponse>> {
    const result = await this.promiseClient.modifyAgreement(config);
    return result as ConnectResult<ModifyAgreementResponse>;
  }

  public async cancelAgreement(
    config: Data<CancelAgreementRequest>
  ): Promise<ConnectResult<CancelAgreementResponse>> {
    const result = await this.promiseClient.cancelAgreement(config);
    return result as ConnectResult<CancelAgreementResponse>;
  }

  public async queryAgreements(
    config: Data<QueryAgreementsRequest>
  ): Promise<ConnectResult<QueryAgreementsResponse>> {
    const result = await this.promiseClient.queryAgreements({
      statuses: config.statuses.length
        ? config.statuses
        : [
            AgreementStatus.ACTIVE,
            AgreementStatus.CANCELED,
            AgreementStatus.DRAFT,
            AgreementStatus.INACTIVE,
            AgreementStatus.REJECTED,
            AgreementStatus.UNSPECIFIED,
          ],
      side: config.side,
      instrument: config.instrument,
    });
    return result as ConnectResult<QueryAgreementsResponse>;
  }
})();

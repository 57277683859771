/* eslint-disable @typescript-eslint/naming-convention */
import { useIsOnline } from '@/composables/native';
import { store as vuex } from '@/store/store';
import { Lock } from '@/utils/helpers/lock';
import { Mediator } from '@/utils/helpers/mediator';
import { SocketMessagesIncoming, SocketMessagesOutgoing } from '@/utils/helpers/socket';
import { defineStore } from 'pinia';
import Vue, { computed, shallowRef, watch } from 'vue';

const WS_PROTOCOL = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
const WS_HOST = window.location.host + '/ws';

type LockStoreSocket = 'connection-state' | 'is-authenticated';

export const useStoreSocket = defineStore('socket', () => {
  const isConnected = shallowRef(false);
  const isReconnecting = shallowRef(false);
  const lockSocket = new Lock<LockStoreSocket>();

  let socket: WebSocket;
  const reconnectTimeout = shallowRef(0);
  const mediator = new Mediator<SocketMessagesIncoming & { open: void }>();

  lockSocket.bind('connection-state', () => isReconnecting.value || !isConnected.value);

  function onOpen() {
    socket.addEventListener('close', onClose);
    socket.addEventListener('message', onMessage);

    isConnected.value = true;
    isReconnecting.value = false;
    mediator.dispatch('open', undefined);
  }

  function delayedReconnect() {
    isReconnecting.value = false;
    setTimeout(reconnect, reconnectTimeout.value);

    // increase reconnect delay to not spam server
    if (reconnectTimeout.value < 1000) {
      reconnectTimeout.value += 250;
    }
  }

  function onClose() {
    socket.removeEventListener('close', onClose);
    socket.removeEventListener('error', onError);
    socket.removeEventListener('message', onMessage);
    socket.removeEventListener('open', onOpen);

    isConnected.value = false;
    delayedReconnect();
  }

  function onError() {
    delayedReconnect();
  }

  function onMessage(event: MessageEvent<string>) {
    const data = JSON.parse(event.data);
    mediator.dispatch(data.action || data.mutation, data.payload);
  }

  function reconnect(): void {
    if (isReconnecting.value) return;
    isReconnecting.value = true;
    reconnectTimeout.value = 0;

    if (!isConnected.value) return connect();

    socket.close();

    // TODO: remove legacy vue-native-websocket integration
    Vue.prototype.$disconnect();
  }

  function connect(): void {
    if (isConnected.value) return reconnect();

    lockSocket.lock('is-authenticated');

    socket = new WebSocket(WS_PROTOCOL + WS_HOST);
    socket.addEventListener('open', onOpen);
    socket.addEventListener('error', onError);

    // TODO: remove legacy vue-native-websocket integration
    Vue.prototype.$connect(undefined, { WebSocket: socket, store: vuex, format: 'json' });
  }

  // make sure WebSocket connects whenever we go online
  const isOnline = useIsOnline();
  watch(
    isOnline,
    () => {
      if (isOnline.value) connect();
    },
    { immediate: true }
  );

  async function send<T extends keyof SocketMessagesOutgoing>(
    message: {
      action: T;
      payload: SocketMessagesOutgoing[T];
    },
    bypass?: LockStoreSocket[]
  ) {
    await lockSocket.wait(bypass);
    if (!isConnected.value) return false;
    socket.send(JSON.stringify(message));
    return true;
  }

  const isShortReconnect = computed(() => isReconnecting.value && reconnectTimeout.value === 0);

  return {
    on: mediator.on,
    off: mediator.off,
    send,
    reconnect,

    onOpen: (callback: () => void) => {
      if (isConnected.value) callback();
      mediator.on('open', callback);
    },

    lockSocket,

    // don't consider short reconnect as disconnect or reconnect
    isConnected: computed(() => isConnected.value || isShortReconnect.value),
    isReconnecting: computed(() => isReconnecting.value && !isShortReconnect.value),
  };
});

export type StoreSocket = ReturnType<typeof useStoreSocket>;

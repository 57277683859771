import { Hrs, Mins, Timeframe, TimeframeKey } from '@/modules/market-closed/types/market-closed';
import { ClientConfig, UXConfig } from '@/utils/helpers/rest';

/**
 * Utility functions to manage the timeframes during which the market is open/closed
 * facilitates enableing/disableing features at the appropriate times
 */

/**
 * Checks if a given timeframed feature should currently be enabled
 */
export function isFeatureEnabled(
  featureKey: TimeframeKey,
  clientConfig: ClientConfig | null,
  uxConfig: UXConfig,
  currentTimeUTC: Date | null
): boolean {
  // when forceMarketTimeframesClosed is true we will pretend all features are disabled
  if (uxConfig?.forceMarketTimeframesClosed === true) {
    return false;
  }

  // if timeframes aren't enabled then all features are enabled
  //  unless the user has chosen to enable it explicitly for testing.
  if (
    clientConfig?.marketTimeframesEnabled === false &&
    uxConfig?.forceMarketTimeframesCheckingEvenWhenDisabled === false
  ) {
    // no need to check timeframe but still check feature flags
    return isFeatureFlagEnabled(featureKey, clientConfig);
  }

  const marketTimeframes = clientConfig?.marketTimeframes;
  const nowUTC = currentTimeUTC || new Date();
  if (marketTimeframes) {
    if (marketTimeframes.isHoliday || marketTimeframes.isWeekend) {
      return false;
    } else if (marketTimeframes[featureKey]) {
      return (
        isTimeInTimeframe(nowUTC, marketTimeframes[featureKey]) &&
        isFeatureFlagEnabled(featureKey, clientConfig)
      );
    } else {
      throw new Error(`missing market timeframe for '${featureKey}'`);
    }
  } else {
    return false;
  }
}

/**
 *  Timeframe may be open but feature flag can be disabled
 *  Currently only implemented for `createLoans`
 *  TODO(ronald): implement other feature flags (here or in separate service)
 */
function isFeatureFlagEnabled(
  featureKey: TimeframeKey,
  clientConfig: ClientConfig | null
): boolean {
  if (featureKey === 'createLoans') {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return clientConfig!.createLoansEnabled;
  } else {
    return true;
  }
}

/**
 * Checks if the given UTC time is between the given UTC timeframe
 * (exported for testing)
 */
export function isTimeInTimeframe(time: Date, timeframe: Timeframe): boolean {
  const convertedTimeframe = convertToHrsMins(timeframe);

  // set UTC time portion of the given date
  const openTime = new Date(time);
  const closeTime = new Date(time);
  openTime.setUTCHours(...convertedTimeframe[0]);
  closeTime.setUTCHours(...convertedTimeframe[1]);

  if (closeTime < openTime) {
    // crossing over the midnight point
    const isOutsideTimeframe = time < openTime && time >= closeTime;
    return !isOutsideTimeframe;
  } else {
    return openTime <= time && time < closeTime;
  }
}

/**
 * Convert a timeframe into its Hours and Minutes
 * (exported for testing)
 */
export function convertToHrsMins(timeframe: Timeframe): [[Hrs, Mins], [Hrs, Mins]] {
  // prettier-ignore
  return [
      timeframe[0].split<Timeframe[0]>(':'),
      timeframe[1].split<Timeframe[1]>(':')
    ];
}

/**
 * Regularly updates the store with the current time.
 * Provides time-based reactivity in computed properties and other reactive methods
 * (exported for testing)
 */
export function updateStoreTime(action: () => void): void {
  // refresh the current time in the store regularly so that we can achieve time-dependant reactivity
  // this allows us to make sure that computed properties that rely on isFeatureEnabled() are
  // recomputed regularly as time changes
  const interval = 60 * 1000; // every 1min
  const timeToNextMin = (60 - new Date().getSeconds()) * 1000;

  setTimeout(() => {
    // do first update then update every minute, on the minute
    void action();

    setInterval(() => {
      void action();
    }, interval);
  }, timeToNextMin);

  void action();
}

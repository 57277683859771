<script setup lang="ts">
import { TopOfBookWatchlist } from '@/modules/marketplace/models';
import MarketplaceTopOfBookWatchlistRemoveButton from './MarketplaceTopOfBookWatchlistRemoveButton.vue';

const props = defineProps<{
  watchlists: TopOfBookWatchlist[];
  showHeaders?: boolean;
}>();

const emit = defineEmits<{
  (event: 'edit', displayId: string): void;
  (event: 'remove'): void;
}>();

const tableColumns = props.showHeaders
  ? [{ text: 'Name', value: 'name' }, { value: 'actions' }]
  : undefined;
</script>

<template>
  <div class="table-container">
    <div>
      <VDataTable
        disable-filtering
        disable-pagination
        disable-sort
        fixed-header
        :headers="tableColumns"
        hide-default-footer
        item-key="key"
        :items="watchlists"
        no-data-text="You haven't created any watchlists yet."
        width="100%"
      >
        <template #item="{ item }">
          <tr>
            <td>
              {{ item.name }}
            </td>
            <td class="text-right">
              <VBtn
                class="icon-action"
                data-test-watchlist-manager="edit"
                icon
                @click="emit('edit', item.displayId)"
              >
                <VIcon size="24">mdi-pencil</VIcon>
              </VBtn>
              <MarketplaceTopOfBookWatchlistRemoveButton
                :watchlist="item"
                @remove="emit('remove')"
              />
            </td>
          </tr>
        </template>
      </VDataTable>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 50vh;
}

.table-container > div {
  display: flex;
  overflow: hidden;
}

::v-deep {
  .v-data-table,
  .v-data-table > div {
    width: 100%;
    display: flex;
  }
}

.icon-action {
  opacity: 0.4;
  transition: opacity linear 0.15s;
}

.icon-action:hover {
  opacity: 1;
}
</style>

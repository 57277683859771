<script setup lang="ts">
import { computed } from 'vue';
import { OmsOrderType } from '@/connect';

const props = defineProps<{
  value: OmsOrderType;
}>();

const emit = defineEmits(['input']);

const model = computed({
  get: () => props.value,
  set: (value) => emit('input', value),
});
</script>

<template>
  <VSelect
    v-model="model"
    :items="[
      { value: OmsOrderType.UNSPECIFIED_ORDER_TYPE, text: 'Limit and IOI' },
      { value: OmsOrderType.LIMIT, text: 'Limit' },
      { value: OmsOrderType.IOI, text: 'IOI' },
    ]"
    label="Order Type"
  />
</template>

import { IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { serviceMarketplace } from '@/connect/services/marketplace';
import { Side } from '@/connect/gen/consts/commonconsts_pb';
import {
  QueryMarketplaceFilter,
  QueryMarketplaceInstrumentsRequest,
  QueryMarketplaceRequest,
} from '@/connect/gen/modules/apiengine/services/venue/venue_pb';
import { OmsOrderType } from '@/connect/gen/consts/omsconsts_pb';
import { useStoreSecurities } from '@/store/store-securities';
import { useStoreAgreements } from '@/store/store-agreements';
import { Security } from '@/modules/common/models/security';
import { CompanyInfo } from '@/modules/common/models';
import { AgreementInfo } from '@/modules/agreements/models';
import { SettlementType } from '@/connect';
import { TopOfBookWatchlist } from '../models';

export interface DataSourceConfig {
  ordersPageLimit: number;
  tradeIntention: Side;
  ownInstruments: boolean;
  counterpartyInstruments: boolean;
  securities: Security[] | null;
  watchlist: TopOfBookWatchlist | null;
  orderType: OmsOrderType;
  companies: CompanyInfo[];
  agreements: AgreementInfo[];
  settlementType: SettlementType;
}

interface GetSideFilterParams
  extends Omit<
    DataSourceConfig,
    'ordersPageLimit' | 'watchlistDisplayId' | 'ownInstruments' | 'counterpartyInstruments'
  > {
  // for convinience match BE response shape (instead of using "Side" enum)
  direction: 'borrow' | 'lend';
  params: IServerSideGetRowsParams;
}

export function createDataSource(config: DataSourceConfig): IServerSideDatasource {
  return {
    async getRows(params) {
      if (params.request.groupKeys.length === 0) {
        await fetchGroups(params, config);
      } else {
        await fetchItems(params, config);
      }
    },
  };
}

async function fetchGroups(params: IServerSideGetRowsParams, config: DataSourceConfig) {
  try {
    const request = new QueryMarketplaceInstrumentsRequest({
      tradeIntention: config.tradeIntention,
      ownInstruments: config.ownInstruments,
      counterpartyInstruments: config.counterpartyInstruments,
      watchlistDisplayId: config.watchlist?.displayId,
      ...getBothSideFilters(params, config),
    });
    const response = await serviceMarketplace.querySecurities(request);
    if (!response.success) throw new Error('Failed to fetch securities');

    const rowData = Object.values(response.data.instruments).sort((a, b) =>
      (a.instrument?.ticker ?? '').localeCompare(b.instrument?.ticker ?? '')
    );

    params.success({ rowData, rowCount: rowData.length });
  } catch (e) {
    params.fail();
  }
}

async function fetchItems(params: IServerSideGetRowsParams, config: DataSourceConfig) {
  try {
    const queryParams = {
      tradeIntention: config.tradeIntention,
      ...getBothSideFilters(params, config),
    };

    const cusip = params.request.groupKeys[0];
    queryParams.lendFilter.instruments = [cusip];
    queryParams.borrowFilter.instruments = [cusip];

    const startRow = params.request.startRow ?? 0;
    const endRow = params.request.endRow ?? config.ordersPageLimit;
    const pageLimit = endRow - startRow;
    const pageNumber = Math.floor(startRow / pageLimit) + 1;

    const request = new QueryMarketplaceRequest({ ...queryParams, pageNumber, pageLimit });
    const response = await serviceMarketplace.queryMarketplace(request);
    if (!response.success) throw new Error('Failed to fetch marketplace');

    useStoreSecurities().addSecurities(response.data.instruments);
    useStoreAgreements().addAgreements(response.data.agreements);

    const rowData = response.data.items;
    const rowCount = rowData.length < pageLimit ? startRow + rowData.length : undefined;

    // "rowCount: undefined" indicates to AgGrid there are more rows to fetch
    params.success({ rowData, rowCount });
  } catch (e) {
    params.fail();
  }
}

function getBothSideFilters(params: IServerSideGetRowsParams, config: DataSourceConfig) {
  return {
    borrowFilter: getSideFilter({ params, direction: 'borrow', ...config }),
    lendFilter: getSideFilter({ params, direction: 'lend', ...config }),
  };
}

function getSideFilter({
  direction,
  params,
  securities,
  orderType,
  companies,
  agreements,
  settlementType,
}: GetSideFilterParams) {
  return {
    orderType: getOrderTypeFilter(orderType),
    counterpartyIds: getCounterpartiesFilter(companies),
    agreementIds: getAgreementsFilter(agreements),
    instruments: securities ? securities.map((s) => s.cusip) : undefined,
    settlementType: settlementType === SettlementType.UNSPECIFIED ? undefined : settlementType,
    ...getQuantityFilter(params, direction),
    ...getRateFilter(params, direction),
  } as QueryMarketplaceFilter;
}

function getAgreementsFilter(agreements: AgreementInfo[]) {
  if (agreements.length === 0) return undefined;
  return agreements.map((c) => c.id);
}

function getCounterpartiesFilter(counterparties: CompanyInfo[]) {
  if (counterparties.length === 0) return undefined;
  return counterparties.map((c) => c.companyId);
}

function getOrderTypeFilter(orderType: OmsOrderType): OmsOrderType | undefined {
  return orderType === OmsOrderType.UNSPECIFIED_ORDER_TYPE ? undefined : orderType;
}

function getQuantityFilter(params: IServerSideGetRowsParams, direction: 'borrow' | 'lend') {
  const filter = params.request.filterModel?.[`${direction}Quantity`];
  if (!filter) return undefined;
  switch (filter.type) {
    case 'equals':
      return {
        minimumQuantity: BigInt(filter.filter),
        maximumQuantity: BigInt(filter.filter),
      };
    case 'lessThan':
      return { maximumQuantity: BigInt(filter.filter) };
    case 'greaterThan':
      return { minimumQuantity: BigInt(filter.filter) };
  }
  return undefined;
}

function getRateFilter(params: IServerSideGetRowsParams, direction: 'borrow' | 'lend') {
  const filter = params.request.filterModel?.[`${direction}Rate`];
  if (!filter) return undefined;
  switch (filter.type) {
    case 'equals':
      return {
        minimumRate: filter.filter,
        maximumRate: filter.filter,
      };
    case 'lessThan':
      return { maximumRate: filter.filter };
    case 'greaterThan':
      return { minimumRate: filter.filter };
  }
  return undefined;
}

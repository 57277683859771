<script setup lang="ts">
import { store } from '@/store/store';
import { useStoreUI } from '@/store/store-ui';
import { suppressKeyboardEvent } from './focus';

const storeUI = useStoreUI();
</script>

<template>
  <div style="display: grid; grid-template-rows: 1fr; flex-grow: 1">
    <slot
      v-bind="{
        class: storeUI.isDarkMode ? 'ag-theme-material-dark' : 'ag-theme-material',
        headerHeight: 32,
        overlayNoRowsTemplate: 'No data available',
        rowHeight: 32,
        rowSelection: 'multiple',
        sortingOrder: ['asc', 'desc'],
        suppressRowVirtualisation: false,
        suppressColumnVirtualisation: store.getters.shouldSuppressGridVirtualisation,
        suppressMaxRenderedRowRestriction: false,
        suppressRowClickSelection: true,
        defaultColDef: { suppressKeyboardEvent },
        detailRowHeight: true,
      }"
    />
  </div>
</template>

<style lang="scss">
.ag-center-cols-container {
  min-width: 100%;
}

.theme--dark .ag-theme-material-dark {
  --ag-header-foreground-color: rgba(255, 255, 255, 0.7) !important;
  --ag-header-cell-hover-background-color: transparent;
  --ag-header-background-color: transparent;
  --ag-footer-background-color: transparent;
  --ag-background-color: #1e1e1e;
  --ag-odd-row-background-color: transparent;
  --ag-checkbox-checked-color: #fff;
  --ag-checkbox-unchecked-color: #fff;
  --ag-checkbox-background-color: transparent;
  --ag-material-accent-color: #fff;
  --ag-range-selection-border-color: #fff;
  --ag-selected-row-background-color: #505050;
  --ag-row-hover-color: #616161;
  --ag-cell-horizontal-padding: 1rem;
  --ag-value-change-value-highlight-background-color: #888;
  --scrollbarBG: #282828;

  .ag-floating-bottom {
    background-color: #141414;
  }
}

.ag-header-cell {
  /* ignore checkbox header */
  &:not(:has(.ag-header-select-all:not(.ag-hidden))) {
    --ag-cell-horizontal-padding: 0.5rem !important;
  }

  .ag-cell-label-container {
    flex-direction: row;

    .ag-sort-indicator-container {
      /**
           * hide container for sorting indicator that has no visible children
           * in order to exclude it from flex flow and remove useless gap
           */
      &:not(:has(.ag-sort-indicator-icon:not(.ag-hidden))) {
        display: none;
      }

      & > * {
        padding: 0;
      }
    }

    &.ag-header-cell-sorted-none:not(:hover) {
      opacity: 0.7;
    }

    .ag-header-cell-label {
      gap: 0.5rem;
      padding-left: 0.5rem;
    }

    &.header-center .ag-header-cell-label {
      justify-content: center;
      justify-content: flex-center;

      .ag-sort-indicator-container:not(:has(> :not(.ag-hidden))) {
        display: block !important;
        .ag-hidden.ag-sort-none-icon {
          display: block !important;
          visibility: hidden;
        }
      }
    }

    &.header-right .ag-header-cell-label {
      justify-content: end;
      justify-content: flex-end;
    }
  }

  &.header-center .ag-header-cell-label {
    justify-content: flex-center;
  }

  &.header-right .ag-header-cell-label {
    justify-content: flex-end;
  }
}

.ag-cell {
  display: flex;
  justify-content: start;
  align-items: center;

  &:has(
    /** cell has focusable elements, don't allow to focus */
    :is(
      button,
      a[href],
      input:not([type='hidden']),
      select,
      textarea,
      [tabindex]
    ):not(
      [disabled],
      [tabindex^='-'] /** eliminate negative values */
    )
  ) {
    &:focus-within {
      border-color: transparent;
    }
  }
}

.v-text-field {
  margin: 0;
}
</style>

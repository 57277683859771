<script lang="ts">
const AGREEMENT_EDITABLE_STATUSES = new Set<AgreementStatus>([
  AgreementStatus.ACTIVE,
  AgreementStatus.DRAFT,
]);
</script>

<!-- eslint-disable @typescript-eslint/unified-signatures -->

<script setup lang="ts">
import {
  Agreement,
  AgreementStatus,
  ApproveAgreementRequest,
  formatRoundingRule,
  formatSettlementType,
  getAgreementStatusColor,
  getAgreementStatusName,
} from '@/connect';
import { useHasPermission, useIsIncoming } from '@/modules/agreements/composables';
import {
  formatCompanyInfo,
  formatIndependentAmountRate,
  formatTimestamp,
} from '@/modules/agreements/utils';
import { useRules } from '@/utils/vuetify-validation';
import { computed, shallowRef, watch, watchEffect } from 'vue';

const props = withDefaults(
  defineProps<{
    agreement: Agreement;
    reservedShortNames?: Set<string>;
  }>(),
  {
    reservedShortNames: () => new Set(),
  }
);

const emit = defineEmits<{
  (event: 'cancel'): void;
  (event: 'close', agreement: Agreement): void;
  (event: 'duplicate', agreement: Agreement): void;
  (event: 'edit', agreement: Agreement): void;
  (event: 'reject', agreement: Agreement, onRejected: () => void): void;
  (event: 'approve', request: ApproveAgreementRequest): void;
}>();

const canUserEditAgreement = useHasPermission('agreement-edit');
const isIncoming = useIsIncoming(() => props.agreement);
const isIncomingDraft = computed(
  () => props.agreement?.status === AgreementStatus.DRAFT && isIncoming.value
);
const isAgreementEditable = computed(() => AGREEMENT_EDITABLE_STATUSES.has(props.agreement.status));

const shortNameInitial = shallowRef('');
watch(
  () => props.agreement,
  () => (shortNameInitial.value = props.agreement?.shortName ?? ''),
  { immediate: true }
);

const shortName = shallowRef('');
watchEffect(() => (shortName.value = props.agreement?.shortName ?? ''));

const { rules, isValid } = useRules<{ shortName: string }>({
  shortName: {
    rules: [
      (value) => !!value || 'Required',
      (value) =>
        value === shortNameInitial.value ||
        !props.reservedShortNames.has(value.trim().toUpperCase()) ||
        'Must be unique',
      (value) => value.length <= 25 || 'Must have at most 25 characters',
    ],
  },
});

function closeDialog() {
  emit('cancel');
}

function agreementClose(agreement: Agreement) {
  emit('close', agreement);
  closeDialog();
}

function agreementDuplicate(agreement: Agreement) {
  emit('duplicate', agreement);
  closeDialog();
}

function agreementEdit(agreement: Agreement) {
  emit('edit', agreement);
  closeDialog();
}

function agreementReject(agreement: Agreement) {
  emit('reject', agreement, closeDialog);
}

function agreementApprove(agreement: Agreement) {
  emit('approve', new ApproveAgreementRequest({ id: agreement.id, shortName: shortName.value }));
  closeDialog();
}
</script>

<template>
  <VDialog
    v-shortkey="['esc']"
    max-width="600"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
    @shortkey.native="closeDialog"
  >
    <VForm novalidate @submit.prevent>
      <VCard>
        <VBtn class="close-icon" icon @click="closeDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
        <VCardTitle class="d-flex align-center flex-grow-1 headline">
          {{ agreement.shortName }}
        </VCardTitle>
        <div class="form-grid">
          <VTextField label="Agreement Id" readonly :value="agreement.displayId" />
          <VTextField
            v-bind="rules.shortName"
            :autofocus="isIncomingDraft"
            label="Agreement Short Name"
            :readonly="!isIncomingDraft"
            :value="agreement.shortName"
            @input="(value) => (shortName = value)"
          />
          <VTextField
            label="Borrower Company Id"
            readonly
            :value="formatCompanyInfo(agreement.borrowerCompany)"
          />
          <VTextField
            label="Lending Company Id"
            readonly
            :value="formatCompanyInfo(agreement.lenderCompany)"
          />
          <VTextField
            :color="getAgreementStatusColor(agreement.status, isIncoming)"
            label="Status"
            readonly
            :value="getAgreementStatusName(agreement.status, isIncoming)"
          />
          <VTextField
            label="Settlement Type"
            readonly
            :value="formatSettlementType(agreement.settlementType)"
          />
          <VTextField
            label="Independent Amount"
            readonly
            :value="formatIndependentAmountRate(agreement.independentAmountRate)"
          />
          <VTextField
            label="Price Rounding Rules"
            readonly
            :value="formatRoundingRule(agreement.roundingRule)"
          />
          <VTextField label="Created By" readonly :value="agreement.proposerUser?.userName" />
          <VTextField
            label="Created DateTime"
            readonly
            :value="formatTimestamp(agreement.createdAt)"
          />
          <template v-if="agreement.responderUser">
            <VTextField label="Approved By" readonly :value="agreement.responderUser.userName" />
            <VTextField
              label="Approved DateTime"
              readonly
              :value="formatTimestamp(agreement.approvedAt)"
            />
          </template>
        </div>
        <VCardActions>
          <VBtn @click="closeDialog">Cancel</VBtn>
          <VSpacer />
          <template v-if="isIncomingDraft">
            <VBtn color="red" @click="agreementReject(agreement)">
              <VIcon left>mdi-close</VIcon>
              Reject
            </VBtn>
            <VBtn color="green" :disabled="!isValid" @click="agreementApprove(agreement)">
              <VIcon left>mdi-check</VIcon>
              Approve
            </VBtn>
          </template>
          <template v-else-if="canUserEditAgreement">
            <VBtn v-if="isAgreementEditable" @click="agreementClose(agreement)">
              <VIcon left>mdi-close</VIcon>
              Close
            </VBtn>
            <VBtn @click="agreementDuplicate(agreement)">
              <VIcon left>mdi-plus-circle-multiple-outline</VIcon>
              Duplicate
            </VBtn>
            <VBtn v-if="isAgreementEditable" @click="agreementEdit(agreement)">
              <VIcon left>mdi-pencil</VIcon>
              Edit
            </VBtn>
          </template>
        </VCardActions>
      </VCard>
    </VForm>
  </VDialog>
</template>

<style lang="scss" scoped>
.close-icon {
  top: 0.4rem;
  right: 0.4rem;
  position: absolute;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 0 1rem;
  margin-top: 1rem;

  & > * {
    margin-top: 0;
  }
}
</style>

import Decimal from 'decimal.js';
import { RoundingRule } from '@/modules/sec-lending/helpers/contract-details';
import { DeepPartial, Raw } from '@/modules/common/helpers/api';
import { SettlementType } from '@/connect';

export type RawCompanyInfo = Raw<
  CompanyInfo,
  {
    enabledSettlementTypes: string[] | SettlementType[];
  }
>;

export class CompanyInfo {
  public companyId: string;
  public displayBoxId: string | null;
  public companyName: string;
  /**
   * $api.userAccounts.searchCounterparties, used by <CounterpartySearch>
   * is the only one returning IA/rr fields below, that's why they are optional
   */
  public companyPreferredIndependentAmountRate?: Decimal;
  public companyPreferredRoundingRule?: RoundingRule;
  public enabledSettlementTypes?: SettlementType[];
  public cutOffTime: string | null;

  protected constructor(data: RawCompanyInfo) {
    this.companyId = data.companyId;
    this.displayBoxId = data.displayBoxId;
    this.companyName = data.companyName;
    this.companyPreferredIndependentAmountRate = data.companyPreferredIndependentAmountRate
      ? new Decimal(data.companyPreferredIndependentAmountRate)
      : undefined;
    this.companyPreferredRoundingRule = data.companyPreferredRoundingRule;

    this.enabledSettlementTypes =
      data.enabledSettlementTypes?.map<SettlementType>((st: SettlementType | string) => {
        return typeof st === 'string' ? SettlementType[st] : st;
      }) ?? [];
    this.cutOffTime = data.cutOffTime;
  }

  public static fromData(data: RawCompanyInfo): CompanyInfo;
  public static fromData(data: RawCompanyInfo | null): CompanyInfo | null;
  public static fromData(data: RawCompanyInfo | undefined): CompanyInfo | undefined;
  public static fromData(data: RawCompanyInfo): null | undefined | CompanyInfo {
    if (data === null) return null;
    if (data === undefined) return undefined;
    return new CompanyInfo(data);
  }

  public static toData(model: CompanyInfo): RawCompanyInfo {
    return {
      companyId: model.companyId,
      companyName: model.companyName,
      displayBoxId: model.displayBoxId,
      companyPreferredIndependentAmountRate:
        model.companyPreferredIndependentAmountRate?.toString(),
      companyPreferredRoundingRule: model.companyPreferredRoundingRule,
      cutOffTime: model.cutOffTime,
      enabledSettlementTypes: model.enabledSettlementTypes?.map((st) => SettlementType[st]) ?? [],
    };
  }

  public static mock(data?: null | DeepPartial<RawCompanyInfo>): CompanyInfo {
    return CompanyInfo.fromData(CompanyInfo.mockData(data));
  }

  public static mockData(data?: null | DeepPartial<RawCompanyInfo>): RawCompanyInfo {
    return {
      companyId: '0',
      companyName: 'Super Awesome Company',
      displayBoxId: null,
      companyPreferredIndependentAmountRate: '0',
      companyPreferredRoundingRule: 'NO_ROUNDING',
      cutOffTime: null,
      enabledSettlementTypes: [],

      ...data,
    };
  }
}

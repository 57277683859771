<template>
  <div style="display: contents">
    <ag-table-client
      :auto-grow="isInsideDialog"
      :column-defs="columnDefs"
      :get-row-id="(item) => item.eventIndex"
      no-menu
      no-move
      :row-data="filteredHistory"
      :sort="{ colId: 'eventTimestamp', sort: 'desc' }"
    />

    <!-- Filter by eventType -->
    <v-row class="mt-4 filter-by">
      <v-col class="col-12 d-flex align-center justify-end">
        <span v-if="filterableEvents.length" class="text--primary mr-2">Filter by</span>
        <v-chip-group v-model="selectedEvents" column multiple>
          <v-chip
            v-for="eventType in filterableEvents"
            :key="eventType"
            :color="getEventTypeColor(eventType)"
            filter
            outlined
            small
            :value="eventType"
          >
            {{ $t(`orderHistory.eventType.${eventType}`) }}
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import {
  getEventTypeColor,
  getStatus,
  timeInForceAbbr,
  timeInForceLabel,
} from '@/modules/marketplace/helpers/marketplace';
import { formatDate } from '@/utils/helpers/dates';
import Decimal from 'decimal.js';
import { OrderEventType } from '@/modules/marketplace/types/marketplace';
import FormatEventInitiator from '@/modules/common/components/FormatEventInitiator.vue';
import { Watch } from 'vue-property-decorator';
import { roundingRuleToShortString } from '@/modules/sec-lending/helpers/contract-details';
import {
  formatCompanyBoxId,
  formatCompanyName,
} from '@/modules/user-accounts/helpers/user-accounts';
import { AgTableClient } from '@/modules/common/components/ag-table';
import { ColDef } from 'ag-grid-enterprise';
import * as cols from '@/modules/common/components/ag-table/columns/marketplace-order-history';
import { OmsHistoryItem, OmsHistoryResponse } from '@/modules/marketplace/models';

const defaultSelectedEvents: OrderEventType[] = [
  'CREATED',
  'MODIFIED',
  'CANCELED',
  'EXPIRED',
  'EXECUTED',
  'CLOSED',
  'TERMINATED',
];

@Component({
  methods: { formatCompanyName, formatCompanyBoxId, roundingRuleToShortString },
  props: {
    details: Object as PropType<OmsHistoryResponse>,
    isInsideDialog: Boolean,
  },
  components: {
    FormatEventInitiator,
    AgTableClient,
  },
})
export default class MarketplaceOrderHistory extends Vue {
  // props
  protected readonly details!: OmsHistoryResponse;
  protected readonly isInsideDialog!: boolean;

  protected getStatus = getStatus;
  protected getEventTypeColor = getEventTypeColor;
  protected timeInForceLabel = timeInForceLabel;
  protected timeInForceAbbr = timeInForceAbbr;

  protected selectedEvents = [...defaultSelectedEvents];

  protected readonly unfilterableEventTypes: OrderEventType[] = ['CREATED'];

  protected get filteredHistory(): OmsHistoryItem[] {
    return this.details.history.filter((i) => {
      return this.selectedEvents.includes(i.eventType);
    });
  }

  /*
   * UI only displays eventType that can be filtered
   * Return a list of unique eventType based on the fetched history data
   */
  protected get filterableEvents(): OrderEventType[] {
    return this.details.history.reduce<OrderEventType[]>((filterable, event) => {
      if (
        !filterable.includes(event.eventType) &&
        !this.unfilterableEventTypes.includes(event.eventType)
      ) {
        filterable.push(event.eventType);
      }
      return filterable;
    }, []);
  }

  protected get allColumnDefs(): Array<ColDef<OmsHistoryItem>> {
    const config = { cellHighlight: this.cellHighlight.bind(this) };
    return [
      cols.eventTimestamp(),
      cols.eventType({
        openLoanDetails: (loanId: string | null) => this.$emit('open-loan-details', loanId),
      }),
      cols.routingStatus(config),
      cols.quantity(config),
      cols.openQuantity(config),
      cols.filled(config),
      cols.rate(config),
      cols.avgExecutionRate(config),
      cols.agreement(config),
      cols.minQuantity(config),
      cols.orderType(config),
      cols.timeInForceType(config),
      cols.initiator(),
    ];
  }

  protected get columnDefs(): Array<ColDef<OmsHistoryItem>> {
    const visibleCols = new Set([
      'eventTimestamp',
      'eventType',
      'routingStatus',
      'quantity',
      'openQuantity',
      'filled',
      'rate',
      'avgExecutionRate',
      'agreement',
      'initiator',
    ]);

    const columnDefs: Array<ColDef<OmsHistoryItem>> = [];
    for (const colDef of this.allColumnDefs) {
      const colId = colDef.colId as string;

      if (visibleCols.has(colId)) {
        columnDefs.push(colDef);
        continue;
      }

      // other columns are only displayed if there there are multiple values between rows
      for (let i = 1; i < this.filteredHistory.length; ++i) {
        let valPrev = this.filteredHistory[i - 1].summary[colId];
        let valCur = this.filteredHistory[i].summary[colId];
        valPrev = valPrev instanceof Decimal ? valPrev.toString() : valPrev;
        valCur = valCur instanceof Decimal ? valCur.toString() : valCur;
        if (valPrev !== valCur) {
          columnDefs.push(colDef);
          break;
        }
      }
    }

    return columnDefs;
  }

  /*
   * Because the component stays mounted, we need to reset the "selectedEvents"
   * (v-chip-group automatically unselects items not present in "filterableEvents",
   * resulting in  stale "selectedEvents" when the parent component changes the "details")
   */
  @Watch('details')
  protected onDetailsChange(): void {
    this.selectedEvents = [...defaultSelectedEvents];
  }

  /*
   * 1) Always highlight all cells of the first event (length - 1)
   * 2) Highlight if current event cell value is different from previous event
   */
  protected cellHighlight(name: string, index: number): 'highlight' | '' {
    if (index === this.filteredHistory.length - 1) {
      return 'highlight';
    }

    // previous item is index + 1 (list is reversed)
    const previousRow = this.filteredHistory[index + 1].summary;
    const currentRow = this.filteredHistory[index].summary;

    if (name === 'openQuantity') {
      // calculate openQuantity and compare
      return currentRow.quantity - currentRow.filled === previousRow.quantity - previousRow.filled
        ? ''
        : 'highlight';
    }

    const previousCell = previousRow[name];
    const currentCell = currentRow[name];

    if (previousCell instanceof Decimal) {
      return previousCell.eq(currentCell) ? '' : 'highlight';
    }

    if (typeof previousCell === 'object') {
      return previousCell?.companyId === currentCell?.companyId ? '' : 'highlight';
    }

    return previousCell === currentCell ? '' : 'highlight';
  }

  protected formatDate(value: Date): string {
    return formatDate(value, 'MMM d h:mm a');
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-card {
    min-height: 60vh;
  }

  .ag-header-row {
    --ag-header-foreground-color: rgba(255, 255, 255, 0.7);
  }

  .ag-row,
  .summary {
    /* Tone down grey to increase contrast between bold and non-bold text */
    color: #888;
  }

  .ag-row .highlight,
  .summary strong {
    color: white;
    font-weight: bold;
  }
}

.filter-by {
  background-color: #1e1e1e;
  position: sticky;
  bottom: 0;

  .theme--light & {
    background: white;
  }
}

.tooltip-icon {
  margin-left: 6px;
}
</style>

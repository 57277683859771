<template>
  <v-dialog
    content-class="au-popup-dialog"
    max-width="800px"
    no-click-animation
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
  >
    <v-form novalidate @submit.prevent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t(title) }}</span>
        </v-card-title>
        <v-card-text>
          <v-container v-if="svErrors">
            <v-alert dense type="error">{{ svErrors }}</v-alert>
          </v-container>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="company.name"
                  autocomplete="company"
                  autofocus
                  :error-messages="clErrors.name"
                  :label="$t('companyName')"
                  placeholder="e.g. Safe Trading Co."
                  required
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  ref="companies"
                  v-model="company.profileID"
                  :error-messages="clErrors.profileID"
                  item-text="label"
                  item-value="id"
                  :items="investorProfiles"
                  :label="$t('companyCategory')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-sheet class="elevation-10">
                  <v-toolbar flat>
                    <v-toolbar-title>NSCC Accounts</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" small @click="onAddNsccAccount">
                      <v-icon left> mdi-plus</v-icon>
                      Add
                    </v-btn>
                  </v-toolbar>
                  <v-container class="aurora-data-table">
                    <v-row class="header">
                      <v-col>Main Account ID</v-col>
                      <v-col>Sub Account ID</v-col>
                      <v-col>Status</v-col>
                      <v-col cols="1">&nbsp;</v-col>
                    </v-row>
                    <v-row v-for="account in company.nsccAccounts" :key="account.id">
                      <v-col>
                        <v-text-field
                          :ref="`mainID-${account.id}`"
                          v-model="account.mainID"
                          autofocus
                          class="nscc-account-number"
                          dense
                          :error-messages="account.errorMainID"
                          :rules="accountNumberRules"
                          single-line
                        />
                      </v-col>
                      <v-col>
                        <v-text-field
                          :ref="`subID-${account.id}`"
                          v-model="account.subID"
                          class="nscc-account-number"
                          dense
                          :error-messages="account.errorSubID"
                          :rules="accountNumberRules"
                          single-line
                        />
                      </v-col>
                      <v-col>
                        <v-chip
                          :class="getStatusClass(account.isActive)"
                          small
                          @click="onActivateNsccAccount(account)"
                        >
                          {{ account.isActive ? 'Active' : 'Inactive' }}
                        </v-chip>
                      </v-col>
                      <v-col cols="1">
                        <v-icon small @click="onDeleteNsccAccount(account)"> mdi-delete </v-icon>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-alert icon="mdi-information">
                          <span class="text--secondary">
                            You can add multiple accounts but only one can be active. <br />Click
                            the status to activate another account.
                          </span>
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-sheet>
              </v-col>
            </v-row>

            <v-row v-if="company.nsccAccounts.length === 0">
              <v-col cols="12">
                <v-alert border="left" icon="mdi-information" type="info">
                  <span class="text--secondary">
                    The company has no permission to trade until you configure an NSCC account.
                  </span>
                </v-alert>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select
                  ref="tradingPermissions"
                  v-model="company.tradingPermissions"
                  :disabled="company.nsccAccounts.length === 0"
                  :error-messages="clErrors.tradingPermissions"
                  :item-text="formatTradingPermissionsLabel"
                  item-value="permission"
                  :items="allowedPermissions"
                  :label="$t(`tradingPermissions.title`)"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-checkbox
                  v-model="company.hasLoanetIntegration"
                  :label="$t('companyLoanet.dialog')"
                />
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="company.deliverOrderSender"
                  :disabled="company.nsccAccounts.length === 0"
                  :error-messages="clErrors.deliverOrderSender"
                  item-text="label"
                  item-value="id"
                  :items="deliverOrderSenderOptions"
                  :label="$t('deliverOrderSender')"
                />
              </v-col>
              <v-col cols="4">
                <counterparty-search
                  v-model="company.sponsor"
                  clearable
                  :exclude-company-id="company.id"
                  label="Sponsored by"
                  placeholder="Sponsored by"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="company.enabledSettlementTypes"
                  item-text="name"
                  item-value="id"
                  :items="settlementTypeOptions"
                  label="Enabled Marketplace Settlement Types"
                  multiple
                  placeholder="Select enabled settlement type"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <multiple-counterparty-selector
                  :counterparties.sync="company.bilateralCounterparties"
                  :exclude-company-id="company.id"
                  label="Bilateral Trade Management Counterparties"
                  :max-height="'10vh'"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-combobox
                  v-model="company.complianceEmailAddresses"
                  chips
                  clearable
                  deletable-chips
                  :delimiters="[',']"
                  :hint="$t('companyComplianceInbox.tip')"
                  :label="$t('companyComplianceInbox.label')"
                  multiple
                  persistent-hint
                  :rules="emailListRule"
                  single-line
                ></v-combobox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pt-4">
          <v-btn class="d-none d-sm-flex" color="secondary" text @click="closeModalDialog">
            {{ $t('cancelButton') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" min-width="120" type="submit" @click="onSubmit">
            {{ $t('saveBrokerCompany') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import i18n from '@/localisation/i18n';
import { isEmpty, omit } from 'lodash';
import { mapActions, mapState } from 'vuex';
import { RestOperation, TradingPermissionOption } from '@/utils/helpers/rest';
import { errorString } from '@/utils/helpers/rest-response';
import { FormValidator, reValidNsccAccountNumber } from '@/utils/helpers/input-form';
import { formatTradingPermissionOption } from '@/modules/broker-admin/utils';
import {
  CompanyAccount,
  CompanyAccountRequest,
  DeliverOrderSender,
  NsccAccount,
} from '@/utils/api/broker';
import CounterpartySearch from '@/modules/user-accounts/components/CounterpartySearch.vue';
import MultipleCounterpartySelector from '@/modules/user-accounts/components/MultipleCounterpartySelector.vue';
import { SettlementType, settlementTypes } from '@/connect';
import { isValidEmailAddress } from '@/utils/helpers/helpers';

@Component({
  components: {
    CounterpartySearch,
    MultipleCounterpartySelector,
  },
  props: {
    crudAction: Number,
    title: String,
    company: Object,
  },
  data: () => ({
    clErrors: {},
    svErrors: '',
    accountNumberRules: [
      (value) => !!value || 'Account ID is required.',
      (value) => {
        return reValidNsccAccountNumber.test(value) || 'Invalid NSCC account number';
      },
    ],
    emailListRule: [
      // should be comma separate list of emails OR empty
      (v: string[]) => {
        if (!v || v.length === 0) {
          return true;
        }

        const invalidItems = v.filter((s) => s && !isValidEmailAddress(s));

        if (invalidItems.length > 0) {
          return `Invalid email address(es) ${invalidItems.join(', ')}`;
        }

        return true;
      },
    ],
    RestOperation,
  }),
  methods: {
    ...mapActions([
      'fetchAdminInvestorProfiles',
      'fetchTradingPermissionOptions',
      'createBrokerCompany',
      'updateBrokerCompany',
    ]),
  },
  computed: {
    ...mapState(['clientConfig', 'investorProfiles', 'tradingPermissionOptions']),
  },
})
export default class BrokerCompanyDialog extends Vue {
  // props
  private title!: string;
  private accountNumberRules!: Array<(value: string) => boolean | string>;

  // store state
  private investorProfiles!: Array<{ id: string; label: string }>;

  private processing = false;

  private crudAction!: RestOperation;
  private company!: CompanyAccount;

  private tradingPermissionOptions!: TradingPermissionOption[];
  private deliverOrderSenderOptions = [
    { id: 'provable' as DeliverOrderSender, label: i18n.tc('deliverOrderSender.options.provable') },
    { id: 'loanet' as DeliverOrderSender, label: i18n.tc('deliverOrderSender.options.loanet') },
    { id: 'self' as DeliverOrderSender, label: i18n.tc('deliverOrderSender.options.self') },
  ];

  private fetchAdminInvestorProfiles!: () => void;
  private fetchTradingPermissionOptions!: () => void;
  private createBrokerCompany!: (company: CompanyAccountRequest) => void;
  private updateBrokerCompany!: (company: CompanyAccountRequest) => void;

  private clErrors: { [index: string]: string } = {};
  private svErrors = '';

  protected get allowedPermissions(): TradingPermissionOption[] {
    if (this.company.nsccAccounts.length > 0) {
      return this.tradingPermissionOptions;
    } else {
      return this.tradingPermissionOptions.filter((o) => o.permission === 0);
    }
  }

  protected get settlementTypeOptions(): Array<{ id: SettlementType; name: string }> {
    return [...settlementTypes.entries()].map(([id, name]) => ({ id, name }));
  }

  protected async mounted(): Promise<void> {
    try {
      await this.fetchAdminInvestorProfiles();
      await this.fetchTradingPermissionOptions();
    } catch (e) {
      this.$log.warn(e);
    }
  }

  protected onAddNsccAccount(): void {
    let lastId = this.company.nsccAccounts[this.company.nsccAccounts.length - 1]?.id || 0;
    const newAccount: NsccAccount = {
      mainID: '',
      subID: '',
      isActive: this.company.nsccAccounts.length === 0,
      id: ++lastId,

      errorMainID: '',
      errorSubID: '',
    };
    this.company.nsccAccounts.push(newAccount);
  }

  protected onDeleteNsccAccount(account: NsccAccount): void {
    const isDeletedActive = account.isActive;
    this.company.nsccAccounts = this.company.nsccAccounts.filter((item) => item !== account);

    // activate the first account when the former active account was just deleted
    if (isDeletedActive && this.company.nsccAccounts.length) {
      this.company.nsccAccounts[0].isActive = true;
    }
  }

  protected onActivateNsccAccount(account: NsccAccount): void {
    this.company.nsccAccounts.forEach((row) => {
      row.isActive = row.mainID === account.mainID;
    });
  }

  protected async onSubmit(): Promise<void> {
    if (this.processing) {
      return;
    }
    this.processing = true;

    try {
      this.svErrors = '';
      this.clErrors = new FormValidator(this.company, [
        'name',
        'profileID',
        'deliverOrderSender',
      ]).check();

      const canSubmitCompany = isEmpty(this.clErrors);
      const canSubmitNsccAccounts = this.company.nsccAccounts.every((row) => {
        const errors = new FormValidator(row, ['mainID', 'subID']).check();

        row.errorMainID = errors.mainID || '';
        row.errorSubID = errors.subID || '';

        return !errors.mainID && !errors.subID;
      });
      const canSubmitComplianceEmails = this.company.complianceEmailAddresses.every(
        (s) => !s || isValidEmailAddress(s)
      );

      // submit company
      if (canSubmitCompany && canSubmitNsccAccounts && canSubmitComplianceEmails) {
        // don't send form helpers
        this.company.nsccAccounts = this.company.nsccAccounts.map((account) => {
          return {
            mainID: account.mainID.toUpperCase(),
            subID: account.subID.toUpperCase(),
            isActive: account.isActive,

            id: 0,
            errorMainID: '',
            errorSubID: '',
          };
        });

        this.company.complianceEmailAddresses = this.company.complianceEmailAddresses.filter(
          (address: string) => address.trim().length > 0
        );

        const payload: CompanyAccountRequest = {
          ...omit(this.company, 'sponsor', 'bilateralCounterparties'),
          sponsorCompanyId: this.company.sponsor?.companyId || null,
          bilateralCounterpartyIds: this.company.bilateralCounterparties.map(
            (party) => party.companyId
          ),
          enabledSettlementTypes: this.company.enabledSettlementTypes.map(
            (st) => SettlementType[st]
          ),
        };

        if (this.crudAction === RestOperation.Create) {
          await this.createBrokerCompany(payload);
        } else {
          await this.updateBrokerCompany(payload);
        }
        this.closeModalDialog();
      }
    } catch (e) {
      this.svErrors = errorString(e as Error);
    } finally {
      this.processing = false;
    }
  }

  protected closeModalDialog(): void {
    // allow parent to cleanup
    this.$emit('close-modal');
  }

  /**
   * Get a CSS class according to the provided loan status
   */
  protected getStatusClass(status: boolean): string {
    return status ? 'status-active' : 'status-inactive';
  }

  protected formatTradingPermissionsLabel(option: TradingPermissionOption): string {
    return this.$i18n.tc(formatTradingPermissionOption(option));
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .inline-label {
    font-weight: bold;
  }

  .is-radiogroup {
    padding: 8px 0 0 16px;
  }

  .nscc-table table tr td {
    border: none !important;
  }

  .nscc-account-number input {
    text-transform: uppercase;
  }

  // @TODO use sass variables to store these colours
  .v-chip {
    &.status-active {
      color: #fff;
      background: #ffae00;
      width: 70px;
    }

    &.status-inactive {
      color: #fff;
      width: 70px;
    }
  }
}
</style>
